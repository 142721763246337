import * as React from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AuthenticatedComponent from "./AuthenticatedComponent";

export default class RouteManager {

    // static _routeWithComponent = [];
    // static _routeWithRender = [];
    public static routes:any[] = [];
    public static authenticatedRoutes:any[] = [];
    public static signupPage?:any = null;

    public static addRouteWithComponent(path: string, component: any) {
        this.routes.push(<Route exact key={path} path={path} component={component} />)
        // this._routeWithComponent.push({path:path, component:component});
    }

    public static addRouteWithRender(path: string, render: any) {
        // this._routeWithRender.push({path:path, render:render});
        this.routes.push(<Route exact key={path} path={path} render={render} />);
    }

    public static addAuthenticatedRouteWithComponent(path: string, component: any) {
        this.authenticatedRoutes.push(<Route exact key={path} path={path} component={component} />)
    }

    public static setSignupPage(component: any) {
        this.signupPage = component;
    }

    public static renderRouter(authSettings: AuthSettings) {
        return <Router>
            <Switch>
                {this.routes}
                {this.authenticatedRoutes.length > 0 ? <Route render={(props: any) =>
                    <AuthenticatedComponent authSettings={authSettings}
                                            {...props}>{this.authenticatedRoutes}</AuthenticatedComponent>}/> : null}
            </Switch>
        </Router>
    }
}