import { Storage } from 'aws-amplify';
import {Http} from "oca-lib-web/dist";

export default class AWSStorage {

    public static configure(bucket: string, region: string, identityPoolId: string) {
        Storage.configure({
            bucket,
            region,
            identityPoolId
        });
    }

    public static track() {
        Storage.configure({ track: true })
    }

    public static get(fileName: string, download?: boolean): Promise<any> {
        return new Promise((resolve, reject) => {
            Storage.get(fileName, { download: download})
                .then((result:any) => {
                    // console.log(result);
                    resolve(result);
                })
                .catch((err:any) => {
                    console.log(err);
                    resolve(null);
                    //reject(err);
                });
        });
    }

    public static getContents(fileName:string): Promise<any> {
        return new Promise<any>(async (resolve, reject) => {
            let file = await this.get(fileName);
            let results = null;
            if (file) {
                results = await Http.getContents(file);
            }

            resolve(results);
        });
    }

    public static list(dir?: string): Promise<any> {
        const directory = dir ? dir : '';
        return new Promise((resolve, reject) => {
            Storage.list(directory)
                .then((result:any) => {
                    // console.log(result);
                    resolve(result);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public static put(fileName: string, file: any): Promise<any> {
        return new Promise((resolve, reject) => {
            Storage.put(fileName, file)
                .then((result:any) => {
                    console.log(result);
                    resolve(result);
                })
                .catch((err:any) => {
                    console.log(err)
                    reject(err);
                });
        });
    }

    public static remove(fileName: string): Promise<any> {
        return new Promise((resolve, reject) => {
            Storage.remove(fileName)
                .then((result:any) => {
                    // console.log(result);
                    resolve(result);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }
}