import * as React from 'reactn';
import {
    ArrayUtil,
    base,
    BaseColor,
    ButtonComponent,
    ComponentFactory, DateUtil,
    IBaseProps,
    Route, SelectComponent
} from "oca-lib-web";
import Sidebar from "../component/Sidebar";
import {addCallback} from "reactn";
import 'react-bulma-components/dist/react-bulma-components.min.css';
import ComponentUtil from "../util/ComponentUtil";
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";
import NotificationUtil from "../util/NotificationUtil";

interface IState {
    componentFactory:ComponentFactory,
    selectedAccount?:string,
    itineraries:Array<Itinerary>,
    notifications: Array<NotificationHistoryModel>,
}

class ItineraryPage extends React.Component<IBaseProps, IState> {
    public componentRefs = ComponentUtil.createRefs(3);

    // Init

    constructor() {
        super();

        let componentFactory = new ComponentFactory(Styles.default);

        this.state = {
            componentFactory:componentFactory,
            itineraries:[],
            notifications: []
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.updateData();
            return null;
        });
        if (this.global.isDataLoaded) {
            this.updateData();
        }
    }

    updateData() {
        this.getItineraries();
        this.getNotifications();
    }

    async getNotifications() {
        let notifications = [];
        if (this.global.user?.admin) {
            //notifications = await this.global.dataManager.get("getNotificationHistoryForAdmin");
            notifications = await NotificationUtil.getNotificationHistoryForAdmin();
        } else if (this.global.account) {
            notifications = await this.global.dataManager.get("getNotificationHistory", this.global.account.id);
        }
        this.setState({notifications: notifications});
    }

    async getItineraries() {
        let itineraries = [];
        if (this.state.selectedAccount) {
            itineraries = await this.global.dataManager.get("getItineraries", this.state.selectedAccount);
        } else if (this.global.user?.admin) {
            itineraries = await this.global.dataManager.get("getItinerariesForAdmin");
        } else if (this.global.account) {
            itineraries = await this.global.dataManager.get("getItineraries", this.global.account.id);
        }

        /*let assets = await AssetUtil.getAssetList();
        ArrayUtils.sort(itineraries, "itineraryStartDate");

        for (let itinerary of itineraries) {
            let totalDistance = 0;
            let assetIds = itinerary.itineraryAssetIds.split(",");
            for (var i=0; i<assetIds.length; i++) {
                if (i==0) continue;
                let asset = ArrayUtils.getItem(assets, "id", assetIds[i]);
                let previousAsset = ArrayUtils.getItem(assets, "id", assetIds[i-1]);
                var from = point([asset.assetLatitude, asset.assetLongitude]);
                var to = point([previousAsset.assetLatitude, previousAsset.assetLongitude]);
                totalDistance += distance(from, to, {units: 'miles'});
            }
            itinerary["stopDistance"] = assetIds.length + " stops (" + Math.round(totalDistance) + " miles)";
        }*/

        this.setState({itineraries: itineraries});
    }

    // Actions

    async selectedAccountChanged(value:any, key:string) {
        let selectedAccount = value != "" ? value : null;
        this.setState({selectedAccount: selectedAccount}, function(this:ItineraryPage) {
            this.getItineraries();
        }.bind(this));
    }

    async deleteItemTapped(item:Itinerary) {
        await this.global.dataManager.delete("deleteItinerary", item);
        await this.getItineraries();

        this.props.base.notify("Itinerary deleted");
    }

    // Render methods

    getRow(item:Itinerary) {
        let editFunction = Route.goFunction(this, 'itinerary/' + item.id);
        let deleteFunction = () => {
            if (!NotificationUtil.doesItineraryHaveNotification(item, this.state.notifications)) {
                this.props.base.alert("Confirm Delete", "Are you sure you want to delete this itinerary? This action cannot be undone.", this.deleteItemTapped.bind(this), item)
            } else {
                this.props.base.notify("Cannot delete itinerary because there is a push notification associated with it.", "error");
            }
        }
        const actions = this.state.componentFactory.renderEditDeleteButtons(editFunction, deleteFunction)

        var row = [
            item.itineraryName,
            item.itineraryStartDate ? DateUtil.format(item.itineraryStartDate, "MMMM DD, YYYY") : "",
            item.itineraryEndDate ? DateUtil.format(item.itineraryEndDate, "MMMM DD, YYYY") : "",
            actions
        ]
        if (this.global.user?.admin) {
            let accountItem = ArrayUtil.getItem(this.global.accountOptions, "value", item.accountId)
            row.splice(3,0,accountItem.label);
        }

        return row;
    }

    public render() {
        let cf = this.state.componentFactory;

        var headers = ["Itinerary Name", "Start Date", "End Date", "Action"];
        if (this.global.user?.admin) {
            headers.splice(3, 0, "Account");
        }

        let headerRowContent = <ButtonComponent primary={true} title="Add Itinerary"
                                                onTouchTap={Route.goFunction(this, 'itinerary')}/>;
        var headerRowLeftContent;
        if (this.global.user?.admin) {
            headerRowLeftContent = <SelectComponent
                id="account"
                inputType="bootstrap"
                variant="outlined"
                value={this.state.selectedAccount}
                options={this.global.accountOptions}
                onChange={this.selectedAccountChanged.bind(this)}/>
        }

        return (
            !this.global.isDataLoaded ?
                VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                    :
                    cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                        cf.renderBoxProps({marginLeft: '90px'},
                            !this.global.account && !this.global.user?.admin ?
                                cf.renderBox("Your account is not associated with any NC counties. Please contact an administrator for help.")
                                :
                                [VisualUtil.getHeaderDisplay("Itineraries", headerRowContent),
                                    cf.renderLine(),
                                    VisualUtil.renderActionBar(headerRowLeftContent),
                                    cf.renderTable(headers, this.state.itineraries, this.getRow.bind(this), {fitToContent: true})
                                ]
                        )
                    ])
        )
    }
}

export default base(ItineraryPage, Sidebar)