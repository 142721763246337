import * as React from 'reactn';
import {
    ArrayUtil,
    base,
    BaseColor, BaseIcon,
    ButtonComponent, ComponentFactory, DateUtil, DialogUtil,
    IBaseProps, IconButtonComponent, LocalCache, MenuButtonComponent, P,
    Route, File
} from "oca-lib-web";
import RichTextEditor from 'react-rte';
import InvokeFields from "../util/InvokeFields";
import Sidebar from "../component/Sidebar";
import SendIcon from '@mui/icons-material/Send';
import {createRef} from "react";
import ContactPhoneCallDialog from "../component/ContactPhoneCallDialog";
import Constants from "../util/Constants";
import {Alert} from "@mui/lab";
import MessageUtil from "../util/MessageUtil";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AssetSelectionDialog, {SelectionType} from "../component/AssetSelectionDialog";
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AssetUtil from "../util/AssetUtil";
import {addCallback} from "reactn";
import DateComponent from "oca-lib-web/dist/components/DateComponent";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";

interface IState {
    dialogs:Array<any>,
    value: any,
    //subject: string,
    accountId?: string,
    type?: string,
    messageHistory?: MessageHistory,
    itemRows:Array<any>,
    //assetRows:Array<AssetModel>,
    //userRows:Array<UserModel>,
    componentFactory:ComponentFactory,
    isSending:boolean,
    selected: Array<any>,
    errorText?: string
}

interface IProps {
    onChange: any
}

class SendMessagePage extends React.Component<IProps & IBaseProps, IState> {

    public contactPhoneCallDialog = createRef<any>();
    public assetSelectionDialog = createRef<any>();
    public scheduledDateComponent = createRef<DateComponent>();
    public scheduledTimeComponent = createRef<DateComponent>();

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(null, null, {includeAssetInformation: true});
        componentFactory.setHandleUpdateCallback((value:any, key:string) => {
            this.setState({errorText:null});
        })
        let dialogs = [
            <ContactPhoneCallDialog ref={this.contactPhoneCallDialog} title="Phone Call Contact" actionTitle="Done" actionCallback={this.dialogCallback.bind(this)} />,
            <AssetSelectionDialog {...componentFactory.renderDialogProps("Select Assets", "Save", this.addAssetsDialogCallback.bind(this), this.assetSelectionDialog)} />,
        ]
        console.log(dialogs);
        this.state = {
            componentFactory: componentFactory,
            dialogs:dialogs,
            value: RichTextEditor.createEmptyValue(),
            itemRows: [],
            isSending: false,
            selected: []
        };
    }

    async componentWillMount() {
        let type = Route.param(this, "type");
        let id = Route.param(this, "id");
        this.setState({type:type});

        addCallback(global => {
            this.initMessages(id, type);
            return null;
        });
        this.initMessages(id, type);
    }

    componentWillUnmount() {
        LocalCache.removeSession("ids");
    }

    async initMessages(id:string, type:string) {
        if (!this.global.isDataLoaded) return;

        if (type == "asset") {
            this.setAssetRows();
        } else if (type == "user") {
            this.setUserRows();
        } else {
            //this.props.base.notify("Something went wrong. Please go back and reselect your assets");
        }

        if (id) {
            this.getMessageHistory(id);
        }
    }

    async getMessageHistory(id:string) {
        let messages = await this.global.dataManager.get("getMessageHistoryForAdmin"); // eventually replace with individual
        let messageHistory:MessageHistory = ArrayUtil.getItem(messages, "id", id);
        console.log(messageHistory);
        if (this.scheduledDateComponent.current) {
            this.scheduledDateComponent.current.handleDateChange(DateUtil.date(messageHistory.messageScheduledDate));
        }
        if (this.scheduledTimeComponent.current) {
            this.scheduledTimeComponent.current.handleDateChange(DateUtil.date(messageHistory.messageScheduledTime));
        }

        this.setState({messageHistory:messageHistory});

        this.state.componentFactory.getDefaultBinding().setProperty("subject", messageHistory.messageSubject);
        let assets = await AssetUtil.getAssetList();
        let assetIds = messageHistory.assetIds.split(",");
        let assetRows = [];
        for (let assetId of assetIds) {
            let asset = ArrayUtil.getItem(assets, "id", assetId);
            assetRows.push(asset);
        }
        let messageBody = RichTextEditor.createValueFromString(messageHistory.messageBody, 'html')
        this.setState({value:messageBody, itemRows:assetRows});
    }

    async setAssetRows() {
        let assetId = LocalCache.getSession("ids");
        let assetIdList = assetId ? assetId.split(",") : [];
        //var assets = await this.global.dataManager.get("getAssetsForAdmin", {field:InvokeFields.getAssetsForAdmin});
        const assets = await AssetUtil.getAssetList();
        var assetRows:Array<AssetModel> = [];
        var selected:Array<AssetModel> = [];
        console.log("total selected assets: " + assetIdList.length);
        for (let assetIdItem of assetIdList) {
            let asset = ArrayUtil.getItem(assets, "id", assetIdItem);
            if (asset) {
                assetRows.push(asset);
                selected.push(asset);
            } else {
                console.log("error getting asset");
                console.log(assetIdItem);
            }
        }
        ArrayUtil.sort(assetRows, "assetName");
        this.setState({itemRows:assetRows});
    }

    async setUserRows() {
        let userId = LocalCache.getSession("ids");
        let userIdList = userId.split(",");
        var users = await this.global.dataManager.get("getUsers");
        var userRows:Array<UserModel> = [];
        for (let userIdItem of userIdList) {
            let user = ArrayUtil.getItem(users, "id", userIdItem);
            userRows.push(user);
        }
        ArrayUtil.sort(userRows, "name");
        console.log(userRows);
        this.setState({itemRows:userRows});
    }

    async handleSave() {
        let items = this.state.itemRows;
        let idList = [];
        for (let item of items) {
            idList.push(item.id);
        }

        if (!this.validate()) {
            this.setState({errorText:"Please fill out all fields"});
            this.props.base.notify("Please fill out all fields", "error");
            return;
        } else if (idList.length == 0) {
            this.setState({errorText:"Please select at least one recipient"});
            this.props.base.notify("Please select at least one recipient", "error");
            return;
        }

        let binding = this.state.componentFactory.getDefaultBinding();
        let messageScheduledDate = binding.getProperty("messageScheduledDate");
        let messageScheduledTime = binding.getProperty("messageScheduledTime");
        this.setState({isSending:true}, async function(this:SendMessagePage) {

            if (this.state.type == "asset") {
                let subject = binding.getProperty("subject");
                let body = this.state.value.toString('html');

                if (!messageScheduledDate) {
                    this.emailAssets(idList.join(','));
                } else {
                    MessageUtil.createMessageHistory(this.state.messageHistory?.id, subject, body, Constants.MESSAGE_TYPE_EMAIL, idList.join(','), messageScheduledDate, messageScheduledTime)
                }

            } else if (this.state.type == "user") {
                await this.emailAdmins(idList.join(','));
                this.dialogCallback();
            }

            // determine if there are any phone assets that need the dialog
            if (!messageScheduledDate) {
                let assetPhoneRows:Array<AssetModel> = [];
                if (this.state.itemRows.length > 0) {
                    for (let assetRow of this.state.itemRows) {
                        if (assetRow.assetContactPreference == "phone") {
                            assetPhoneRows.push(assetRow);
                        }
                    }

                    if (assetPhoneRows.length > 0) {
                        DialogUtil.open(this.contactPhoneCallDialog.current, assetPhoneRows);
                    } else {
                        this.dialogCallback();
                    }
                }
            } else {
                this.dialogCallback();
            }

        }.bind(this));
    }

    // Callbacks

    dialogCallback() {
        LocalCache.removeSession("ids");
        Route.goBack(this);
    }

    /*verifyEmailAddressCallback(item:any) {
        console.log("verify email adddress");
        this.props.base.notify("An email has been sent to you. Please check your inbox for more instructions.");
    }*/

    /*scheduleMessageDialogCallback(item:any) {
        let cf = this.state.componentFactory;
        cf.getDefaultBinding().setProperty("messageScheduledDate", item.messageScheduledDate);
        cf.getDefaultBinding().setProperty("messageScheduledTime", item.messageScheduledTime);
        console.log(cf.getDefaultBinding().item);
        this.handleSave();
    }*/

    addAssetsDialogCallback(items:Array<AssetModel>) {
        console.log(items);
        this.setState({itemRows:items});
    }

    // Private methods

    private async emailAssets(assetIds:string):Promise<any> {
        let binding = this.state.componentFactory.getDefaultBinding();
        let user = await this.global.dataManager.get("getCurrentUser");
        var item = {
            messageSubject: binding.getProperty("subject"),
            messageBody: this.state.value.toString('html'),
            includeAssetInformation: binding.getProperty("includeAssetInformation"),
            userId: user.id,
            accountId: this.global.account?.id,
            assetId: assetIds,
            type: this.state.type,
            field: InvokeFields.emailFarmAssets
        };
        console.log(item);

        this.global.dataManager.perform("emailFarmAssets", item);

        return P.resolve(true);
    }

    private async emailAdmins(userIds:string):Promise<any> {
        let binding = this.state.componentFactory.getDefaultBinding();
        let user = await this.global.dataManager.get("getCurrentUser");
        var item = {
            messageSubject: binding.getProperty("subject"),
            messageBody: this.state.value.toString('html'),
            userId: user.id,
            userIds: userIds,
            field: InvokeFields.emailAdmins
        };
        console.log(item);

        this.global.dataManager.perform("emailAdmins", item);
        return P.resolve(true);
    }

    validate():boolean {
        let binding = this.state.componentFactory.getDefaultBinding();
        let subject = binding.getProperty("subject");
        let blankMessageText = "<p><br></p>";
        if (!subject || subject == "") {
            return false;
        } else if (this.state.value.toString('html') == blankMessageText) {
            return false;
        }
        return true;
    }

    onChange = (value:any) => {
        if (this.state.errorText) {
            this.setState({errorText:null});
        }
        this.setState({value});
        if (this.props.onChange) {
            // Send the changes up to the parent component as an HTML string.
            // This is here to demonstrate using `.toString()` but in a real app it
            // would be better to avoid generating a string on each change.
            this.props.onChange(value.toString('html'));
        }
    };

    async scheduleButtonTapped() {
        this.handleSave();
    }

    async saveButtonTapped() {
        this.handleSave();
    }

    async removeButtonTapped() {
        let cf = this.state.componentFactory;
        cf.getDefaultBinding().setProperty("messageScheduledDate", null);
        cf.getDefaultBinding().setProperty("messageScheduledTime", null);

        this.scheduledDateComponent.current.handleDateChange(null);
        this.scheduledTimeComponent.current.handleDateChange(null);

        this.forceUpdate();
    }

    onRowSelection(item: any, data: any) {
        console.log(data);
        let selectedItems = this.state.selected;
        let found = false;
        for (let selectedItem of selectedItems) {
            if (data.id == selectedItem.id) {
                found = true;
            }
        }
        if (found) {
            ArrayUtil.deleteItemByKey(data, "id", selectedItems);
        } else {
            selectedItems.push(data);
        }
        this.setState({selected:selectedItems, errorText:null})
    }

    isRowSelected(item:AssetModel):boolean {
        let selectedItems = this.state.selected;
        for (let selectedItem of selectedItems) {
            if (item.id == selectedItem.id) return true;
        }
        return false;
    }

    /*scheduleMessagesTapped() {
        var hasPhone = false;
        for (let item of this.state.itemRows) {
            if (item.assetContactPreference == "phone") {
                hasPhone = true;
            }
        }
        if (hasPhone) {
            this.setState({errorText:"Cannot schedule a message when at least one asset has a contact preference of phone"})
        } else {
            DialogUtil.open(this.scheduleMessageDialog.current);
        }
    }*/

    deleteSelected() {
        this.props.base.alert("Confirm Delete", "Are you sure you want to remove these recipients? This action cannot be undone.", this.deleteSelectionTapped.bind(this), null);
    }

    deleteSelectionTapped() {
        let items = this.state.itemRows;
        let selected = this.state.selected;
        let unselectedItems = [];
        for (let item of items) {
            if (!ArrayUtil.contains(selected, item)) {
                unselectedItems.push(item);
            }
        }
        this.setState({itemRows:unselectedItems, selected:[]})
    }

    // Render Methods

    getHeaders() {
        if (this.state.type == "asset") {
            return ["Asset Name", "Contact Action"];
        } else if (this.state.type == "user") {
            return ["Admin Name", "Contact Action"];
        }
        return [];
    }

    getRow(item:any) {
        if (this.state.type == "asset") {
            return this.getAssetRow(item);
        } else if (this.state.type == "user") {
            return this.getUserRow(item);
        }
        return [];
    }

    getAssetRow(item:AssetModel) {
        let contactActionText = "";
        if (item.assetContactPreference === "email") {
            contactActionText = "Send email to " + item.assetEmailAddress;
        } else if (item.assetContactPreference === "text") {
            contactActionText = "Send text message to " + item.assetContactPhoneNumber;
        } else if (item.assetContactPreference === "phone") {
            contactActionText = "Request phone call at " + item.assetContactPhoneNumber;
        }
        return [
            //<Checkbox color="primary" checked={this.isRowSelected(item)} />,
            //null,
            item.assetName,
            contactActionText
        ]
    }

    getUserRow(item:UserModel) {
        return [
            //<Checkbox color="primary" checked={this.isRowSelected(item)} />,
            //null,
            item.name,
            item.email,
        ]
    }

    async overflowMenuTapped(value:number, e:any) {
        if (value === 0) {
            console.log("Generating email list");
            let items = this.state.itemRows;
            let emailAddresses = [];
            for (let item of items) {
                if (item.assetContactPreference == "email") {
                    emailAddresses.push(item.assetEmailAddress);
                }
            }
            console.log(emailAddresses);
            File.download(emailAddresses, "visitncfarms_contactlist.txt");
        }
    }

    renderEmailMessage() {
        let cf = this.state.componentFactory;
        return [
            cf.renderBoxPadded(cf.renderTextField("subject", "Email Subject")),
            cf.renderBoxPadded(<RichTextEditor className="new-post-editor" value={this.state.value} onChange={this.onChange}/>),
            this.state.type == "asset" ? cf.renderBoxPadded(cf.renderSwitchField("includeAssetInformation", "Include Asset Information")) : null
        ]
    }

    render() {
        let cf = this.state.componentFactory;
        let messageScheduledDate = cf.getDefaultBinding().getProperty("messageScheduledDate");
        let messageScheduledTime = cf.getDefaultBinding().getProperty("messageScheduledTime");
        //let scheduledDate = DateUtil.format(messageScheduledDate, "MMMM D, YYYY") + " " + DateUtil.format(messageScheduledTime, "hh:mm a")
        //let actionButtons = [<ButtonComponent primary={true} title="Add" startIcon={<AddCircleOutlineIcon/>} onTouchTap={DialogUtil.openFunction(this.assetSelectionDialog.current)} />];
        let ids = [];
        for (let item of this.state.itemRows) {
            ids.push(item.id);
        }
        let actionButtons = [
            this.state.selected.length > 0 ? cf.renderButton("Delete", this.deleteSelected.bind(this), {
                startIcon: <DeleteOutlineIcon/>, isFlat: true
            }) : null,
            cf.renderButton("Add", DialogUtil.openFunction(this.assetSelectionDialog.current, {
                type: this.state.type === "asset" ? SelectionType.Asset : this.state.type === "user" ? SelectionType.User : "",
                selected: ids
            }), {startIcon: <AddCircleOutlineIcon/>}),
        ]
        let sendActions = [];
        let saveLabel = messageScheduledDate && messageScheduledTime ? "Schedule" : "Send";
        /*if (this.state.messageHistory) {
            sendActions = [<div style={{marginRight:'20px'}}>Scheduled for {DateUtil.format(scheduledDate, 'MMMM D, YYYY h:mma')}</div>, <ButtonComponent primary title="Update" disabled={this.state.isSending}
                             onTouchTap={this.saveButtonTapped.bind(this)}/>]
        } else {
            sendActions = [this.state.type == "asset" ?
                <ButtonComponent primary title={saveLabel} startIcon={<SendIcon/>} disabled={this.state.isSending}
                                 onTouchTap={this.saveButtonTapped.bind(this)}/> : null]
        }*/
        sendActions = [
            <ButtonComponent primary title={saveLabel} startIcon={<SendIcon/>} disabled={this.state.isSending}
                             onTouchTap={this.saveButtonTapped.bind(this)}/>,
            <MenuButtonComponent menuItemLabels={["Generate email list"]}
                                 onTouchTap={this.overflowMenuTapped.bind(this)}>
                <IconButtonComponent icon={BaseIcon.ICON_MORE_VERT} tooltip="More Actions" color="primary"
                                     onTouchTap={() => {
                                     }}/>
            </MenuButtonComponent>]
        let removeAction = messageScheduledDate || messageScheduledTime ? [<ButtonComponent primary title="Remove"
                                                                                            isFlat
                                                                                            onTouchTap={this.removeButtonTapped.bind(this)}/>] : [];

        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                this.state.dialogs,
                !this.global.isDataLoaded ?
                    VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                        :
                        cf.renderBoxProps({marginLeft: '90px'}, [
                            this.state.errorText ? <Alert severity="error">{this.state.errorText}</Alert> : null,
                            VisualUtil.getHeaderDisplay("Send Message", sendActions),
                            cf.renderLine(),
                            this.renderEmailMessage(),

                            cf.renderLine(),
                            VisualUtil.getHeaderDisplay("Schedule (Optional)", removeAction),
                            cf.renderLine(),
                            cf.renderBoxPadded(cf.renderDateField("messageScheduledDate", "Scheduled Date", "date", this.scheduledDateComponent, null, {minutesStep: 60})),
                            //cf.renderBoxPadded(cf.renderDateField("messageScheduledTime", "Scheduled Time", "time", this.scheduledTimeComponent, null, {minutesStep: 60})),

                            cf.renderLine(),
                            VisualUtil.getHeaderDisplay("Recipients", actionButtons),
                            cf.renderLine(),
                            cf.renderTableWithSelection(this.getHeaders(), this.state.itemRows, this.getRow.bind(this), this.onRowSelection.bind(this), {fitToContent: true}),
                        ])
            ])
        )
    }
}

export default base(SendMessagePage, Sidebar)