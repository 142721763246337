import * as React from 'reactn';
import {ArrayUtil, ComponentFactory, dialog, IBaseDialogProps, SelectItem, StringUtil} from "oca-lib-web";
import ReactGA from "react-ga";
import Constants from "../util/Constants";
import ComponentUtil from "../util/ComponentUtil";
import AWSStorage from "../auth/aws/AWSStorage";
import Imgix from "react-imgix";
import ImageUtil from "../util/ImageUtil";
import { Alert } from '@mui/material';
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    assetSelections:Array<SelectItem>,
    assetTagOptions:Array<SelectItem>,
    errorText?:string
}

// TODO: No longer used
class AddEventDialog extends React.Component<IBaseDialogProps, IState> {

    public components = ComponentUtil.createRefs(8);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        console.log(props.param);
        componentFactory.addDefaultBinding(null, null, props.param);
        componentFactory.setHandleUpdateCallback((value:any, key:string) => {
            this.setState({errorText:null});
        })

        this.state = {
            componentFactory: componentFactory,
            assetSelections: [],
            assetTagOptions: []
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/item/addEventDialog');

        //let assets = await this.global.data.getAssets(this.global.account.id);

        let assetTags = await this.global.dataManager.get("getAssetTags");
        ArrayUtil.sort(assetTags, "assetTagName");
        let assetTagOptions = this.state.componentFactory.createOptions(assetTags, "id", "assetTagName");
        /*for (let assetTag of assetTags) {
            assetTagOptions.push(SelectItem.create(assetTag.id, assetTag.assetTagName));
        }*/
        this.setState({assetTagOptions:assetTagOptions})
    }

    // Methods

    async uploadPictureTapped(e:any) {
        if (!e.nativeEvent.target || !e.nativeEvent.target.files) { return }

        const file = e.nativeEvent.target.files[0];
        const fileName = StringUtil.uuid();
        await AWSStorage.put(fileName, file);
        console.log("uploaded file: " + fileName);

        let componentBinding = this.state.componentFactory.getDefaultBinding();
        componentBinding.setProperty("photo", fileName);
        this.forceUpdate();
        //componentBinding.saveBinding();

    }

    async handleSave(callback: any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) {
            this.setState({errorText:"Please fill out all fields"});
            return;
        }

        let eventStartDateMoment = this.components[2].current.getValue();
        let eventEndDateMoment = this.components[3].current.getValue();
        let eventStartTimeMoment = this.components[4].current.getValue();
        let eventEndTimeMoment = this.components[5].current.getValue();
        if (eventStartDateMoment == null) {
            this.setState({errorText:"Event start date is required"});
            return;
        } else if (eventEndDateMoment == null) {
            this.setState({errorText:"Event end date is required"});
            return;
        } else if (eventStartTimeMoment == null) {
            this.setState({errorText:"Event start time is required"});
            return;
        } else if (eventEndTimeMoment == null) {
            this.setState({errorText:"Event end time is required"});
            return;
        }

        if (eventStartDateMoment.isAfter(eventEndDateMoment, 'day')) {
            this.setState({errorText:"Event end date must be after event start date"});
            return;
        } else if (eventStartTimeMoment.isAfter(eventEndTimeMoment, 'hour')) {
            this.setState({errorText:"Event end time must be after event start time"});
            return
        }

        let assetEventRecurrence = componentBinding.getProperty("assetEventRecurrence");
        let assetEventCustomRecurrence = componentBinding.getProperty("assetEventCustomRecurrence");
        if (assetEventRecurrence == "custom" && (!assetEventCustomRecurrence || assetEventCustomRecurrence == "")) {
            this.setState({errorText:"Custom event recurrence requires you to enter days of the week"});
            return
        } else if (assetEventRecurrence != "custom" && assetEventCustomRecurrence && assetEventCustomRecurrence != "") {
            this.setState({errorText:"Days of the week should only be entered for custom event recurrence"});
            return
        }

        //console.log(componentBinding.item);
        //console.log(componentBinding.item["assetEventStartTime"])
        //console.log(moment(componentBinding.item["assetEventStartTime"]))
        //console.log(moment(componentBinding.item["assetEventStartTime"], "HHmm"))
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    render() {
        let cf = this.state.componentFactory;
        let photo = cf.getDefaultBinding().getProperty("photo");
        return (
            cf.renderBox([
                this.state.errorText ? <Alert severity="error">{this.state.errorText}</Alert> : null,
                cf.renderBoxPadded(cf.renderTextField("assetEventTitle", "Event Title", this.components[0], null, {required:true})),
                cf.renderBoxPadded(cf.renderTextField("assetEventDescription", "Event Description", this.components[1], null, {required:true, type:"textarea", multiline:true})),
                cf.renderBoxPadded(cf.renderDateField("assetEventStartDate", "Event Start Date", "date", this.components[2], null, {required:true})),
                cf.renderBoxPadded(cf.renderDateField("assetEventEndDate", "Event End Date", "date", this.components[3], null, {required:true})),
                cf.renderBoxPadded(cf.renderDateField("assetEventStartTime", "Event Start Time", "time", this.components[4], null, {required:true})),
                cf.renderBoxPadded(cf.renderDateField("assetEventEndTime", "Event End Time", "time", this.components[5], null, {required:true})),
                cf.renderBoxPadded(cf.renderSelectField("assetEventRecurrence", "Event Recurrence", Constants.EVENT_RECURRENCE_SELECTION, this.components[6], null, {required:true})),
                cf.renderBoxPadded(cf.renderSelectField("assetEventCustomRecurrence", "Custom Event Recurrence", Constants.EVENT_CUSTOM_RECURRENCE_SELECTION, this.components[7], null, {multiple:true})),
                //cf.renderBoxPadded(cf.renderSwitchField("isActive", "Active")),
                cf.renderBoxPadded([
                    cf.renderBox(photo ? <Imgix height={100} width={100} src={ImageUtil.fetch(photo)} /> : null),
                    cf.renderBox(cf.renderUploadButton("Upload Image", "images", this.uploadPictureTapped.bind(this), null))
                ])
            ])
        )
    }
}

export default dialog(AddEventDialog)