import * as React from 'reactn';
import {ComponentFactory, ComponentUtil, DateUtil, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from "react-ga";
import { Alert } from '@mui/material';
import AssetUtil from "../util/AssetUtil";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    errorText?:string
}

class AddAssetDialog extends React.Component<IBaseDialogProps, IState> {

    public componentRefs = ComponentUtil.createRefs(2);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createAsset", null);
        componentFactory.setHandleUpdateCallback((value:any, key:string) => {
            this.setState({errorText:null});
        })

        this.state = {
            componentFactory:componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    componentDidMount() {
        ReactGA.modalview('/item/addAssetDialog');

        this.initView();
    }

    async initView() {
        /*let accountOptions:Array<SelectItem> = [];
        let accounts = await this.global.dataManager.get("getAccounts");
        ArrayUtil.sort(accounts, "accountName");
        for (let account of accounts) {
            accountOptions.push(SelectItem.create(account.id, account.accountName));
        }

        this.setState({accountOptions:accountOptions});*/
    }

    // Public methods

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();

        if (!this.global.user.admin) {
            //let account = await UserUtil.getAccountForUser(this.global.user);
            componentBinding.setProperty("accountId", this.global.account.id);
        }
        if (!componentBinding.getProperty("accountId")) {
            this.setState({errorText:"Please fill out all fields"});
            return;
        }
        await AssetUtil.addAssetFields(componentBinding);
        componentBinding.setProperty("lastUpdated", DateUtil.now());

        if (!componentBinding.validate()) {
            this.setState({errorText:"Please fill out all fields"});
            return;
        }

        let validateCorrectCounty = await AssetUtil.validateCorrectCounty(componentBinding.item);
        if (!validateCorrectCounty) {
            this.setState({errorText:"You don't have access to create assets for this county"});
            return;
        }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        // TODO: Need to get id back
        //await HistoryUtil.createHistory(this.state.id, Constants.HISTORY_TYPE_ASSET, this.global.user.id, "Created asset");

        callback(componentBinding.item);
    }

    // Render

    public render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                this.state.errorText ? <Alert severity="error">{this.state.errorText}</Alert> : null,
                this.global.user?.admin ? cf.renderBoxPadded(cf.renderSelectField("accountId", "Account", this.global.accountOptions, null, null, null)) : null,
                cf.renderBoxPadded(cf.renderTextField("assetName", "Name", this.componentRefs[0], null, {required:true})),
                cf.renderBoxPadded(cf.renderTextField("assetAddress", "Address (123 Main Street, Chapel Hill, NC)", this.componentRefs[1], null, {required:true}))
            ])
        )
    }
}

export default dialog(AddAssetDialog)