export default class AssetEventQuery {

    public static GetAssetEvents = `query GetAssetEventQuery {
        getAssetEvents {
            id
            assetId
            assetEventTitle
            assetEventDescription
            assetEventStartDate
            assetEventEndDate
            assetEventStartTime
            assetEventEndTime
            assetEventRecurrence
            assetEventCustomRecurrence
            assetEventProducts
            assetEventTags
            photo
            primaryImage
            isActive
        }
    }`;

    public static GetAssetEventsForAsset = `query GetAssetEventQuery($assetId: String) {
        getAssetEventsForAsset (
            assetId:$assetId
        ) {
            id
            assetId
            assetEventTitle
            assetEventDescription
            assetEventStartDate
            assetEventEndDate
            assetEventStartTime
            assetEventEndTime
            assetEventRecurrence
            assetEventCustomRecurrence
            assetEventProducts
            assetEventTags
            photo
            primaryImage
            isActive
        }
    }`;

    public static GetAssetEvent = `query GetAssetEvent($id: ID) {
        getAssetEvent(
            id:$id
        ) {
            id
            assetId
            assetEventTitle
            assetEventDescription
            assetEventStartDate
            assetEventEndDate
            assetEventStartTime
            assetEventEndTime
            assetEventRecurrence
            assetEventCustomRecurrence
            assetEventProducts
            assetEventTags
            photo
            primaryImage
            isActive
        }
    }`;

    public static CreateAssetEvent = `mutation CreateAssetEventMutation($input: AssetEventInput!) {
        createAssetEvent(input: $input) {
            id
            assetId
            assetEventTitle
            assetEventDescription
            assetEventStartDate
            assetEventEndDate
            assetEventStartTime
            assetEventEndTime
            assetEventRecurrence
            assetEventCustomRecurrence
            assetEventProducts
            assetEventTags
            photo
            primaryImage
            isActive
        }
    }`;

    public static DeleteAssetEvent = `mutation DeleteAssetEventMutation($id: ID!) {
        deleteAssetEvent(id: $id) {
            id
        }
    }`;
}