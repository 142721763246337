import * as React from 'reactn';
import {ComponentFactory, ComponentUtil, DateUtil, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from 'react-ga';
import Styles from "../style/Styles";
import Constants from "../util/Constants";

interface IState {
    componentFactory:ComponentFactory,
}

class AddFolderDialog extends React.Component<IBaseDialogProps, IState> {

    public components = ComponentUtil.createRefs(1);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        console.log(props.param);
        componentFactory.addDefaultBinding(this.global.dataManager, "createFile", props.param);

        this.state = {
            componentFactory:componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/addFolderDialog');
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        componentBinding.setProperty("fileType", Constants.FILE_TYPE_FOLDER);
        componentBinding.setProperty("fileDate", DateUtil.nowFormat());
        componentBinding.setProperty("fileUserId", this.global.user.id);

        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox(
                cf.renderBoxPadded([
                    cf.renderBoxPadded(cf.renderTextField("fileName", "Folder Name"))
                ])
            )
        )
    }
}

export default dialog(AddFolderDialog)