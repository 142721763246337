import * as React from 'reactn';
import {ArrayUtil, ComponentFactory, dialog, IBaseDialogProps, SelectItem} from "oca-lib-web";
import Constants from "../util/Constants";
import ReactGA from "react-ga";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    assetTagGroupOptions:Array<SelectItem>
}

class AddAssetTagDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createAssetTag", props.param);

        this.state = {
            componentFactory:componentFactory,
            assetTagGroupOptions: []
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/assetTagDialog');

        let assetTagGroupOptions = this.state.componentFactory.createOptions(await this.global.dataManager.get("getAssetTagGroups"), "id", "assetTagGroupName");
        this.setState({assetTagGroupOptions:assetTagGroupOptions});
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    render() {
        let cf = this.state.componentFactory;
        let assetGroups = Constants.ASSET_GROUP_CATEGORIES;
        ArrayUtil.sort(assetGroups, "label");

        return (
            cf.renderBox([
                cf.renderBoxPadded(cf.renderTextField("assetTagName", "Asset Tag Name")),
                cf.renderBoxPadded(cf.renderSelectField("assetCategories", "Asset Categories", assetGroups, null, null, {multiple:true})),
                cf.renderBoxPadded(cf.renderSelectField("assetTagGroupId", "Asset Tag Group", this.state.assetTagGroupOptions)),
                cf.renderBoxPadded(cf.renderSwitchField("filterPriority", "Filter Priority"))
            ])
        )
    }
}

export default dialog(AddAssetTagDialog)