import * as React from 'reactn';
import {base, BaseColor, ComponentFactory, IBaseProps, StringUtil} from "oca-lib-web";
import Sidebar from "../component/Sidebar";
import {addCallback} from "reactn";
import AWSAuth from "../auth/aws/AWSAuth";
import 'react-bulma-components/dist/react-bulma-components.min.css';
import ComponentUtil from "../util/ComponentUtil";
import AppIssueDialog from "../component/AppIssueDialog";
import {createRef} from "react";
import AWSStorage from "../auth/aws/AWSStorage";
import ImageUtil from "../util/ImageUtil";
import Imgix from "react-imgix";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";

interface IState {
    componentFactory:ComponentFactory,
    dialogs:Array<any>
}

class ProfilePage extends React.Component<IBaseProps, IState> {
    public appIssueDialog = createRef<any>();
    public componentRefs = ComponentUtil.createRefs(3);

    constructor() {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        let dialogs = [
            <AppIssueDialog {...componentFactory.renderDialogProps("Add Issue", "Save", this.updateData.bind(this), this.appIssueDialog)} />,
        ]

        this.state = {
            componentFactory:componentFactory,
            dialogs:dialogs
        };
    }

    async componentDidMount() {
        let componentFactory = this.state.componentFactory;
        addCallback(global => {
            console.log("callback");
            console.log(this.global.isDataLoaded);
            componentFactory.addDefaultBinding(this.global.dataManager, "createUser", global.user);
            this.setState({componentFactory:componentFactory});
            return null;
        });
        //console.log("callback")
        if (this.global.user) {
            componentFactory.addDefaultBinding(this.global.dataManager, "createUser", this.global.user);
            this.setState({componentFactory:componentFactory});
        }
    }

    updateData() {
        this.props.base.notify("Thank you! The NC Farms app team will look into your issue and respond to you soon");
    }

    // Methods

    async uploadPictureTapped(e:any) {
        if (!e.nativeEvent.target || !e.nativeEvent.target.files) { return }

        const file = e.nativeEvent.target.files[0];
        const fileName = StringUtil.uuid();
        await AWSStorage.put(fileName, file);
        console.log("uploaded file: " + fileName);

        let componentBinding = this.state.componentFactory.getDefaultBinding();
        componentBinding.setProperty("photo", fileName);
        this.forceUpdate();
        //componentBinding.saveBinding();
    }

    async saveButtonTapped(e:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding()
        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();
        this.props.base.notify("user saved successfully");
    }

    async logoutButtonTapped(e:any) {
        this.global.dataManager.clear();
        await AWSAuth.signOut();
    }

    public render() {
        let cf = this.state.componentFactory;
        let photo = cf.getDefaultBinding()?.getProperty("photo");
        let actions = [
            cf.renderButton("Save Profile", this.saveButtonTapped.bind(this)),
            cf.renderBoxProps({marginLeft: '20px'}, null),
            cf.renderButton("Logout", this.logoutButtonTapped.bind(this), {isFlat: true})
        ]
        console.log("rendering");

        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                cf.renderBoxProps({marginLeft: '90px'}, [
                    this.state.dialogs,
                    !this.global.isDataLoaded ?
                        VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                            :
                            [VisualUtil.getHeaderDisplay("Profile", actions),
                                cf.renderLine(),
                                <br/>,
                                cf.renderSizedColumns("half", [
                                    cf.renderBoxPadded(cf.renderTextField("email", "Administrator Email Address", this.componentRefs[0], null, {
                                        disabled: true,
                                        required: true
                                    })),
                                    cf.renderBoxPadded(cf.renderTextField("name", "Administrator Name", this.componentRefs[1], null, {required: true}))
                                ], [
                                    cf.renderBoxPadded(cf.renderTextField("phone", "Administrator Phone Number (XXX-XXX-XXXX)", this.componentRefs[2], null, {required: true})),
                                    cf.renderBoxPadded([
                                        cf.renderBox(photo ?
                                            <Imgix height={100} width={100} src={ImageUtil.fetch(photo)}/> : null),
                                        cf.renderBox(cf.renderUploadButton("Upload Image", "images", this.uploadPictureTapped.bind(this), null))
                                    ])
                                ])]
                ])
            ])
        )
    }
}

export default base(ProfilePage, Sidebar)