import * as React from 'reactn';
import {ComponentFactory, dialog, IBaseDialogProps} from "oca-lib-web";
import InvokeFields from "../util/InvokeFields";
import ReactGA from "react-ga";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory
}

class SendMessageDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        this.state = {
            componentFactory: new ComponentFactory(Styles.default)
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/sendMessage/verifyEmailAddressDialog');
    }

    async handleSave(callback:any) {
        let user = this.global.user;
        let input:VerifyEmailAddressInput = {
            emailAddress: user.email,
            field: InvokeFields.verifyEmailAddress
        }
        this.global.dataManager.perform("verifyEmailAddress", input);

        callback();
    }

    render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                cf.renderBox("Sending emails to your assets requires that your email address to be verified. Once your email address is validated, messages sent to your assets will come from the email address assigned to your account."),
                cf.renderBox("Once your press Verify an email will be sent to your email address from 'Amazon Web Services'. If you do not receive an email please check your spam and junk folders. Click on verification link within the email to complete the verification process.")
            ])
        )
    }
}

export default dialog(SendMessageDialog)