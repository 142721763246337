import * as React from 'reactn';
import {createRef} from "react";
import {
    TextComponent,
    Route,
    LocalCache,
    base,
    BaseColor,
    ComponentFactory
} from "oca-lib-web";
import AWSAuth from "../../auth/aws/AWSAuth";
import { SignIn } from "aws-amplify-react";
import loginHero from '../../image/heroLogin.png';
import logo from '../../image/visit_nc_farms_logo.png';
import { getGlobal } from 'reactn';
import Styles from "../../style/Styles";

interface IProps {
    base: any,
    authSettings: AuthSettings,
    onStateChange: any
}

class LoginPage extends SignIn<IProps> {

    public emailAddressComponent = createRef<TextComponent>();
    public passwordComponent = createRef<TextComponent>();

    // Application lifecycle methods

    constructor(props:any) {
        super();
        //this._validAuthStates = ["signIn", "signedOut", "signedUp"];

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(getGlobal().dataManager, null, {});
        this.state = {
            componentFactory: componentFactory
        };
    }


    // Event Handlers

    public async loginButtonTapped(event: any) {
        //const username = this.emailAddressComponent.current ? this.emailAddressComponent.current.getValue() : "";
        //const password = this.passwordComponent.current ? this.passwordComponent.current.getValue() : "";
        let binding = this.state.componentFactory.getDefaultBinding();
        let username = binding.getProperty("emailAddress");
        let password = binding.getProperty("password");

        try {
            this.props.base.wait();

            await AWSAuth.signIn(username, password);

            this.props.base.done();

            this.props.onStateChange('signedIn');

            // TODO: For now just check for specific admins and send an error if it's a different user

            if (this.props.authSettings.loginSuccessRoute) {
                Route.go(this, this.props.authSettings.loginSuccessRoute);
            }

        } catch (err) {
            console.log(err);
            this.props.base.done();

            // @ts-ignore
            if (err.code === "UserNotConfirmedException") {
                LocalCache.saveLocal("username", username);
                this.props.onStateChange('confirmSignUp');

            } else { // @ts-ignore
                if (err.code == "UserNotFoundException" || err.code == "NotAuthorizedException") {
                                // @ts-ignore
                    this.props.base.notify(err.message, "error");

                                //Notification.error(err.message, "Login Error");
                            }
            }
        }
    };

    // Render functions
    showComponent(theme:any) {
        let cf = this.state.componentFactory;
        const authSettings:AuthSettings = this.props.authSettings ? this.props.authSettings : {};

        return (
            cf.renderBoxProps({fullHeight:true, background:BaseColor.WHITE}, [
                cf.renderHorizontalLayout([
                    <img src={loginHero} width="100%" />,
                    [cf.renderBoxProps({pad:true, center:true, width:'600px', marginLeft:'auto', marginRight:'auto'},[
                        cf.renderBoxPadded([
                            cf.renderBoxPadded(<img src={logo} width={125} className="center-img" />),
                            <h2 className="loginTextLight" style={{marginTop:'20px', marginBottom:'20px'}}>Please login with your administrator credentials</h2>,
                            cf.renderBoxPadded(cf.renderTextField("emailAddress", "Email Address", null, null)),
                            cf.renderBoxPadded(cf.renderTextField("password", "Password", null, null, {type:'password', submitFunction:this.loginButtonTapped.bind(this)})),
                            cf.renderBoxPadded(cf.renderButton("Login", this.loginButtonTapped.bind(this), {primary:true, disableElevation:true, submitFunction:this.loginButtonTapped.bind(this)})),
                            authSettings.signupEnabled ?
                                cf.renderBoxPadded(
                                    cf.renderButton("Sign up", () => this.props.onStateChange('signUp'), {primary:true, isFlat:true})
                                )
                                : null,
                            cf.renderBoxPadded(
                                cf.renderButton("Forgot password?", () => this.props.onStateChange('forgotPassword'), {primary:true, isFlat:true})
                            )
                        ])
                    ])]
                ], ['33%','67%'])
                ])

        )
    }

    /*showComponent2(theme:any) {
        const authSettings:AuthSettings = this.props.authSettings ? this.props.authSettings : {};

        return (
            <Box fullHeight pad background="lightgray">
                <Card center pad size="small">
                    <PaperComponent>
                        <Box pad>
                            <Avatar style={{margin:'auto'}}><LockIcon /></Avatar>
                            <Box center pad><Title>Wellbeing Monitor Login</Title></Box>

                            <Box center pad>
                                { authSettings.facebookId ?
                                    <FacebookLogin
                                        appId={this.props.facebookId}
                                        size="small"
                                        textButton={"Facebook"}
                                        autoLoad={false}
                                        icon={<LockIcon />}
                                        fields="name,email,picture"
                                        callback={this.loginWithFacebook.bind(this)} /> : null }

                                { authSettings.googleId ?
                                    <GoogleLogin
                                        clientId={this.props.googleId}
                                        buttonText="Login with Google"
                                        onSuccess={this.loginWithGoogleSuccess}
                                        onFailure={this.loginWithGoogleSuccess} /> : null }


                                { (authSettings.facebookId || authSettings.googleId) ?
                                    <Box pad><Title>- OR -</Title></Box> : null }
                            </Box>

                            <Box pad><TextComponent fullWidth
                                                    ref={this.emailAddressComponent}
                                                    label="Email Address"
                                                    placeholder="Email Address"
                                                    submitFunction={this.loginButtonTapped.bind(this)} /></Box>
                            <Box pad><TextComponent fullWidth
                                                    ref={this.passwordComponent}
                                                    label="Password"
                                                    type="password"
                                                    placeholder="Password"
                                                    submitFunction={this.loginButtonTapped.bind(this)} /></Box>
                            <Box pad><ButtonComponent fullWidth
                                                      primary={true}
                                                      title="Login"
                                                      onTouchTap={this.loginButtonTapped.bind(this)} /></Box>

                        </Box>
                    </PaperComponent>
                </Card>

                { authSettings.signupEnabled ?
                    <Box center><ButtonComponent primary
                                                 isFlat
                                                 title="Signup"
                                                 onTouchTap={() => this.props.onStateChange('signUp')} /></Box>
                    : null }
                <Box center><ButtonComponent title="Having trouble logging in?"
                                             isFlat
                                             onTouchTap={() => this.props.onStateChange('forgotPassword')} /></Box>

            </Box>
        );
    }*/
}

export default base(LoginPage);