export default class AccountQuery {

    public static GetAccounts = `query GetAccountQuery {
        getAccounts {
            id
            accountName
            assetLicense
            counties
            users
            enhancedRegion
            paid
            live
            pinpointProjectId
            pinpointOriginationNumber
        }
    }`;

    public static GetAccount = `query GetAccountQuery($id: ID) {
        getAccount (id:$id) {
            id
            accountName
            assetLicense
            counties
            users
            enhancedRegion
            paid
            live
            pinpointProjectId
            pinpointOriginationNumber
        }
    }`;

    public static CreateAccount = `mutation CreateAccountMutation($input: AccountInput!) {
        createAccount(input: $input) {
            id
            accountName
            assetLicense
            counties
            users
            paid
            live
            pinpointProjectId
            pinpointOriginationNumber
        }
    }`;

    public static DeleteAccount = `mutation DeleteAccountMutation($id: ID!) {
        deleteAccount(id: $id) {
            id
        }
    }`;
}