import * as React from 'react';

interface IProps {
    authState?:any
    children:any
}

class AuthSuccessPage extends React.Component<IProps> {

    public static defaultProps = {};

    public state = {};

    public render() {
        if (this.props.authState == "signedIn") {
            return (
                this.props.children
            );
        } else {
            return null;
        }
    }
}

export default AuthSuccessPage