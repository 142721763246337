import {DateUtil, P} from "oca-lib-web";
import { getGlobal } from 'reactn';
import InvokeFields from "./InvokeFields";

export default class HistoryUtil {

    public static async createHistory(uniqueId:string, type:string, userId:string, content:string) {
        let input:HistoryInput = {
            field:InvokeFields.createHistory,
            uniqueId:uniqueId,
            type:type,
            content:content,
            userId:userId,
            date:DateUtil.nowFormat(),
        }
        await getGlobal().dataManager.save("createHistory", input);
    }

    public static async getHistory(uniqueId:string, type:string):Promise<Array<HistoryModel>> {
        let input:GetHistoryInput = {
            field:InvokeFields.getHistory,
            uniqueId:uniqueId
        }
        let results = await getGlobal().dataManager.get("getHistory", input);
        return P.resolve(results);
    }
}