export default class ItineraryQuery {

    public static GetItinerariesForAdmin = `query GetItinerariesQuery {
        getItinerariesForAdmin {
            id
            itineraryName
            itineraryDescription
            itineraryStartDate
            itineraryEndDate
            itineraryStartTime
            itineraryEndTime
            itineraryRecurrence
            itineraryCustomRecurrence
            itineraryAssetIds
            itineraryProducts
            itineraryTags
            primaryImage
            accountId
        }
    }`;

    public static GetItineraries = `query GetItinerariesQuery($accountId: String) {
        getItineraries (
            accountId:$accountId
        ) {
            id
            itineraryName
            itineraryDescription
            itineraryStartDate
            itineraryEndDate
            itineraryStartTime
            itineraryEndTime
            itineraryRecurrence
            itineraryCustomRecurrence
            itineraryAssetIds
            itineraryProducts
            itineraryTags
            primaryImage
            accountId
        }
    }`;

    public static GetItinerary = `query GetItinerary($id: ID) {
        getItinerary(
            id:$id
        ) {
            id
            itineraryName
            itineraryDescription
            itineraryStartDate
            itineraryEndDate
            itineraryStartTime
            itineraryEndTime
            itineraryRecurrence
            itineraryCustomRecurrence
            itineraryAssetIds
            itineraryProducts
            itineraryTags
            primaryImage
            accountId
        }
    }`;

    public static CreateItinerary = `mutation CreateItineraryMutation($input: ItineraryInput!) {
        createItinerary(input: $input) {
            id
            itineraryName
            itineraryDescription
            itineraryStartDate
            itineraryEndDate
            itineraryStartTime
            itineraryEndTime
            itineraryRecurrence
            itineraryCustomRecurrence
            itineraryAssetIds
            itineraryProducts
            itineraryTags
            primaryImage
            accountId
        }
    }`;

    public static DeleteItinerary = `mutation DeleteItineraryMutation($id: ID!) {
        deleteItinerary(id: $id) {
            id
        }
    }`;
}