// WARNING: DO NOT EDIT. This file is Auto-Generated by AWS Mobile Hub. It will be overwritten.

// Copyright 2017-2018 Amazon.com, Inc. or its affiliates (Amazon). All Rights Reserved.
// Code generated by AWS Mobile Hub. Amazon gives unlimited permission to
// copy, distribute and modify it.

// AWS Mobile Hub Project Constants
const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_auth_facebook': 'enable',
    'aws_cognito_identity_pool_id': 'us-east-1:5a1875c0-8526-440f-ac23-e6d45a18ef6a',
    'aws_cognito_region': 'us-east-1',
    'aws_facebook_app_id': '352074018638897',
    'aws_facebook_app_permissions': 'public_profile',
    'aws_google_app_permissions': 'email,profile,openid',
    'aws_google_web_app_id': '679450918143-gb6qb5jv4uhtdmfoouj6k38l3kn6e6ne.apps.googleusercontent.com',
    'aws_mobile_analytics_app_id': 'f3e8b5fe1d624acdbfdc52631715a5b4',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': '9e267bad-a45a-4484-afb8-811326bbfb87',
    'aws_project_name': 'Visit NC Farms Web Production',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'visitncfarmswebprodu-mobilehub-1830075174',
    'aws_sign_in_enabled': 'enable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'visitncfarmswebprodu-userfiles-mobilehub-1830075174',
    'aws_user_files_s3_bucket_region': 'us-east-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_PwO8ob7hk',
    'aws_user_pools_mfa_type': 'OFF',
    'aws_user_pools_web_client_id': '1q6q2a92r6tvc6m0u64odkg521',
    'aws_appsync_graphqlEndpoint': 'https://ebtqa7inmjdphfeiogk7ezts7u.appsync-api.us-east-1.amazonaws.com/graphql',
    'aws_appsync_region': 'us-east-1',
    'aws_appsync_authenticationType': 'API_KEY',
    'aws_appsync_apiKey': 'da2-ao7kurcnvvg33i5f3i3v5f6laa',
}

/*const awsmobile = {
    'aws_app_analytics': 'enable',
    'aws_auth_facebook': 'enable',
    'aws_cognito_identity_pool_id': 'us-east-1:5a1875c0-8526-440f-ac23-e6d45a18ef6a',
    'aws_cognito_region': 'us-east-1',
    'aws_facebook_app_id': '352074018638897',
    'aws_facebook_app_permissions': 'public_profile',
    'aws_google_app_permissions': 'email,profile,openid',
    'aws_google_web_app_id': '679450918143-gb6qb5jv4uhtdmfoouj6k38l3kn6e6ne.apps.googleusercontent.com',
    'aws_mandatory_sign_in': 'enable',
    'aws_mobile_analytics_app_id': 'f3e8b5fe1d624acdbfdc52631715a5b4',
    'aws_mobile_analytics_app_region': 'us-east-1',
    'aws_project_id': '9e267bad-a45a-4484-afb8-811326bbfb87',
    'aws_project_name': 'Visit NC Farms Web Production',
    'aws_project_region': 'us-east-1',
    'aws_resource_name_prefix': 'visitncfarmswebprodu-mobilehub-1830075174',
    'aws_sign_in_enabled': 'enable',
    'aws_user_files': 'enable',
    'aws_user_files_s3_bucket': 'visitncfarmswebprodu-userfiles-mobilehub-1830075174',
    'aws_user_files_s3_bucket_region': 'us-east-1',
    'aws_user_pools': 'enable',
    'aws_user_pools_id': 'us-east-1_1Lq07e4XV',
    'aws_user_pools_web_client_id': '50lc0vbqimh9ujee10n922sgtp',
    'aws_appsync_graphqlEndpoint': 'https://grqpd5n3tzhdpon4stl5khipri.appsync-api.us-east-1.amazonaws.com/graphql',
    'aws_appsync_region': 'us-east-1',
    'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS',
}*/

export default awsmobile;
