import * as React from 'reactn';
import {
    ArrayUtil,
    base, BaseColor,
    ButtonComponent, ComponentFactory, DateUtil,
    DialogUtil, IBaseProps, SelectItem,
    SelectComponent, LocalCache
} from "oca-lib-web";
import AddSponsorDialog from "../component/AddSponsorDialog";
import {createRef, useEffect, useState} from "react";
import Constants from "../util/Constants";
import {addCallback} from "reactn";
import Sidebar from "../component/Sidebar";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";
import { getGlobal } from 'reactn';

/*interface IState {
    rows:Array<SponsorModel>,
    dialogs:Array<any>,
    selectedAccount?:string,
    selectedCounty?:string,
    componentFactory:ComponentFactory
}*/

//class SponsorPage extends React.Component<IBaseProps, IState> {
function SponsorPage(props:IBaseProps) {

    const addSponsorDialog = createRef<any>();
    const [rows, setRows] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedCounty, setSelectedCounty] = useState(null);
    //const [loaded, setLoaded] = useState(false);
    //const [componentFactory, setComponentFactory] = useState(null);

    const cf = new ComponentFactory(Styles.default);
    const headers = ["Sponsor Name", "Sponsor Type", "Start Date", "End Date", "Primary", "Action"];
    if (getGlobal().user?.admin) {
        headers.splice(5, 0, "Account");
    }

    /*let dialogs = [
        <AddSponsorDialog ref={addSponsorDialog} title="Add Sponsor" actionTitle="Save" actionCallback={createSponsorCallback.bind(this)}  />
    ]*/
    //public logger = new Logger("visit nc farms", "DEBUG");

    /*constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        let dialogs = [
            <AddSponsorDialog ref={this.addSponsorDialog} title="Add Sponsor" actionTitle="Save" actionCallback={this.createSponsorCallback.bind(this)}  />
        ]

        this.state = {
            rows: [],
            dialogs:dialogs,
            componentFactory: componentFactory
        };
    }*/

    /*async componentDidMount() {
        addCallback(global => {
            this.initSponsors();
            return null;
        });
        this.initSponsors();
    }*/

    useEffect(() => {
        addCallback(global => {
            initSponsors();
            return null;
        });
        initSponsors();
        //setLoaded(true);
    }, []);

    useEffect(  () => {
        getSponsors();
    }, [selectedAccount, selectedCounty]);

    const initSponsors = () => {
        if (!getGlobal().isDataLoaded) return;
        
        let selectedCountySponsor = LocalCache.getSession("selectedCountySponsor");
        setSelectedCounty(selectedCountySponsor);
        getSponsors();
        /*if (selectedCounty) {
            this.setState({selectedCounty: selectedCounty}, function (this: SponsorPage) {
                this.getSponsors();
            }.bind(this));
        } else {
            this.getSponsors();
        }*/
    }

    const deleteItemTapped = async (item:SponsorModel) => {
        //await this.global.data.deleteSponsor(item);
        await getGlobal().dataManager.delete("deleteSponsor", item);
        await getSponsors();

        props.base.notify("Sponsor deleted");
    }

    const createSponsorCallback = async (item?:SponsorModel) => {
        getSponsors();

        const message = item ? "Sponsor created" : "Sponsor could not be created";
        props.base.notify(message);
    }

    const selectedAccountChanged = async (value:any, key:string) => {
        const selectedAccount = value != "" ? value : null;
        setSelectedAccount(selectedAccount);
        //getSponsors();
        /*this.setState({selectedAccount: selectedAccount}, function(this:SponsorPage) {
            this.getSponsors();
        }.bind(this));*/
    }

    const selectedCountyChanged = async (value:any, key:string) => {
        LocalCache.saveSession("selectedCountySponsor", value);
        const selectedCounty = value != "" ? value : null;
        setSelectedCounty(selectedCounty);
        //getSponsors();
        /*this.setState({selectedCounty:selectedCounty}, function(this:SponsorPage) {
            this.getSponsors();
        }.bind(this));*/
    }

    const getSponsors = async () => {
        let sponsors:Array<SponsorModel> = [];
        if (selectedAccount) {
            sponsors = await getGlobal().dataManager.get("getSponsors", selectedAccount);
        } else if (selectedCounty) {
            sponsors = await getGlobal().dataManager.get("getSponsors", getGlobal().account.id);
            sponsors = ArrayUtil.getItems(sponsors, "sponsorCounty", selectedCounty);

        } else if (getGlobal().user?.admin) {
            sponsors = await getGlobal().dataManager.get("getSponsorsForAdmin");
        } else if (getGlobal().account) {
            //sponsors = await this.global.data.getSponsors(this.global.account.id);
            sponsors = await getGlobal().dataManager.get("getSponsors", getGlobal().account.id);
        }

        ArrayUtil.sort(sponsors, "sponsorType");
        //this.setState({rows: sponsors});
        console.log("setting sponsors");
        setRows(sponsors);
    }

    const getRow = (item:SponsorModel) => {
        let editFunction = () => { DialogUtil.open(addSponsorDialog.current, item) };
        let deleteFunction = () => {
            props.base.alert("Confirm Delete", "Are you sure you want to delete this sponsor? This action cannot be undone.", deleteItemTapped, item)
        }
        const actions = cf.renderEditDeleteButtons(editFunction, deleteFunction)

        let sponsorTypeList = item.sponsorType.split(",");
        let sponsorTypeLabelList:Array<String> = [];
        for (let sponsorType of sponsorTypeList) {
            sponsorTypeLabelList.push(Constants.getAssetCategoryLabel(sponsorType))
        }

        const row = [
            item.sponsorName,
            sponsorTypeLabelList.join(","),
            item.sponsorStartDate ? DateUtil.format(item.sponsorStartDate, "MM/DD/YYYY") : "",
            item.sponsorEndDate ? DateUtil.format(item.sponsorEndDate, "MM/DD/YYYY") : "",
            item.primary ? "Yes" : "No",
            actions
        ]
        if (getGlobal().user?.admin) {
            let accountItem = ArrayUtil.getItem(getGlobal().accountOptions, "value", item.accountId)
            row.splice(5,0,accountItem.label);
        }
        return row;
    }

    const dialogs = [
        <AddSponsorDialog ref={addSponsorDialog} title="Add Sponsor" actionTitle="Save" actionCallback={createSponsorCallback}  />
    ];

    const headerRowContent = <ButtonComponent primary={true} title="Add Sponsor"
                                              onTouchTap={() => { DialogUtil.open(addSponsorDialog.current)}}/>;
    let headerRowLeftContent;
    if (getGlobal().user?.admin) {
        headerRowLeftContent = <SelectComponent
            id="county"
            inputType="bootstrap"
            variant="outlined"
            value={selectedAccount}
            options={getGlobal().accountOptions}
            onChange={selectedAccountChanged}/>

    } else if (getGlobal().account?.enhancedRegion) {
        let counties = getGlobal().account?.counties.split(",");
        if (counties && counties.length > 1) {
            let countyOptions: Array<SelectItem> = [];
            for (let county of counties) {
                countyOptions.push(SelectItem.create(county, county));
            }
            countyOptions.splice(0, 0, SelectItem.create("", "All Counties"));
            headerRowLeftContent = <SelectComponent
                id="counties"
                inputType="bootstrap"
                variant="outlined"
                disableUnderline={true}
                value={selectedCounty || ""}
                options={countyOptions}
                onChange={selectedCountyChanged}/>;
        }
    }

    return (
        cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
            dialogs,
            cf.renderBoxProps({marginLeft: '90px'}, [
                !getGlobal().isDataLoaded ?
                    VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                        :
                        [VisualUtil.getHeaderDisplay("Sponsors", headerRowContent),
                            cf.renderLine(),
                            VisualUtil.renderActionBar(headerRowLeftContent),
                            cf.renderTable(headers, rows, getRow)]
            ])
        ])
    );

    /*public render() {
        let cf = this.state.componentFactory;
        let headerRowContent = <ButtonComponent primary={true} title="Add Sponsor"
                                                onTouchTap={DialogUtil.openFunction(this.addSponsorDialog.current)}/>;
        var headerRowLeftContent;

        if (this.global.user?.admin) {
            headerRowLeftContent = <SelectComponent
                id="county"
                inputType="bootstrap"
                variant="outlined"
                value={this.state.selectedAccount}
                options={this.global.accountOptions}
                onChange={this.selectedAccountChanged.bind(this)}/>

        } else if (this.global.account?.enhancedRegion) {
            let counties = this.global.account?.counties.split(",");
            if (counties && counties.length > 1) {
                let countyOptions: Array<SelectItem> = [];
                for (let county of counties) {
                    countyOptions.push(SelectItem.create(county, county));
                }
                countyOptions.splice(0, 0, SelectItem.create("", "All Counties"));
                headerRowLeftContent = <SelectComponent
                    id="counties"
                    inputType="bootstrap"
                    variant="outlined"
                    disableUnderline={true}
                    value={this.state.selectedCounty || ""}
                    options={countyOptions}
                    onChange={this.selectedCountyChanged.bind(this)}/>;
            }
        }

        var headers = ["Sponsor Name", "Sponsor Type", "Start Date", "End Date", "Primary", "Action"];
        if (this.global.user?.admin) {
            headers.splice(5, 0, "Account");
        }

        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                this.state.dialogs,
                cf.renderBoxProps({marginLeft: '90px'}, [
                    !this.global.isDataLoaded ?
                        VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                            :
                            [VisualUtil.getHeaderDisplay("Sponsors", headerRowContent),
                                cf.renderLine(),
                                VisualUtil.renderActionBar(headerRowLeftContent),
                                cf.renderTable(headers, this.state.rows, this.getRow.bind(this))]
                ])
            ])
        )
    }*/
}

export default base(SponsorPage, Sidebar)