import * as React from 'reactn';
import Sidebar from "../component/Sidebar";
import {addCallback} from "reactn";
import {
    ArrayUtil,
    base,
    BaseColor,
    BaseIcon, ButtonComponent,
    ComponentFactory,
    ComponentUtil, DateUtil,
    DialogUtil,
    IBaseProps,
    Route, StringUtil, SwitchComponent
} from "oca-lib-web";
import AssetSelectionDialog, {SelectionType} from "../component/AssetSelectionDialog";
import {createRef} from "react";
import DateComponent from "oca-lib-web/dist/components/DateComponent";
import AddCommentDialog from "../component/AddCommentDialog";
import AWSStorage from "../auth/aws/AWSStorage";
import ImageUtil from "../util/ImageUtil";
import Imgix from "react-imgix";
import Constants from "../util/Constants";
import MapUtil from "../util/MapUtil";
import SelectComponent from "oca-lib-web/dist/components/SelectComponent";
import {Alert} from "@mui/lab";
import AssetUtil from "../util/AssetUtil";
import SelectItem from "oca-lib-web/dist/util/SelectItem";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";

interface IState {
    componentFactory:ComponentFactory,
    assetRows:Array<any>,
    dialogs:Array<any>,
    selected: Array<any>,
    assetImages:Array<any>,
    pictures:Array<any>,
    assetTagOptions:Array<SelectItem>,
    productOptions:Array<SelectItem>,
    errorText?: string,
    url?: string
}

class ItineraryDetailPage extends React.Component<IBaseProps, IState> {
    public componentRefs = ComponentUtil.createRefs(3);
    public startDateComponent = createRef<DateComponent>();
    public endDateComponent = createRef<DateComponent>();
    public startTimeComponent = createRef<DateComponent>();
    public endTimeComponent = createRef<DateComponent>();
    public recurrenceComponent = createRef<SelectComponent>();
    public customRecurrenceComponent = createRef<SelectComponent>();
    public assetSelectionDialog = createRef<any>();
    public addCommentDialog = createRef<any>();

    constructor() {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.setHandleUpdateCallback((value: any, key: string) => {
            if (this.state.errorText) {
                this.setState({errorText: null});
            }
        });
        let dialogs = [
            <AssetSelectionDialog {...componentFactory.renderDialogProps("Select Assets", "Save", this.addAssetsDialogCallback.bind(this), this.assetSelectionDialog)} />,
            <AddCommentDialog {...componentFactory.renderDialogProps("Add Comment", "Save", this.saveCommentCallback.bind(this), this.addCommentDialog)}  />
        ]

        this.state = {
            componentFactory:componentFactory,
            assetRows:[],
            dialogs:dialogs,
            selected:[],
            assetImages:[],
            pictures:[],
            assetTagOptions: [],
            productOptions: []
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.updateData();
            return null;
        });
        if (this.global.isDataLoaded) {
            this.updateData();
        }
    }

    async updateData() {
        let cf = this.state.componentFactory
        let assetTags = await this.global.dataManager.get("getAssetTags");
        let products = await this.global.dataManager.get("getProducts");
        this.setState({assetTagOptions:cf.createOptions(assetTags, "id", "assetTagName"), productOptions:cf.createOptions(products, "productName", "productName")});

        this.getItinerary();
        this.getPictures();
    }

    async setMapUrl() {
        let coordinates = [];
        for (let asset of this.state.assetRows) {
            coordinates.push({latitude:asset.assetLatitude, longitude:asset.assetLongitude});
        }
        let url = await MapUtil.getMapForPoints(coordinates, 400, 400);
        this.setState({url:url});
    }

    async getItinerary() {
        let itinerary:Itinerary;
        let id = Route.param(this, "id");
        if (id) {
            itinerary = await this.global.dataManager.get("getItinerary", id);
            let assets = await AssetUtil.getAssetList();
            //let assets = await this.global.dataManager.get("getAssets", itinerary.accountId);
            let assetIds = itinerary.itineraryAssetIds.split(",");
            let assetRows = [];
            let coordinates:Array<{latitude:number, longitude:number}> = [];
            for (let assetId of assetIds) {
                let asset = ArrayUtil.getItem(assets, "id", assetId);
                if (asset) {
                    coordinates.push({latitude:asset.assetLatitude, longitude:asset.assetLongitude});
                    assetRows.push(asset);
                }
            }
            //let url = await MapUtil.getMapForPoints(coordinates, 400, 400);
            this.setState({assetRows:assetRows}, function(this:ItineraryDetailPage) {
                this.setMapUrl();
            }.bind(this));
        } else {
            itinerary = {
                id: StringUtil.uuid()
            }
        }
        console.log(itinerary);

        let componentFactory = this.state.componentFactory;
        componentFactory.addDefaultBinding(this.global.dataManager, "createItinerary", itinerary);
        this.setState({componentFactory:componentFactory}, function(this:ItineraryDetailPage) {
            if (itinerary) {
                if (this.startDateComponent.current && itinerary.itineraryStartDate) {
                    this.startDateComponent.current.handleDateChange(DateUtil.date(itinerary.itineraryStartDate));
                }
                if (this.endDateComponent.current && itinerary.itineraryEndDate) {
                    this.endDateComponent.current.handleDateChange(DateUtil.date(itinerary.itineraryEndDate));
                }
                if (this.startTimeComponent.current && itinerary.itineraryStartTime) {
                    this.startTimeComponent.current.handleDateChange(DateUtil.date(itinerary.itineraryStartTime));
                }
                if (this.endTimeComponent.current && itinerary.itineraryEndTime) {
                    this.endTimeComponent.current.handleDateChange(DateUtil.date(itinerary.itineraryEndTime));
                }
            }
        }.bind(this))
    }

    async getPictures() {
        let id = Route.param(this, "id");
        if (!id) return;

        let pictures = await AWSStorage.list(id);
        let assetImages:Array<AssetImageModel> = [];
        for (let picture of pictures) {
            let assetImage = await this.global.dataManager.get("getAssetImage", picture.key);
            if (assetImage) {
                assetImages.push(assetImage);
            }
        }

        this.setState({pictures: pictures, assetImages:assetImages});
    }

    // callbacks

    saveCommentCallback(item:any) {
        this.props.base.notify("Image saved");
        this.getPictures();
    }

    addAssetsDialogCallback(items:Array<AssetModel>) {
        console.log(items);
        this.setState({errorText:null, assetRows:items}, function(this:ItineraryDetailPage) {
            this.setMapUrl();
        }.bind(this));
    }

    onRowSelection(item: any, data: any) {
        console.log(data);
        let selectedItems = this.state.selected;
        let found = false;
        for (let selectedItem of selectedItems) {
            if (data.id == selectedItem.id) {
                found = true;
            }
        }
        if (found) {
            ArrayUtil.deleteItemByKey(data, "id", selectedItems);
        } else {
            selectedItems.push(data);
        }
        this.setState({selected:selectedItems})
    }

    isRowSelected(item:AssetModel):boolean {
        let selectedItems = this.state.selected;
        for (let selectedItem of selectedItems) {
            if (item.id == selectedItem.id) return true;
        }
        return false;
    }

    async uploadPictureTapped(e:any) {
        if (!e.nativeEvent.target || !e.nativeEvent.target.files) { return }

        console.log("bytes: " + e.nativeEvent.target.files[0].size);
        if(e.nativeEvent.target.files[0].size > 1048576) {
            this.props.base.notify("Image is too big to upload (max 1MB)", "error");
            return;
        }

        const file = e.nativeEvent.target.files[0];
        const id = this.state.componentFactory.getDefaultBinding().getProperty("id");
        let fullFileName = id + "/" + StringUtil.uuid();
        await AWSStorage.put(fullFileName, file);
        console.log("uploaded file: " + fullFileName);

        this.getPictures();
    }

    saveButtonTapped() {
        let binding = this.state.componentFactory.getDefaultBinding();
        let accountId = binding.getProperty("accountId");
        if (!accountId) {
            let account = this.global.account;
            accountId = account?.id;
            binding.setProperty("accountId", accountId);
        }
        if (!accountId) {
            this.setState({errorText:"Please select an account"});
            return;
        }

        // TODO: Figure out why validate is throwing error on another tab
        /*if (!binding.validate()) {
            this.setState({errorText:"Please fill out all fields"});
            return;
        }*/
        let itineraryName = binding.getProperty("itineraryName");
        let itineraryDescription = binding.getProperty("itineraryDescription");
        if (StringUtil.isNullOrEmpty(itineraryName)) {
            this.setState({errorText:"Please enter an itinerary name"});
            return;
        } else if (StringUtil.isNullOrEmpty(itineraryDescription)) {
            this.setState({errorText:"Please enter an itinerary description"});
            return;
        }

        let eventStartDate = this.startDateComponent.current?.getValue();
        let eventEndDate = this.endDateComponent.current?.getValue();
        let eventStartTime = this.startTimeComponent.current?.getValue();
        let eventEndTime = this.endTimeComponent.current?.getValue();

        if (eventStartDate != null && eventEndDate != null &&
            DateUtil.isAfter(eventStartDate, eventEndDate, 'day')) {
            this.setState({errorText:"Event end date must be after event start date"});
            return;
        } else if (eventStartTime != null && eventEndTime != null &&
            DateUtil.isAfter(eventStartTime, eventEndTime, 'hour')) {
            this.setState({errorText:"Event end time must be after event start time"});
            return
        }

        let assetEventRecurrence = binding.getProperty("assetEventRecurrence");
        let assetEventCustomRecurrence = binding.getProperty("assetEventCustomRecurrence");
        if (assetEventRecurrence == "custom" && (!assetEventCustomRecurrence || assetEventCustomRecurrence == "")) {
            this.setState({errorText:"Custom event recurrence requires you to enter days of the week"});
            return
        } else if (assetEventRecurrence != "custom" && assetEventCustomRecurrence && assetEventCustomRecurrence != "") {
            this.setState({errorText:"Days of the week should only be entered for custom event recurrence"});
            return
        }

        if (this.state.assetRows.length < 3) {
            this.setState({errorText:"Itineraries must contain at least 3 assets"});
            return;
        }

        let assetIds = [];
        for (let item of this.state.assetRows) {
            assetIds.push(item.id);
        }
        binding.setProperty("itineraryAssetIds", assetIds.join(","));
        console.log(binding.item);
        binding.saveBinding();
        Route.goBack(this);

        //this.props.base.notify("Itinerary saved.");
    }

    toggleAssetPosition(item:any, direction:string) {
        let assetRows = this.state.assetRows;
        let indexOf = assetRows.indexOf(item);
        if ((indexOf == 0 && direction == "up") || (indexOf == assetRows.length - 1 && direction == "down")) {
            return;
        }

        indexOf = direction == "up" ? indexOf - 1 : indexOf + 1;
        ArrayUtil.deleteItem(item, assetRows);
        assetRows.splice(indexOf, 0, item);
        this.setState({assetRows:assetRows}, function(this:ItineraryDetailPage) {
            this.setMapUrl();
        }.bind(this));
    }

    async removePicture(picture:any) {
        console.log(picture);
        console.log("about to delete: " + JSON.stringify(picture));
        await AWSStorage.remove(picture.key);

        let assetImage = ArrayUtil.getItem(this.state.assetImages, "id", picture.key);
        if (assetImage) {
            await this.global.dataManager.delete("deleteAssetImage", assetImage);
        }

        this.getPictures();
        this.props.base.notify("Image deleted");
    }

    handleSwitchChanged(key) {
        console.log(key);
        let binding = this.state.componentFactory.getDefaultBinding();
        binding.setProperty("primaryImage", key);

        binding.saveBinding();
        this.props.base.notify("Primary image updated");
    }

    // render

    renderAssetEventImageRow(picture:any) {
        let cf = this.state.componentFactory;
        let editFunction = DialogUtil.openFunction(this.addCommentDialog.current, picture.key);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this comment? This action cannot be undone.", this.removePicture.bind(this), picture);
        let actions = cf.renderEditDeleteButtons(editFunction,deleteFunction);

        let assetImage = ArrayUtil.getItem(this.state.assetImages, "id", picture.key);
        let binding = this.state.componentFactory.getDefaultBinding();
        let primaryImage = binding?.getProperty("primaryImage");
        return [
            <Imgix height={100} width={100} src={ImageUtil.fetch(picture.key)} />,
            assetImage ? assetImage.comment : "",
            assetImage ? assetImage.isActive ? <span className="active">ACTIVE</span> : <span className="inactive">INACTIVE</span> : <span className="active">ACTIVE</span>,
            <SwitchComponent id="assetSwitch" primary checked={primaryImage == picture.key} onChange={(e:any) => this.handleSwitchChanged(picture.key)} />,
            actions
        ];
    }

    renderAssetImages() {
        let cf = this.state.componentFactory;
        let headers = ["Image", "Comment", "Active", "Primary", "Action"];
        let headerRowContent = [<ButtonComponent primary disabled={this.state.pictures.length >= Constants.MAX_NUMBER_OF_ASSET_IMAGES} title="Upload" forUpload={true} uploadAccept="images" onTouchTap={this.uploadPictureTapped.bind(this)} />]

        return ([
            VisualUtil.renderActionBar(null, headerRowContent),
            cf.renderTable(headers, this.state.pictures, this.renderAssetEventImageRow.bind(this)),
        ])
    }

    renderDetails() {
        let cf = this.state.componentFactory;
        return ([
            this.global.user?.admin ? cf.renderBoxPadded(cf.renderSelectField("accountId", "Account", this.global.accountOptions)) : null,
            cf.renderBoxPadded(cf.renderTextField("itineraryName", "Itinerary Title", this.componentRefs[0], null, {required:true})),
            cf.renderBoxPadded(cf.renderTextField("itineraryDescription", "Itinerary Description", this.componentRefs[1], null, {required:true})),
            cf.renderBoxPadded(cf.renderDateField("itineraryStartDate", "Itinerary Start Date", "date", this.startDateComponent, null, {required:false})),
            cf.renderBoxPadded(cf.renderDateField("itineraryEndDate", "Itinerary End Date", "date", this.endDateComponent, null, {required:false})),
            cf.renderBoxPadded(cf.renderDateField("itineraryStartTime", "Itinerary Start Time", "time", this.startTimeComponent, null, {required:false})),
            cf.renderBoxPadded(cf.renderDateField("itineraryEndTime", "Itinerary End Time", "time", this.endTimeComponent, null, {required:false})),
            cf.renderBoxPadded(cf.renderSelectField("itineraryRecurrence", "Itinerary Recurrence", Constants.EVENT_RECURRENCE_SELECTION, this.recurrenceComponent, null, {required:false})),
            cf.renderBoxPadded(cf.renderSelectField("itineraryCustomRecurrence", "Custom Itinerary Recurrence", Constants.EVENT_CUSTOM_RECURRENCE_SELECTION, this.customRecurrenceComponent, null, {multiple:false})),
            cf.renderBoxPadded(cf.renderSelectField("itineraryProducts", "Product Tags", this.state.productOptions, null, null, {multiple:true, required:false})),
            cf.renderBoxPadded(cf.renderSelectField("itineraryTags", "Asset Tags", this.state.assetTagOptions, null, null, {multiple:true, required:false})),
        ]);
    }

    deleteAssetTapped(item:any) {
        let assetRows = this.state.assetRows;
        ArrayUtil.deleteItemByKey(item, "id", assetRows)
        this.setState({assetRows:assetRows}, function(this:ItineraryDetailPage) {
            this.setMapUrl();
        }.bind(this));
    }

    getAssetRow(item:AssetModel) {
        let cf = this.state.componentFactory;
        let indexOf = this.state.assetRows.indexOf(item);
        let canUp = indexOf == 0;
        let canDown = indexOf == this.state.assetRows.length - 1;
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this asset from the itinerary? This action cannot be undone.", this.deleteAssetTapped.bind(this), item);
        let actions = cf.renderBox([
            cf.renderIconButton(BaseIcon.ICON_KEYBOARD_ARROW_UP, () => { this.toggleAssetPosition(item, "up") }, "Move Up", {disabled:canUp}),
            cf.renderIconButton(BaseIcon.ICON_KEYBOARD_ARROW_DOWN, () => { this.toggleAssetPosition(item, "down") }, "Move Down", {disabled:canDown}),
            cf.renderIconButton(BaseIcon.ICON_DELETE, deleteFunction, "Delete", {color:'secondary'})
        ]);

        return [
            <div style={{marginLeft:20,height:20,width:20,backgroundColor:Constants.COLORS[indexOf], borderRadius:10}}>&nbsp;</div>,
            item.assetName,
            item.assetAddress,
            actions
        ]
    }

    renderAssetDetails() {
        let cf = this.state.componentFactory;
        let ids = [];
        let coordinates:Array<{latitude:number, longitude:number}> = [];
        for (let item of this.state.assetRows) {
            coordinates.push({latitude:item.assetLatitude, longitude:item.assetLongitude});
            ids.push(item.id);
        }
        //console.log(await MapUtil.getMapForPoints(coordinates, 400, 400));
        let accountId = cf.getDefaultBinding()?.getProperty("accountId");
        let actionButtons = [
            cf.renderButton("Add", DialogUtil.openFunction(this.assetSelectionDialog.current, {type:SelectionType.Asset, selected:ids, accountId:accountId})),
        ]

        return cf.renderSizedColumns("two-thirds", [
                VisualUtil.renderActionBar(null, actionButtons),
                cf.renderTable(["", "Asset Name", "Asset Address", ""], this.state.assetRows, this.getAssetRow.bind(this), {fitToContent:true})
            ],
            this.state.assetRows.length > 0 && this.state.url ? <img src={this.state.url} /> : null
        )
    }

    render() {
        let cf = this.state.componentFactory;
        let headerRowContent = cf.renderButton("Save Itinerary", this.saveButtonTapped.bind(this));
        let tabs = ["Details", "Assets", "Images"];
        return (
            !this.global.isDataLoaded ?
                VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                    :
                    cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                        this.state.dialogs,
                        cf.renderBoxProps({marginLeft: '90px'}, [
                            VisualUtil.getHeaderDisplay("Itinerary", headerRowContent),
                            cf.renderLine(),
                            this.state.errorText ? <Alert severity="error">{this.state.errorText}</Alert> : null,
                            cf.renderTabs(tabs, [
                                this.renderDetails(),
                                this.renderAssetDetails(),
                                this.renderAssetImages()
                            ])
                        ])
                    ])
        )
    }
}

export default base(ItineraryDetailPage, Sidebar)