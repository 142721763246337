import * as React from 'reactn';
import ImageUtil from "./ImageUtil";
import {ComponentFactory} from "oca-lib-web";
import Styles from "../style/Styles";
import {CircularProgress} from "@mui/material";

export default class VisualUtil {

    public static showLoading() {
        return <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100vh'}}>
            <CircularProgress />
        </div>;
    }

    public static showNoAccount() {
        return <div style={{display:'flex', alignItems:'center', justifyContent:'center', height:'100vh'}}>
            <h2 className="loginTextLight">Your account is not associated with any NC counties. Please contact an administrator for help.</h2>
        </div>
    }

    public static getNameAvatarDisplay(photo:string, name:string):any {
        return <div style={{display:'flex', alignItems:'center'}}>{ImageUtil.getPhoto(photo, null, true)}<span style={{marginLeft:'16px'}}>{name}</span></div>
    }

    public static getHeaderDisplay(header:string, items?:any):any {
        let cf = new ComponentFactory(Styles.default);
        return cf.renderBoxProps({marginTop:'20px', marginLeft:'20px', marginRight:'20px', marginBottom:'20px'},
            cf.renderHeader(header, items));
    }

    public static renderActionBar(left:any, right?:any) {
        let cf = new ComponentFactory(Styles.default);
        return cf.renderBoxProps({marginLeft:'20px', marginRight:'20px', marginTop:'20px', marginBottom:'20px'}, cf.renderRightLeftLayout(left,right));
    }

    /*public static renderLine() {
        return <hr style={{margin:0, padding:0}} />
    }*/
}