import * as React from 'reactn';
import {
    ArrayUtil,
    base, BaseColor,
    BaseIcon,
    ButtonComponent, ComponentFactory, DateUtil,
    IBaseProps,
    Route, SelectComponent, SelectItem,
} from "oca-lib-web";
import {CircularProgress} from "@material-ui/core";
import Tooltip from '@mui/material/Tooltip';
import {addCallback} from "reactn";
import Sidebar from "../component/Sidebar";
//import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NotificationUtil from "../util/NotificationUtil";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import AssetUtil from "../util/AssetUtil";
import { getGlobal } from 'reactn';
import UserUtil from "../util/UserUtil";
import StringUtil from "../util/StringUtil";

interface IState {
    componentFactory:ComponentFactory,
    rows:NotificationHistoryModel[],
    selectedAccount?:AccountModel,
    selectedCounty?:string,
    assets:AssetModel[],
    assetEvents:AssetEventModel[]
}

class NotificationPage extends React.Component<IBaseProps, IState> {

    constructor(props: any) {
        super();

        const componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(null, null, null);
        componentFactory.setHandleUpdateCallback((value:any, key:string) => {
            if (key === "completed") {
                this.forceUpdate();
            }
        })

        this.state = {
            componentFactory:componentFactory,
            rows: [],
            assets: [],
            assetEvents: []
        };
    }

    async componentWillMount() {
        addCallback(global => {
            this.initNotificationHistory();
            return null;
        });
        this.initNotificationHistory();
    }

    async selectedAccountChanged(value:any, key:string) {
        let selectedAccount;
        if (value !== "") {
            selectedAccount = await this.global.dataManager.get("getAccount", value);
        }
        this.setState({selectedAccount:selectedAccount, selectedCounty:null}, function(this:NotificationPage) {
            this.getNotificationHistory();
        }.bind(this));
    }

    async selectedCountyChanged(value:any, key:string) {
        //LocalCache.saveSession("selectedCountyNotification", value);
        let selectedCounty = value != "" ? value : null;
        this.setState({selectedCounty:selectedCounty}, function(this:NotificationPage) {
            this.getNotificationHistory();
        }.bind(this));
    }

    async initNotificationHistory() {
        if (!this.global.isDataLoaded) return;

        let assets = await AssetUtil.getAssetList();
        let assetEvents = await getGlobal().dataManager.get("getAssetEvents");
        this.setState({assets:assets, assetEvents:assetEvents}, function(this:NotificationPage) {
            this.getNotificationHistory();
        }.bind(this));

        /*let selectedCounty = LocalCache.getSession("selectedCountyNotification");
        if (selectedCounty) {
            this.setState({selectedCounty: selectedCounty}, function (this: NotificationPage) {
                this.getNotificationHistory();
            }.bind(this));
        } else {
            this.getNotificationHistory();
        }*/
    }

    async getNotificationHistory() {
        let notifications:NotificationHistoryModel[] = [];
        if (this.state.selectedAccount) {
            console.log(this.state.selectedAccount);
            //notifications = await this.global.data.getNotificationHistory(this.state.selectedAccount);
            notifications = await this.global.dataManager.get("getNotificationHistory", this.state.selectedAccount.id);
            console.log(notifications);
            notifications = await this.applyCounty(notifications, this.state.selectedAccount);
            console.log(notifications);
        } else if (this.global.user?.admin) {
            console.log("admin");
            notifications = await NotificationUtil.getNotificationHistoryForAdmin();
            //console.log(adminNotifications);
            //notifications = await this.global.data.getNotificationHistoryForAdmin();
            //notifications = await this.global.dataManager.get("getNotificationHistoryForAdmin");
        } else if (this.global.account) {
            console.log(this.global.account)
            //notifications = await this.global.data.getNotificationHistory(this.global.account.id);
            notifications = await this.global.dataManager.get("getNotificationHistory", this.global.account.id);
            notifications = await this.applyCounty(notifications, this.global.account);
        }

        //notifications = await NotificationUtil.updateOpenRates(notifications);

        console.log("number of notifications: " + notifications.length);
        //ArrayUtils.sort(notifications, "date", true);
        notifications.sort((a, b) => {
            let aSortString = a.pushNotificationDate && a.pushNotificationDate != "null" ? a.pushNotificationDate + a.pushNotificationTime : "19600101";
            let bSortString = b.pushNotificationDate && b.pushNotificationDate != "null" ? b.pushNotificationDate + b.pushNotificationTime : "19600101";
            if (aSortString < bSortString) return 1;
            else if (aSortString > bSortString) return -1;
            else return 0;
        });

        // update the open rates

        this.setState({rows:notifications});
    }

    async applyCounty(notifications:Array<NotificationHistoryModel>, account:AccountModel): Promise<Array<NotificationHistoryModel>> {
        return new Promise((async (resolve, reject) => {
            console.log(this.state.selectedCounty);
            if (!this.state.selectedCounty) {
                resolve(notifications);
                return;
            }
            let notificationsForCounty = [];
            //let notifications = await this.global.dataManager.get("getNotificationHistory", account.id);
            //let assets = await AssetUtil.getAssetList();
            //let assetEvents = await getGlobal().dataManager.get("getAssetEvents");
            for (let notification of notifications) {
                let assetModel = NotificationUtil.getAssetForNotification(notification, this.state.assets, this.state.assetEvents);
                if (assetModel?.assetCounty == this.state.selectedCounty) {
                    notificationsForCounty.push(notification);
                }
            }
            resolve(notificationsForCounty);
        }).bind(this));

    }

    async deleteNotificationHistoryTapped(item:any) {
        await this.global.dataManager.delete("deleteNotificationHistory", item);
        this.getNotificationHistory();
    }

    async dialogCallback(item: any) {
        console.log(item);
        this.props.base.notify("Push notification scheduled");
        this.getNotificationHistory();
    }

    filterRows(items: Array<NotificationHistoryModel>) {
        const binding = this.state.componentFactory.getDefaultBinding();
        const completedOnly = binding?.getProperty("completed");
        console.log("Filtering rows for completed", completedOnly);
        if (completedOnly) {
            items = ArrayUtil.getItems(items, "completed", true);
        }
        return items;
    }

    /*
     * Render methods
     */

    renderRow(item: NotificationHistoryModel) {
        const dateStartedText = DateUtil.format(item.date, 'MMMM D, YYYY h:mma');
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this scheduled notification? This action cannot be undone.", this.deleteNotificationHistoryTapped.bind(this), item);

        const cf = this.state.componentFactory;
        let actions = cf.renderBox([
            item.completed ? cf.renderIconButton(BaseIcon.ICON_KEYBOARD_ARROW_RIGHT, Route.goFunction(this, 'notificationDetail/' + item.id), "View Notification Results") : null,
            !item.completed ? cf.renderIconButton(BaseIcon.ICON_EDIT, Route.goFunction(this, 'sendNotification/' + item.id), "Edit") : null,
            !item.completed ? cf.renderIconButton(BaseIcon.ICON_DELETE, deleteFunction, "Delete") : null
        ]);

        //let issueNotification = item.notificationIssues && item.notificationIssues != "" ?
          //  cf.renderIconButton(BaseIcon.ICON_WARNING, Route.goFunction(this, 'sendNotification/' + item.id), item.notificationIssues, {color:'error'}) : null;

        const progressIndicator = <span><CircularProgress style={{width:'25px', height:'25px', marginRight:'10px'}}/> Running</span>
        const numberOfDevices = item.sentDetail ? item.sentDetail.length : item.numberOfDevices;
        const dateFormat = item.pushNotificationTime?.length == 4 ? "HHmm" : null;
        const assetModel = NotificationUtil.getAssetForNotification(item, this.state.assets, this.state.assetEvents);
        const pushOpened = item.numberOpened + (item.notificationCenterOpened || 0);
        const pushSentOpened = numberOfDevices + " / " + pushOpened;
        const row = [
            item.pushNotificationBody,
            !item.completed && item.pushNotificationDate != null ? DateUtil.format(item.pushNotificationDate, "MMMM D, YYYY") + " " + DateUtil.format(item.pushNotificationTime, "hh:mm a", dateFormat) + " (scheduled)"
                : dateStartedText,
            item.inProcess ? progressIndicator : item.completed ? <Tooltip title={<div><div>Push notifications sent: {numberOfDevices}</div><div>Push notifications opened: {item.numberOpened}</div><div>Notification center opens: {item.notificationCenterOpened}</div></div>}><span>{pushSentOpened}</span></Tooltip> : null,
            StringUtil.capitalizeFirstLetter(assetModel?.assetCounty),
            actions
        ];
        if (this.global.user?.admin) {
            let accountItem = ArrayUtil.getItem(this.global.accountOptions, "value", item.accountId)
            row.splice(5,0,accountItem?.label);
        }
        return row;
    }

    /*calculateOpenRate(item: NotificationHistoryModel) {
        return item.numberOfDevices == 0 ? "0%" : Math.round((item.numberOpened / item.numberOfDevices) * 100) + "%";
    }*/

    render() {
        const cf = this.state.componentFactory;
        const headerRowContent = [<ButtonComponent primary={true} title="New Notification"
                                                   onTouchTap={Route.goFunction(this, 'sendNotification')}/>];
        const headerRowLeftContent = [];

        let counties: Array<string> = [];
        if (this.state.selectedAccount?.counties) {
            counties = this.state.selectedAccount?.counties.split(",");
        } else if (!this.global.user?.admin && this.global.account) {
            counties = this.global.account.counties?.split(",");
        }
        if (counties && counties.length > 0) {
            ArrayUtil.sort(counties);
        }

        if (this.global.user?.admin) {
            headerRowLeftContent.push(<SelectComponent
                id="county"
                inputType="bootstrap"
                variant="outlined"
                value={this.state.selectedAccount ? this.state.selectedAccount.id : ""}
                options={this.global.accountOptions}
                onChange={this.selectedAccountChanged.bind(this)}/>)

        }
        if (counties && counties.length > 1) {
            let countyOptions: Array<SelectItem> = [];
            for (let county of counties) {
                countyOptions.push(SelectItem.create(county, county));
            }
            countyOptions.splice(0, 0, SelectItem.create("", "All Counties"));
            headerRowLeftContent.push(<SelectComponent
                id="counties"
                inputType="bootstrap"
                style={{marginLeft: '20px'}}
                variant="outlined"
                value={this.state.selectedCounty || ""}
                options={countyOptions}
                onChange={this.selectedCountyChanged.bind(this)}/>);
        }

        headerRowLeftContent.push([<span style={{marginLeft:'20px'}}>&nbsp;</span>, cf.renderSwitchField("completed", "Completed")]);

        let headers = ["Message", "Date Sent", "Sent / Opened", "County", ""];
        if (this.global.user?.admin) {
            headers.splice(5, 0, "Account");
        }

        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE},
                cf.renderBoxProps({marginLeft: '90px'},
                    !this.global.isDataLoaded ?
                        VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                            :
                            [VisualUtil.getHeaderDisplay("Notifications", headerRowContent),
                                cf.renderLine(),
                                headerRowLeftContent.length > 0 ? VisualUtil.renderActionBar(headerRowLeftContent) : null,
                                cf.renderTable(headers, this.filterRows(this.state.rows), this.renderRow.bind(this), {fitToContent: true})
                            ]
                )
            )
        )
    }
}

export default base(NotificationPage, Sidebar)