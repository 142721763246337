export default class Styles {

    public static MARGIN_SEPARATOR = {marginLeft:'20px'};
    public static MARGIN_BOX = {marginTop:'20px', marginLeft:'20px', marginRight:'20px', marginBottom:'20px'};
    public static MARGIN_ZERO = {margin:0, padding:0};
    public static MARGIN_SIDEBAR = {marginLeft:'90px'};

    public static merge(styles:Array<object>):object {
        let mergedStyle = {};
        for (let style of styles) {
            mergedStyle = {...mergedStyle, ...style};
        }
        return mergedStyle;
    }

    public static default = {
        button: {
            primary: true,
            elevation: false
        }
    }
}