import * as React from 'reactn';
import {
    ArrayUtil,
    base, BaseColor, BaseIcon,
    ComponentFactory, DateUtil, DialogUtil,
    IBaseProps,
    Route, SelectComponent,
} from "oca-lib-web";
import {addCallback} from "reactn";
import Sidebar from "../component/Sidebar";
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import {createRef} from "react";
import MessageStatisticsDialog from "../component/MessageStatisticsDialog";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";

interface IState {
    dialogs:Array<any>,
    componentFactory:ComponentFactory,
    rows:MessageHistory[],
    statsRows:MessageStatistics[],
    accounts:AccountModel[],
    selectedAccount?:string,
    //selectedCounty?:string
}

class MessagePage extends React.Component<IBaseProps, IState> {

    public messageStatisticsDialog = createRef<any>();

    constructor(props: any) {
        super();

        const componentFactory = new ComponentFactory(Styles.default);
        let dialogs = [
            <MessageStatisticsDialog {...componentFactory.renderDialogProps("Message Statistics", null, this.messageStatisticsCallback.bind(this), this.messageStatisticsDialog)} />
        ]

        this.state = {
            dialogs:dialogs,
            componentFactory:componentFactory,
            accounts: [],
            rows: [],
            statsRows: []
        };
    }

    async componentWillMount() {
        addCallback(global => {
            this.initMessageHistory();
            return null;
        });
        this.initMessageHistory();
    }

    async selectedAccountChanged(value:any, key:string) {
        let selectedAccount = value != "" ? value : null;
        this.setState({selectedAccount:selectedAccount}, function(this:MessagePage) {
            this.getMessageHistory();
        }.bind(this));
    }

    /*async selectedCountyChanged(value:any, key:string) {
        LocalCache.saveSession("selectedCountyNotification", value);
        let selectedCounty = value != "" ? value : null;
        this.setState({selectedCounty:selectedCounty}, function(this:MessagePage) {
            this.getMessageHistory();
        }.bind(this));
    }*/

    initMessageHistory() {
        if (!this.global.isDataLoaded) return;

        this.getMessageHistory();
        this.getAccounts();
        /*et selectedCounty = LocalCache.getSession("selectedCountyNotification");
        if (selectedCounty) {
            this.setState({selectedCounty: selectedCounty}, function (this:MessagePage) {
                this.getMessageHistory();
                //this.getMessageStatistics();
            }.bind(this));
        } else {
            this.getMessageHistory();
            //this.getMessageStatistics();
        }*/
    }

    async getAccounts() {
        let accounts = await this.global.dataManager.get("getAccounts");
        this.setState({accounts:accounts});
    }

    async getMessageHistory() {
        let messages:MessageHistory[] = [];
        if (this.state.selectedAccount) {
            messages = await this.global.dataManager.get("getMessageHistory", this.state.selectedAccount);
        } else if (this.global.user?.admin) {
            messages = await this.global.dataManager.get("getMessageHistoryForAdmin");
        } else if (this.global.account) {
            messages = await this.global.dataManager.get("getMessageHistory", this.global.account.id)
        }

        console.log(messages);
        ArrayUtil.sort(messages, "date", true);

        this.setState({rows:messages});
    }

    /*async getMessageStatistics() {
        let messageStatistics:MessageStatistics[] = [];
        let input = {
            field: InvokeFields.getMessageStatistics
        }
        if (this.state.selectedAccount) {
            //messageStatistics = await this.global.dataManager.get("getMessageStatistics", this.state.selectedAccount);
            input["accountId"] = this.state.selectedAccount;
        } else if (this.global.account) {
            //messageStatistics = await this.global.dataManager.get("getMessageStatistics", this.global.account.id)
            input["accountId"] = this.global.account.id;
        }
        messageStatistics = await this.global.dataManager.perform("getMessageStatistics", input)

        console.log("number of message statistics: " + messageStatistics.length);
        ArrayUtils.sort(messageStatistics, "date", true);

        this.setState({statsRows:messageStatistics});
    }*/

    async dialogCallback(item: any) {
        console.log(item);
        this.props.base.notify("Push notification scheduled");
        this.getMessageHistory();
    }

    async deleteMessageHistoryTapped(item:any) {
        await this.global.dataManager.delete("deleteMessageHistory", item);
        this.getMessageHistory();
    }

    // Callbacks

    messageStatisticsCallback() {}

    /*
     * Render methods
     */

    renderRow(item: MessageHistory) {
        let cf = this.state.componentFactory;
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this scheduled message? This action cannot be undone.", this.deleteMessageHistoryTapped.bind(this), item);
        let assets = item.assetIds?.split(",");
        let actions = cf.renderBox([
            item.date ? cf.renderIconButton(BaseIcon.ICON_KEYBOARD_ARROW_RIGHT, Route.goFunction(this, 'messageDetail/' + item.id), "View Message Results") : null,
            !item.date ? cf.renderIconButton(BaseIcon.ICON_EDIT, Route.goFunction(this, 'sendMessage/asset/' + item.id), "Edit") : null,
            !item.date ? cf.renderIconButton(BaseIcon.ICON_DELETE, deleteFunction, "Delete") : null
        ]);
        let currentDate = DateUtil.format(item.date, 'MMMM D, YYYY h:mma')
        //let statsButton = <span>{currentDate}<IconButtonComponent icon={BaseIcon.getIcon("settings", "primary")} tooltip="Edit" color="primary" onTouchTap={() => {console.log("tap")}} /></span>
        let statsButton;
        let account = ArrayUtil.getItem(this.state.accounts, "id", item.accountId);
        if (account?.pinpointProjectId) {
            statsButton = <span><span>{currentDate}</span><span>{cf.renderIconButton(BaseIcon.ICON_ANALYTICS, DialogUtil.openFunction(this.messageStatisticsDialog.current, item), "Statistics")}</span></span>
        } else {
            statsButton = currentDate;
        }

        let row = [
            item.messageSubject,
            item.date == null ? DateUtil.format(item.messageScheduledDate, "MMMM D, YYYY") + " " + DateUtil.format(item.messageScheduledTime, "hh:mm a") + " (scheduled)"
                : statsButton,
            assets?.length || 0,
            actions
        ];
        if (this.global.user?.admin) {
            let accountItem = ArrayUtil.getItem(this.global.accountOptions, "value", item.accountId)
            row.splice(3,0,accountItem?.label);
        }
        return row;
    }

    /*renderStatsRow(item:MessageStatistics) {
        return [
            item.date,
            item.delivered,
            item.bounced,
            item.openes,
            item.clicks
        ]
    }

    renderStatistics() {
        const cf = this.state.componentFactory;
        let headers = ["date", "delivered", "bounced", "opens", "clicks"]
        var headerRowLeftContent;
        if (this.global.user?.admin) {
            headerRowLeftContent = <SelectComponent
                id="county"
                style={{width:'200px', backgroundColor:'#D7D7D7'}}
                disableUnderline={true}
                value={this.state.selectedAccount}
                options={this.global.accountList}
                onChange={this.selectedAccountChanged.bind(this)} />
        }
        return (
            cf.renderPaper(cf.renderTableWithActions(headers, this.state.statsRows, this.renderStatsRow.bind(this), headerRowLeftContent))
        )
    }*/

    renderMessages() {
        const cf = this.state.componentFactory;
        let headers = ["Message Subject", "Date Sent", "Number of Assets", ""];
        if (this.global.user?.admin) {
            headers.splice(3,0,"Account");
        }
        var headerRowLeftContent;
        if (this.global.user?.admin) {
            headerRowLeftContent = <SelectComponent
                id="county"
                inputType="bootstrap"
                variant="outlined"
                value={this.state.selectedAccount}
                options={this.global.accountOptions}
                onChange={this.selectedAccountChanged.bind(this)} />
        }

        return ([
            cf.renderLine(),
            VisualUtil.renderActionBar(headerRowLeftContent),
            cf.renderTable(headers, this.state.rows, this.renderRow.bind(this), {fitToContent:true})
        ])
    }

    render() {
        const cf = this.state.componentFactory;
        //let headerRowContent = [<ButtonComponent primary={true} title="New Message" startIcon={<AddCircleOutlineIcon/>} onTouchTap={Route.goFunction(this, 'sendMessage/asset')} />];
        let headerRowContent = cf.renderButton("New Message", Route.goFunction(this, 'sendMessage/asset'), {
            startIcon: null
        });

        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                this.state.dialogs,
                cf.renderBoxProps({marginLeft: '90px'},
                    !this.global.isDataLoaded ?
                        VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                            :
                            cf.renderBox([
                                VisualUtil.getHeaderDisplay("Messaging", headerRowContent),
                                this.renderMessages()
                            ])
                )
            ])
        )
    }
}

export default base(MessagePage, Sidebar)