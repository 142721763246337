import * as React from 'reactn';
import {
    ArrayUtil,
    ComponentFactory, ComponentUtil,
    dialog,
    IBaseDialogProps,
    SelectItem
} from "oca-lib-web";
import ReactGA from "react-ga";
import InvokeFields from "../util/InvokeFields";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    assetSelections:Array<SelectItem>
}

class AddAssetRelationshipDialog extends React.Component<IBaseDialogProps, IState> {

    //public relationshipDescription = createRef<TextComponent>();
    //public relationshipAssets = createRef<SelectComponent>();
    public components = ComponentUtil.createRefs(2);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(null, null, props.param);

        this.state = {
            componentFactory: componentFactory,
            assetSelections: []
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/item/assetRelationshipDialog');

        //let assets = await this.global.data.getAssets(this.global.account.id);
        let assets = [];
        if (this.global.user.admin) {
            assets = await this.global.dataManager.get("getAssetsForAdmin", {field:InvokeFields.getAssetsForAdmin});
        } else if (this.global.account) {
            assets = await this.global.dataManager.get("getAssets", this.global.account.id);
        }
        ArrayUtil.sort(assets, "assetName");

        let assetSelections:Array<SelectItem> = [];
        for (let asset of assets) {
            assetSelections.push(SelectItem.create(asset.id, asset.assetName));
        }
        this.setState({assetSelections:assetSelections});
    }

    // Methods

    async handleSave(callback:any) {
        //let fields = [this.relationshipDescription, this.relationshipAssets];
        //if (!this.state.componentFactory.validate(fields)) { return; }

        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();
        //await componentBinding.saveBinding();

        callback(componentBinding.item);
        //callback(this.state.assetRelationship);
    }

    render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                cf.renderBoxPadded(cf.renderTextField("relationshipDescription", "Label", this.components[0])),
                cf.renderBoxPadded(cf.renderSelectField("relationshipAssets", "Assets", this.state.assetSelections, this.components[1], null, {multiple:true}))
            ])
        )
    }
}

export default dialog(AddAssetRelationshipDialog)