export default class StringUtil {

    public static capitalizeFirstLetter(value?:string) {
        if (value) {
            let upperValueList = [];
            let valueList = value.split(" ");
            for (let valueItem of valueList) {
                upperValueList.push(valueItem.charAt(0).toUpperCase() + valueItem.slice(1));
            }
            return upperValueList.join(" ");
        }
        return value;
    }
}