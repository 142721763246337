import MapUtil from "./MapUtil";
import Constants from "./Constants";

export default class FeatureUtil {

    /*public static getTitle(info: any, object: any, propPrefix: string) {
        let name = "";
        let title = "";
        let subtitle = "";
        if (object) {
            name = MapUtil.getProp(object, `${propPrefix}.NAME`) || "";
        }
        if (object && info.title) {
            title = MapUtil.getProp(object, `${propPrefix}.${info.title}`) || "";
        }
        if (object && info.subtitle) {
            subtitle = MapUtil.getProp(object, `${propPrefix}.${info.subtitle}`) || "";
        }
        return {name, title, subtitle};
    }*/

    public static getTitle(feature:any) {
        let title = feature.properties[Constants.ATTRIBUTE_TITLE];
        return title ? title : "";
    }

    public static getSubtitle(feature:any) {
        let subtitle = feature.properties[Constants.ATTRIBUTE_SUBTITLE];
        return subtitle ? subtitle : "";
    }

    public static getProperty(feature:any, property:string): string {
        return feature.properties[property];
    }

    public static getFeatureIcon(info: any, object: any): string {
        //const {title} = this.getTitle(info, object, "properties");
        /*let title = this.getTitle(object);

        switch (title.toLowerCase()) {
            case "boat launch":
                return "marker_boatlunch";
            case "cultural features":
                return "marker_culturalfeatures";
            case "gazebo":
            case "gazebo/overlook":
                return "marker_gazebo";
            case "grill":
                return "marker_grill";
            case "kiosk":
                return "marker_kiosk";
            case "picnic table":
                return "marker_picnic";
            case "overlook":
                return "marker_overlook";
            case "fishing pier/platform":
                return "marker_pierfishing";
            case "restrooms":
                return "marker_restrooms";
            case "gate/trailhead":
                return "marker_trailhead";
            case "water fountain":
                return "marker_waterfountain";
            case "obstruction":
                return "marker_obstruction";
            case "moment":
                return "marker_image";
        }*/

        return "marker_obstruction";
    }

    public static getFeatureColor(info: any, object: any, layer: string): number[] {
        const greenPolygon = [ ...MapUtil.hexToRgb("#2ECC71"), 0.5 * 255];
        /*var randomColor = require('randomcolor'); // import the script
        var color = randomColor();
        const greenPolygon = [ ...MapUtil.hexToRgb(color), 0.5 * 255];*/
        return greenPolygon;
    }

    public static getLineColor() {
        const greenPolygon = [ ...MapUtil.hexToRgb("#2ECC71"), 0.9 * 255];
        return greenPolygon;
    }

    public static getFeature3DHeight(info: any, object: any): number {
        //const {title} = this.getTitle(info, object, "sourceFeature.feature.properties");
        const title = this.getTitle(object);

        if (info.gisType === "water" || title.toLowerCase() === "water") return 0.2;

        if (info.gisType === "park") return 0.1;

        switch (title.toLowerCase()) {
            case "field":
                return 0.2;
            case "garden/orchard":
                return 2.0;
            case "parking lot":
                return 0.3;
            case "restroom":
                return 5.0;
            case "shelter":
                return 5.0;
            case "amphitheater":
                return 5.0;
            case "picnic area":
                return 5.0;
            case "cultural feature":
                return 10.0;
            case "administrative building":
                return 10.0;
        }

        return 0.0;
    }
}