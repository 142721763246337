import {File, StringUtil} from "oca-lib-web";
import AWSStorage from "../auth/aws/AWSStorage";

export default class FileUtil {

    public static async uploadImage(e: any, fileName?: string): Promise<string> {
        return new Promise(async (resolve, reject) => {
            if (!fileName) {
                fileName = StringUtil.uuid();
            }
            if (!e.nativeEvent.target || !e.nativeEvent.target.files || e.nativeEvent.target.files.length == 0) {
                reject("Something went wrong. Please try uploading again later.");
                return;
            }

            const key = "_files";
            const file = e.nativeEvent.target.files[0];
            try {
                let result = await AWSStorage.put(key + "/" + fileName, file);
                console.log(result);
                resolve(fileName);
            } catch (e) {
                reject(e as string);
            }
        });
    }

    public static async downloadFile(fileName?: string):Promise<string> {
        return new Promise(async (resolve, reject) => {
            const key = "_files";
            let data = await AWSStorage.get(key + "/" + fileName, true);
            if (data) {
                console.log(data.Body);
                let fileNamePieces = fileName.split("/");
                File.download(data.Body, fileNamePieces[fileNamePieces.length-1]);
                resolve("");
            } else {
                reject("Unable to find file");
            }
        });
    }

    public static getFolderStructure():string {
        let folder = "";

        return folder;
    }
}