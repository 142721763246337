import AWSStorage from "../auth/aws/AWSStorage";
import {ArrayUtil, Http, P} from "oca-lib-web";
import Constants from "./Constants";

export default class NotificationUtil {

    private static NOTIFICATION_HISTORY_URL = `${process.env.REACT_APP_API_URL}/getNotificationHistoryForAdmin`;

    public static async getNotificationHistoryForAdmin():Promise<Array<NotificationHistoryModel>> {
        const results = await Http.get(NotificationUtil.NOTIFICATION_HISTORY_URL);
        console.log("total notification results", results.length);
        return P.resolve(results);
    }

    public static async getSentNotifications(notificationHistoryId:string):Promise<Array<any>> {
        let sentContents = await AWSStorage.getContents("_notifications/" + notificationHistoryId + "/sent.json");
        let sentDetailObject = JSON.parse(sentContents);
        return sentDetailObject;
    }

    public static async getOpenedNotifications(notificationHistoryId:string):Promise<Array<any>> {
        let openContents = [];
        let notificationDir = await AWSStorage.list("_notifications/" + notificationHistoryId);
        if (notificationDir) {
            for (let notificationItem of notificationDir) {
                if (notificationItem.key.indexOf("sent") == -1) {
                    openContents.push(notificationItem);
                }
            }
        }
        return openContents;
    }

    public static getAssetForNotification(notificationHistory:NotificationHistoryModel, assets:Array<AssetModel>, assetEvents:Array<AssetEventModel>):AssetModel {
        let assetModel = null;
        if (notificationHistory.appTargetType == "asset") {
            //assetModel = await getGlobal().dataManager.get("getAsset", notificationHistory.appTargetSelection);
            assetModel = ArrayUtil.getItem(assets, "id", notificationHistory.appTargetSelection);
        } else if (notificationHistory.appTargetType == "assetEvent") {
            let assetEventModel = ArrayUtil.getItem(assetEvents, "id", notificationHistory.appTargetSelection);
            assetModel = ArrayUtil.getItem(assets, "id", assetEventModel?.assetId);
            //let assetEventModel = await getGlobal().dataManager.get("getAssetEvent", notificationHistory.appTargetSelection);
            //assetModel = await getGlobal().dataManager.get("getAsset", assetEventModel.assetId);
        }
        //return P.resolve(assetModel);
        return assetModel;
    }

    public static doesAssetEventHaveNotification(assetEvent:AssetEventModel, notifications:Array<NotificationHistoryModel>):boolean {
        const doesHaveNotification = notifications.some(notification => {
            return notification.appTargetType === Constants.NOTIFICATION_TYPE_ASSET_EVENT && notification.appTargetSelection == assetEvent.id;
        })
        return doesHaveNotification;
    }

    public static doesItineraryHaveNotification(itinerary:Itinerary, notifications:Array<NotificationHistoryModel>):boolean {
        const doesHaveNotification = notifications.some(notification => {
            return notification.appTargetType === Constants.NOTIFICATION_TYPE_ITINERARY && notification.appTargetSelection == itinerary.id;
        })
        return doesHaveNotification;
    }
}