import * as React from 'reactn';
import {
    ArrayUtil,
    base,
    BaseColor,
    BaseIcon,
    ComponentFactory,
    DateUtil,
    DialogUtil,
    SelectComponent
} from "oca-lib-web";
import Sidebar from "../component/Sidebar";
import AppIssueDialog from "../component/AppIssueDialog";
import CloseAppIssueDialog from "../component/CloseAppIssueDialog";
import {createRef} from "react";
import {addCallback} from "reactn";
import UserUtil from "../util/UserUtil";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    dialogs:Array<any>
    rows:Array<AppIssueModel>,
    users:Array<UserModel>,
    selectedStatus?:string
}

class AppIssuePage extends React.PureComponent<{}, IState> {
    public appIssueDialog = createRef<any>();
    public closeAppIssueDialog = createRef<any>();

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        let dialogs = [
            <AppIssueDialog {...componentFactory.renderDialogProps("Add Issue", "Save", this.updateData.bind(this), this.appIssueDialog)} />,
            <CloseAppIssueDialog {...componentFactory.renderDialogProps("Close Issue", "Save", this.updateData.bind(this), this.closeAppIssueDialog)} />
        ]

        this.state = {
            componentFactory: componentFactory,
            dialogs:dialogs,
            rows: [],
            users: [],
            selectedStatus: 'open'
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.updateData();
            return null;
        });
        if (this.global.isDataLoaded) {
            this.updateData();
        }
    }

    updateData() {
        this.getAppIssues();
    }

    async getAppIssues() {
        let users = await this.global.dataManager.get("getUsers");
        let appIssues = await this.global.dataManager.get("getAppIssues");
        if (this.state.selectedStatus) {
            appIssues = ArrayUtil.getItems(appIssues, "status", this.state.selectedStatus);
        }
        ArrayUtil.sort(appIssues, "date");

        this.setState({rows: appIssues, users:users});
    }

    async selectedStatusChanged(value:any, key:string) {
        let selectedStatus = value != "" ? value : null;
        this.setState({selectedStatus:selectedStatus}, function(this:AppIssuePage) {
            this.updateData();
        }.bind(this));
    }

    renderRow(item:AppIssueModel) {
        let user = ArrayUtil.getItem(this.state.users, "id", item.userId);
        let cf = this.state.componentFactory;

        let actions = cf.renderBox([
            cf.renderIconButton(BaseIcon.ICON_EDIT, DialogUtil.openFunction(this.closeAppIssueDialog.current, item))
        ]);

        return [
            item.issueText,
            DateUtil.format(item.date, "MMMM DD, YYYY"),
            UserUtil.getAppIssueTypeLabel(item.issueType),
            user.name,
            item.status,
            actions
        ];
    }

    public render() {
        const cf = this.state.componentFactory;
        let headerRowLeftContent = <SelectComponent
            id="county"
            inputType="bootstrap"
            variant="outlined"
            value={this.state.selectedStatus}
            options={cf.createOptions([{id: 'open', label: 'open'}, {id: 'close', label: 'close'}], "id", "label")}
            onChange={this.selectedStatusChanged.bind(this)}/>

        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                this.state.dialogs,
                cf.renderBoxProps({marginLeft: '90px'}, [
                    !this.global.isDataLoaded ?
                        VisualUtil.showLoading() :
                        VisualUtil.getHeaderDisplay("App Issues", null),
                    cf.renderLine(),
                    VisualUtil.renderActionBar(headerRowLeftContent),
                    cf.renderTable(["Issue Text", "Date", "Type", "Reporter", "Status", "Action"], this.state.rows, this.renderRow.bind(this), {fitToContent: true})
                ])
            ])
        )
    }
}

export default base(AppIssuePage, Sidebar)