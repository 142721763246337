import * as React from 'reactn';
import {
    ComponentFactory,
    dialog,
    IBaseDialogProps,
    SelectItem
} from "oca-lib-web";
import Constants from "../util/Constants";
import ReactGA from 'react-ga';
import Styles from "../style/Styles";

interface IState {
    componentFactory: ComponentFactory,
    userRows: Array<SelectItem>
    countyRows: Array<SelectItem>
}

class AddAccountDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createAccount", props.param);
        componentFactory.setHandleUpdateCallback((value, key) => {
            if (key == "paid") {
                this.forceUpdate();
            }
        })
        this.state = {
            componentFactory,
            userRows: [],
            countyRows: []
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/accountDialog');

        var userRows:Array<SelectItem> = [];
        var countyRows:Array<SelectItem> = [];
        let users = await this.global.dataManager.get("getUsers");
        userRows = this.state.componentFactory.createOptions(users, "id", "name");
        for (let county of Constants.COUNTIES) {
            countyRows.push(SelectItem.create(county, county));
        }

        this.setState({userRows: userRows, countyRows: countyRows});
    }

    // Methods

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding()
        if (!componentBinding.item.live) {
            componentBinding.setProperty("live", false);
        }
        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    render() {
        let cf = this.state.componentFactory;
        let paid = cf.getDefaultBinding()?.getProperty("paid");
        let paidAdminAccount = this.global.user?.email == "eric@oakcityapps.com" || this.global.user?.email == "ericmajewicz@gmail.com";
        return (
            cf.renderBox([
                cf.renderBoxPadded(cf.renderTextField("accountName", "Account Name")),
                cf.renderBoxPadded(cf.renderTextField("assetLicense", "Asset License Count", null, null, {type:"number"})),
                cf.renderBoxPadded(cf.renderSelectField("counties", "Counties", this.state.countyRows, null, null, {multiple:true})),
                cf.renderBoxPadded(cf.renderSelectField("users", "Users", this.state.userRows, null, null, {multiple:true})),
                cf.renderBoxPadded(cf.renderTextField("pinpointProjectId", "Pinpoint Project ID")),
                cf.renderBoxPadded(cf.renderTextField("pinpointOriginationNumber", "Pinpoint Origination Number")),
                paidAdminAccount ? cf.renderBoxPadded(cf.renderSwitchField("paid", "Paid")) : null,
                cf.renderBoxPadded(cf.renderSwitchField("live", "Active", null, null, {disabled:!paid})),
                cf.renderBoxPadded(cf.renderSwitchField("enhancedRegion", "Enhanced Region")),
            ])
        )
    }
}

export default dialog(AddAccountDialog)