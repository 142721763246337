import * as React from 'reactn';
import {ComponentFactory, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from 'react-ga';
import UserUtil from "../util/UserUtil";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
}

class NotificationIssueDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createNotificationHistory", props.param);

        this.state = {
            componentFactory:componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/item/notificationIssueDialog');
    }

    // Public methods

    async handleSave(callback:any) {
        let binding = this.state.componentFactory.getDefaultBinding();
        if (!binding.validate()) { return; }

        binding.saveBinding();

        this.sendNotificationIssueEmail();

        callback(binding.item);
    }

    async sendNotificationIssueEmail() {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let userId = componentBinding.getProperty("userId");
        let user = await this.global.dataManager.get("getUser", userId);
        let pushNotificationBody = componentBinding.getProperty("pushNotificationBody");
        let notificationIssues = componentBinding.getProperty("notificationIssues");

        let messageBody = "";
        messageBody += "We've determined that your push notification does not conform to our best practices. Please correct the following issue in order for your push notification to be sent out.";

        messageBody += "<br/><br/>"
        messageBody += "<b>Push notification body: </b>" + pushNotificationBody + "<br/><br/>";
        messageBody += "<span style='color:#F10022'><b>Issue: </b>" + notificationIssues + "</span>";
        UserUtil.sendEmail([user.email], "Issue with your push notification", messageBody);
    }

    public render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                cf.renderBoxPadded(cf.renderTextField("notificationIssues", "Notification Issue", null, null, {type:"textarea", multiline:true, required:true}))
            ])
        )
    }
}

export default dialog(NotificationIssueDialog)