import * as React from 'reactn';
import {
    ArrayUtil,
    base, BaseColor, BaseIcon, ComponentFactory,
    IBaseProps, Route,
} from "oca-lib-web";
import {addCallback} from "reactn";
import Sidebar from "../component/Sidebar";
import AssetUtil from "../util/AssetUtil";
import Styles from "../style/Styles";
import VisualUtil from 'src/util/VisualUtil';
import UserUtil from "../util/UserUtil";

interface IState {
    componentFactory:ComponentFactory,
    rows:Array<AssetModel>
}

class MessageDetailPage extends React.Component<IBaseProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(null, null, null);

        this.state = {
            componentFactory:componentFactory,
            rows:[]
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.getMessage();
            return null;
        });
        if (this.global.isDataLoaded) {
            this.getMessage();
        }
    }

    async getMessage() {
        let id = Route.param(this, "id");
        let messageHistoryList = await this.global.dataManager.get("getMessageHistoryForAdmin");
        let item:MessageHistory = ArrayUtil.getItem(messageHistoryList, "id", id);
        console.log(item);
        this.state.componentFactory.getDefaultBinding().setItem(item);
        this.forceUpdate();

        let assets = [];
        let fullAssetList = await AssetUtil.getAssetList();
        let assetIds = item.assetIds.split(",");
        for (let assetId of assetIds) {
            assets.push(await ArrayUtil.getItem(fullAssetList, "id", assetId));
        }
        this.setState({rows:assets});
    }

    // Render methods

    public renderRow(item:AssetModel) {
        let cf = this.state.componentFactory;
        let contactActionText = "";
        if (item.assetContactPreference == "email") {
            contactActionText = "Send email to " + item.assetEmailAddress;
        } else if (item.assetContactPreference == "text") {
            contactActionText = "Send text message to " + item.assetContactPhoneNumber;
        } else if (item.assetContactPreference == "phone") {
            contactActionText = "Request phone call at " + item.assetContactPhoneNumber;
        }
        return [
            item.assetName,
            contactActionText,
            cf.renderIconButton(BaseIcon.ICON_KEYBOARD_ARROW_RIGHT, Route.goFunction(this, 'item/' + item.id), "View Asset")
        ]
    }

    public render() {
        let cf = this.state.componentFactory;
        let messageBody = this.state.componentFactory.getDefaultBinding().getProperty("messageBody");

        return (
            !this.global.isDataLoaded ?
                VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                    :
                    cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE},
                        cf.renderBoxProps(Styles.MARGIN_SIDEBAR, ([
                            VisualUtil.getHeaderDisplay("Message Details"),
                            cf.renderLine(),
                            cf.renderBoxPadded(cf.renderTextField("messageSubject", "Message Subject", null, null, {disabled: true})),
                            cf.renderBoxPadded(<div dangerouslySetInnerHTML={{__html: messageBody}}/>),
                            cf.renderLine(),
                            VisualUtil.getHeaderDisplay("Recipients"),
                            cf.renderLine(),
                            cf.renderTable(["Asset Name", "Contact Action", ""], this.state.rows, this.renderRow.bind(this), {fitToContent: true})
                        ]))
                    )
        )
    }
}

export default base(MessageDetailPage, Sidebar)