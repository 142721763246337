import * as React from 'reactn';
import Imgix from "react-imgix";
import defaultPhoto from '../image/defaultPhoto.png'
import { P } from 'oca-lib-web';
import AWSStorage from "../auth/aws/AWSStorage";
import {StringUtil} from "oca-lib-web";;

export default class ImageUtil {

    public static async uploadImage(e:any, fileName?:string):Promise<{success:boolean, message:string}> {
        if (!fileName) {
            fileName = StringUtil.uuid();
        }
        if (!e.nativeEvent.target || !e.nativeEvent.target.files) {
            return P.resolve({success:false, message:"Something went wrong. Please try uploading again later."});
        }

        console.log("bytes: " + e.nativeEvent.target.files[0].size);
        if(e.nativeEvent.target.files[0].size > 1048576) {
            return P.resolve({success:false, message:"Image is too big to upload (max 1MB)"});
        }
        // TODO: Validate image size and dimensions and use imgix if needed

        const file = e.nativeEvent.target.files[0];
        await AWSStorage.put(fileName, file);
        return P.resolve({success:true, message:fileName});
    }

    public static getPhoto(photo:string, size?:number, circle?:boolean) {
        let dimension = size || 40;
        let params = circle ? { mask: "ellipse" } : {};
        return (photo ? <Imgix height={dimension} width={dimension} src={this.fetch(photo)} imgixParams={params} /> : <img src={defaultPhoto} width={dimension} />)
    }

    public static fetch(imageName: string) {
        return process.env.REACT_APP_IMGIX_URL + "/" + imageName;
    }

    // pass e.nativeEvent
    public static getImageDimensions(e:any) {
        var reader = new FileReader();

        //Read the contents of Image File.
        reader.readAsDataURL(e.files[0]);
        reader.onload = function (e) {

            //Initiate the JavaScript Image object.
            var image = new Image();

            //Set the Base64 string return from FileReader as source.
            //image.src = e.target.result;

            //Validate the File Height and Width.
            image.onload = function () {
                /*var height = this.height;
                var width = this.width;
                if (height > 100 || width > 100) {
                    alert("Height and Width must not exceed 100px.");
                    return false;
                }*/
                alert("Uploaded image has valid Height and Width.");
                return true;
            };
        };
    }

    public static imageSize(url) {
        const img = document.createElement("img");

        const promise = new Promise((resolve, reject) => {
            img.onload = () => {
                // Natural size is the actual image size regardless of rendering.
                // The 'normal' `width`/`height` are for the **rendered** size.
                const width  = img.naturalWidth;
                const height = img.naturalHeight;

                // Resolve promise with the width and height
                resolve({width, height});
            };

            // Reject promise on error
            img.onerror = reject;
        });

        // Setting the source makes it start downloading and eventually call `onload`
        img.src = url;

        return promise;
    }
}