export default class ProductQuery {

    public static GetProducts = `query GetProductQuery {
        getProducts {
            id
            productName
            productGroupId
            seasonStart
            seasonEnd
            seasonStartMonth
            seasonStartDay
            seasonEndMonth
            seasonEndDay
            suggestedFilter
            filterPriority
        }
    }`;

    public static CreateProduct = `mutation CreateProductMutation($input: ProductInput!) {
        createProduct(input: $input) {
            id
            productName
            productGroupId
            seasonStart
            seasonEnd
            seasonStartMonth
            seasonStartDay
            seasonEndMonth
            seasonEndDay
            suggestedFilter
            filterPriority
        }
    }`;

    public static DeleteProduct = `mutation DeleteProductMutation($id: ID!) {
        deleteProduct(id: $id) {
            id
        }
    }`;
}