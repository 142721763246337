import * as React from 'reactn';
import {
    ArrayUtil,
    base,
    BaseColor,
    ButtonComponent,
    ComponentFactory,
    DateUtil,
    DialogUtil,
    IBaseProps
} from "oca-lib-web";
import Sidebar from "../component/Sidebar";
import {addCallback, getGlobal} from "reactn";
import 'react-bulma-components/dist/react-bulma-components.min.css';
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import AddFolderDialog from "../component/AddFolderDialog";
import {createRef} from "react";
import Constants from "../util/Constants";
import Folder from '@mui/icons-material/FolderOpen';
import File from '@mui/icons-material/InsertDriveFile';
import FileUtil from "../util/FileUtil";
import UserUtil from "../util/UserUtil";

interface IState {
    componentFactory:ComponentFactory,
    userMessageRows: Array<any>,
    fileRows: Array<FileModel>,
    users: Array<UserModel>,
    selected: Array<any>,
    currentFolder?: FileModel,
    dialogs:Array<any>
}

class InformationPage extends React.Component<IBaseProps, IState> {
    public addFolderDialog = createRef<any>();

    constructor() {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        let dialogs = [
            <AddFolderDialog {...componentFactory.renderDialogProps("Add Folder", "Save", this.updateData.bind(this), this.addFolderDialog)} />,
        ]

        this.state = {
            componentFactory:componentFactory,
            userMessageRows: [],
            fileRows: [],
            users: [],
            selected: [],
            dialogs:dialogs
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.updateData();
            return null;
        });
        this.updateData();
    }

    // Methods

    async updateData() {
        let allFiles = await this.global.dataManager.get("getFiles");
        let users = await this.global.dataManager.get("getUsers");

        //ArrayUtil.sort(allFiles, "fileName");
        allFiles.sort((n1,n2) => {
            if (n1.fileType == Constants.FILE_TYPE_FOLDER && n2.fileType == Constants.FILE_TYPE_FILE) {
                return -1;
            } else if (n1.fileType == Constants.FILE_TYPE_FILE && n2.fileType == Constants.FILE_TYPE_FOLDER) {
                return 1;
            }
            return n1.fileName.localeCompare(n2.fileName);
        });
        this.setState({fileRows:allFiles, users:users});
    }

    getFilesForFolder():Array<FileModel> {
        let files = [];
        for (let file of this.state.fileRows) {
            if (!this.state.currentFolder && !file.folderId) {
                files.push(file);
            } else if (file.folderId == this.state.currentFolder?.id) {
                files.push(file);
            }
        }
        return files;
    }

    async uploadFileTapped(e:any) {
        if (!e.nativeEvent.target || !e.nativeEvent.target.files) { return }

        const file = e.nativeEvent.target.files[0];
        let fileName = file.name;
        let currentFilePathList = this.getFullFolderPath();
        let currentFilePath = currentFilePathList.join("/");
        if (currentFilePath && currentFilePath != "") {
            fileName = currentFilePath + "/" + fileName;
        }

        try {
            await FileUtil.uploadImage(e, fileName);
            console.log("uploaded file success: " + fileName);

        } catch (e) {
            this.props.base.notify(e as string, "error");
            return;
        }

        let fileModel:FileInput = {
            fileName: file.name,
            fileType: Constants.FILE_TYPE_FILE,
            fileDate: DateUtil.nowFormat(),
            fileUserId: this.global.user.id,
            folderId: this.state.currentFolder?.id
        }
        await getGlobal().dataManager.save("createFile", fileModel);
        this.updateData();
    }

    // Action methods

    onRowSelection(item: any, data: any) {
        console.log(data);
        let selectedItems = this.state.selected;
        let found = false;
        for (let selectedItem of selectedItems) {
            if (data.id == selectedItem.id) {
                found = true;
            }
        }
        if (found) {
            ArrayUtil.deleteItemByKey(data, "id", selectedItems);
        } else {
            selectedItems.push(data);
        }
        this.setState({selected:selectedItems})
    }

    isRowSelected(item:FileModel):boolean {
        let selectedItems = this.state.selected;
        for (let selectedItem of selectedItems) {
            if (item.id == selectedItem.id) return true;
        }
        return false;
    }

    async deleteItemTapped(item:FileModel) {
        console.log(item);

        // TODO: Delete file
        await this.global.dataManager.delete("deleteFile", item)

        this.updateData();

        this.props.base.notify("File deleted");
    }

    async downloadTapped() {
        if (!this.state.selected) return;
        try {
            let fileName = this.state.selected[0].fileName;
            let currentFilePathList = this.getFullFolderPath();
            let currentFilePath = currentFilePathList.join("/");
            if (currentFilePath && currentFilePath != "") {
                fileName = currentFilePath + "/" + fileName;
            }
            await FileUtil.downloadFile(fileName);
        } catch (e) {
            this.props.base.notify(e as string, "error");
        }
    }

    async saveButtonTapped(e:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding()
        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();
        this.props.base.notify("user saved successfully");
    }

    public fileRowClicked(item:FileModel) {
        console.log(item);
        if (item.fileType == Constants.FILE_TYPE_FOLDER) {
            this.setState({currentFolder:item}, function(this:InformationPage) {
                this.updateData();
            }.bind(this));
        } else if (item.fileType == Constants.FILE_TYPE_FILE) {
            this.setState({selected:[item]}, function(this:InformationPage) {
                this.downloadTapped();
            }.bind(this));
        }
    }

    // Render methods

    public onBreadCrumbTapped(item) {
        let currentFolder = null;
        for (let file of this.state.fileRows) {
            if (file.fileName == item) {
                currentFolder = file;
            }
        }
        this.setState({currentFolder:currentFolder}, function(this:InformationPage) {
            this.updateData();
        }.bind(this));
    }

    public getFullFolderPath():Array<string> {
        let items:Array<string> = [];
        let foundHome = false;
        if (this.state.currentFolder) {
            let folders = [this.state.currentFolder];
            while (!foundHome) {
                if (folders[0].folderId) {
                    let folder = ArrayUtil.getItem(this.state.fileRows, "id", folders[0].folderId);
                    folders.splice(0, 0, folder);
                } else {
                    foundHome = true;
                }
            }
            console.log(folders);
            for (let folder of folders) {
                items.push(folder.fileName);
            }
        }
        return items;
    }

    public renderBreadCrumbs() {
        let cf = this.state.componentFactory;
        let items = ["Home"];
        if (this.state.currentFolder) {
            items.push(... this.getFullFolderPath());
        }
        /*let foundHome = false;
        if (this.state.currentFolder) {
            let folders = [this.state.currentFolder];
            while (!foundHome) {
                if (folders[0].folderId) {
                    let folder = ArrayUtil.getItem(this.state.fileRows, "id", folders[0].folderId);
                    folders.splice(0,0, folder);
                } else {
                    foundHome = true;
                }
            }
            console.log(folders);
            for (let folder of folders) {
                items.push(folder.fileName);
            }
        }*/

        return cf.renderHorizontalLayout(
            items.map((item:string, index:number) => {
                return [
                    cf.renderButton(item, (e:any) => this.onBreadCrumbTapped(item), {isFlat:true, primary:index != items.length-1, secondary:index == items.length-1}),
                    index < items.length-1 ? " > " : null
                ]
            })
        )
        return this.state.currentFolder?.fileName;
    }

    public renderPosts() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox("posts")
        )
    }

    public renderFileRows(item:FileModel) {
        let cf = this.state.componentFactory;
        /*let editFunction = DialogUtil.openFunction(this.addFolderDialog.current, item);
        let deleteFunction = () => {
            this.props.base.alert("Confirm Delete", "Are you sure you want to delete this folder? This action cannot be undone.", this.deleteItemTapped.bind(this), item)
        }*/
        //const actions = this.state.componentFactory.renderEditDeleteButtons(editFunction, deleteFunction);
        let user = ArrayUtil.getItem(this.state.users, "id", item.fileUserId);
        let icon = item.fileType == Constants.FILE_TYPE_FILE ? <File color="primary"/> : <Folder color="primary"/>

        let rows = [];
        /*if (this.global.user?.admin) {
            rows.push(cf.renderCheckbox(this.isRowSelected(item), (e:any) => { this.onRowSelection(null, item) }));
        }*/
        rows.push(...[
            <div style={{display:'flex', alignItems:'center'}}>{icon}<span style={{marginLeft:'16px'}}>{cf.renderButton(item.fileName, (e:any) => { this.fileRowClicked(item) }, {isFlat:true})}</span></div>,
            user?.name,
            DateUtil.format(item.fileDate, "MMMM DD, YYYY hh:mm a"),
        ]);
        if (this.global.user?.admin) {
            let deleteFunction = () => {
                let fileType = item.fileType == Constants.FILE_TYPE_FOLDER ? "folder" : "file";
                this.props.base.alert("Confirm Delete", "Are you sure you want to delete this " + fileType + "? This action cannot be undone.", this.deleteItemTapped.bind(this), item)
            }
            let editFunction = null;
            if (item.fileType == Constants.FILE_TYPE_FOLDER) {
                editFunction = DialogUtil.openFunction(this.addFolderDialog.current, item);
            }

            const actions = this.state.componentFactory.renderEditDeleteButtons(editFunction, deleteFunction)
            rows.push(actions);
        }

        return rows;
    }

    public renderFiles() {
        let cf = this.state.componentFactory;
        var headers = [];
        headers.push(...["Name", "Modified By", "Date Modified"]);
        if (this.global.user?.admin) {
            headers.push("Actions");
        }

        return (
            cf.renderBox([
                cf.renderTable(headers, this.getFilesForFolder(), this.renderFileRows.bind(this), {fitToContent:true})
            ])
        )
    }

    public render() {
        let cf = this.state.componentFactory;
        var headerRowContent = [];
        if (this.global.user?.admin) {
            let param = null;
            if (this.state.currentFolder) {
                param = {
                    folderId: this.state.currentFolder?.id
                }
            }
            headerRowContent = [
                cf.renderButton("Add Folder", DialogUtil.openFunction(this.addFolderDialog.current, param)),
                cf.renderBoxProps({marginLeft: '20px'}, null),
                <ButtonComponent primary title="Upload" forUpload={true} onTouchTap={this.uploadFileTapped.bind(this)}/>
            ];
        }
        /*if (this.state.selected.length > 0) {
            let deleteFunction = () => {
                this.props.base.alert("Confirm Delete", "Are you sure you want to delete this folder? This action cannot be undone.", this.deleteItemTapped.bind(this), null)
            }
            headerRowContent.push(cf.renderBoxProps({marginLeft:'20px'}, null));
            headerRowContent.push(cf.renderButton("Delete", deleteFunction, {isFlat:true}));
        }
        if (this.state.selected.length == 1 && this.state.selected[0].fileType == Constants.FILE_TYPE_FILE) {
            headerRowContent.push(cf.renderBoxProps({marginLeft:'20px'}, null));
            headerRowContent.push(cf.renderButton("Download", this.downloadTapped.bind(this), {isFlat:true}));
        }*/

        return (
            !this.global.isDataLoaded ?
                VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                    :
                    cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                        this.state.dialogs,
                        cf.renderBoxProps({marginLeft: '90px'}, [
                            VisualUtil.getHeaderDisplay("Information Center"),
                            cf.renderLine(),
                            cf.renderBoxProps({
                                    marginTop: '20px',
                                    marginLeft: '20px',
                                    marginRight: '20px',
                                    marginBottom: '20px'
                                },
                                cf.renderRightLeftLayout(this.renderBreadCrumbs(), headerRowContent)
                            ),
                            cf.renderLine(),
                            this.renderFiles()
                        ])
                    ])
        )
    }
}

export default base(InformationPage, Sidebar)