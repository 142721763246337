import * as React from 'reactn';
import {ComponentFactory, ComponentUtil, dialog, IBaseDialogProps, SelectItem} from "oca-lib-web";
import ReactGA from "react-ga";
import Constants from "../util/Constants";
import Styles from "../style/Styles";

interface IState {
    //product?:ProductModel,
    productGroupOptions:Array<SelectItem>,
    componentFactory:ComponentFactory
}

class AddProductDialog extends React.Component<IBaseDialogProps, IState> {

    public components = ComponentUtil.createRefs(2);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createProduct", props.param);

        this.state = {
            componentFactory: componentFactory,
            //product: props.param ? props.param : {},
            productGroupOptions: [],
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/productDialog');

        let productGroupOptions = this.state.componentFactory.createOptions(await this.global.dataManager.get("getProductGroups"), "id", "productGroupName");
        this.setState({productGroupOptions:productGroupOptions});
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                cf.renderBoxPadded(cf.renderTextField("productName", "Product Name", this.components[0], null, {required:true})),
                cf.renderBoxPadded(cf.renderSelectField("productGroupId", "Product Group", this.state.productGroupOptions, this.components[1], null, {required:true})),
                cf.renderHorizontalLayout([
                    cf.renderBoxPadded(cf.renderSelectField("seasonStartMonth", "Season Start Month", Constants.PRODUCT_SEASONAL_MONTHS)),
                    cf.renderBoxPadded(cf.renderSelectField("seasonStartDay", "Season Start Day", Constants.PRODUCT_SEASONAL_DAYS))
                ], ['50%','50%']),
                cf.renderHorizontalLayout([
                    cf.renderBoxPadded(cf.renderSelectField("seasonEndMonth", "Season End Month", Constants.PRODUCT_SEASONAL_MONTHS)),
                    cf.renderBoxPadded(cf.renderSelectField("seasonEndDay", "Season End Day", Constants.PRODUCT_SEASONAL_DAYS))
                ], ['50%','50%']),
                cf.renderBoxPadded(cf.renderSwitchField("suggestedFilter", "Suggested Filter")),
                cf.renderBoxPadded(cf.renderSwitchField("filterPriority", "Filter Priority")),
            ])
        )
    }
}

export default dialog(AddProductDialog)