import { Cache } from 'aws-amplify';
//import {ArrayUtils} from "oca-lib-web";

export default class DataManager {

    private dataAccess:any;
    private cacheEnabled = false;

    constructor(dataAccess:any) {
        this.dataAccess = dataAccess;
    }

    public config() {
        /*const config = {
            itemMaxSize: 3000, // 3000 bytes
            defaultPriority: 4
        };*/

        const config = {
            storage: window.sessionStorage
        };

        //this.cacheInstance = Cache.createInstance(config);
        Cache.configure(config);
    }

    public async get(dataMethod:string, param?:any):Promise<any> {
        var self = this;
        //var cacheSuffix = param && typeof param === 'object' ? ("_" + JSON.stringify(param)) : param?.id ? ("_" + param?.id) : (param || "");
        var cacheSuffix = param?.id ? ("_" + param?.id) : typeof param === 'object' ? ("_" + JSON.stringify(param)) : param ? ("_" + param) : "";
        return new Promise<any>(async function(resolve) {
            let fullCacheKey = dataMethod + cacheSuffix;
            let items = Cache.getItem(fullCacheKey);
            if (items && self.cacheEnabled && self.shouldCache(dataMethod)) {
                console.log("Getting " + fullCacheKey + " from cache");
                resolve(items);
            } else {
                let items = await self.dataAccess[dataMethod](param);
                console.log("Getting " + fullCacheKey + " from network");
                if (items) {
                    Cache.setItem(fullCacheKey, items);
                } else {
                    console.error(fullCacheKey + " is null");
                }
                resolve(items);
            }
        });
    }

    public async save(dataMethod:string, param:any):Promise<any> {
        var self = this;
        return new Promise<any>(async function(resolve) {
            await self.dataAccess[dataMethod](param);

            if (self.cacheEnabled && self.shouldCache(dataMethod)) {
                let objectName = self.getObjectName(dataMethod);
                let keys = await self.getAllKeys();
                for (let key of keys) {
                    // Need to determine if new. If it is new than we go through each object and look for array and of object type than add
                    let items = Cache.getItem(key);
                    if (Array.isArray(items)) {
                        let found = false;
                        let keyObjectName = self.getObjectName(key);
                        console.log("key object name: " + keyObjectName + " object name: " + objectName);
                        console.log(items);
                        if (keyObjectName.startsWith(objectName) && self.isNewObject(param)) {
                            found = true;
                            console.log("found a new object for " + objectName)
                        } else {
                            for (let item of items) {
                                if (item.id == param.id) {
                                    found = true;
                                    //ArrayUtils.deleteItem(item, items);
                                }
                            }
                        }
                        if (found) {
                            //items.push(result);
                            Cache.removeItem(key);
                            //Cache.setItem(key, items);
                        }
                    } else {
                        if (items.id == param.id) {
                            Cache.removeItem(key);
                            //Cache.setItem(key, param);
                        }
                    }
                }
            }
            resolve(param);
        });
    }

    public async perform(dataMethod:string, param:any):Promise<any> {
        var self = this;
        return new Promise<any>(async function(resolve) {
            let result = await self.dataAccess[dataMethod](param);
            resolve(result);
        });
    }

    public isNewObject(param:any) {
        return !param["id"];
    }

    public async delete(dataMethod:string, param:any):Promise<any> {
        var self = this;
        return new Promise<any>(async function(resolve) {
            await self.dataAccess[dataMethod](param);

            if (self.cacheEnabled && self.shouldCache(dataMethod)) {
                let keys = await self.getAllKeys();
                for (let key of keys) {
                    let items = Cache.getItem(key);
                    if (Array.isArray(items)) {
                        //let found = false;
                        for (let item of items) {
                            if (item.id == param.id) {
                                Cache.removeItem(key);
                                //ArrayUtils.deleteItem(item, items);
                                //found = true;
                            }
                        }
                        /*if (found) {
                            Cache.setItem(key, items);
                        }*/
                    } else {
                        if (items.id == param.id) {
                            Cache.removeItem(key);
                        }
                    }
                }
            }
            resolve(param);
        });
    }

    // Cache methods

    public clear() {
        Cache.clear();
        console.log("Cache has been cleared");
    }

    public currentCacheSize() {
        const size = Cache.getCacheCurSize();
        return size;
    }

    public getAllKeys() {
        return Cache.getAllKeys();
    }

    // names
    public getObjectName(dataMethod:String) {
        dataMethod = dataMethod.replace("create", "");
        dataMethod = dataMethod.replace("delete", "");
        dataMethod = dataMethod.replace("get", "");
        return dataMethod.toLowerCase();
    }

    public shouldCache(dataMethod:String) {
        let objectName = this.getObjectName(dataMethod);
        let objectsToCache = [
            "Account",
            "AssetTagGroup",
            "AssetTag",
            "AppIssue",
            "Product",
            "ProductGroup"
        ];
        for (let objectToCache of objectsToCache) {
            if (objectName.startsWith(objectToCache.toLowerCase())) {
                return true;
            }
        }
        return false;
    }
}