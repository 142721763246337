import * as React from 'reactn';
import {ComponentFactory, ComponentUtil, dialog, IBaseDialogProps, SelectItem} from "oca-lib-web";
import ReactGA from 'react-ga';
import moment from "moment";
import UserUtil from "../util/UserUtil";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
}

class AppIssueDialog extends React.Component<IBaseDialogProps, IState> {

    public components = ComponentUtil.createRefs(2);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createAppIssue", props.param);

        this.state = {
            componentFactory:componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/appIssueDialog');
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        componentBinding.setProperty("date", moment());
        componentBinding.setProperty("userId", this.global.user.id);
        componentBinding.setProperty("status", "open");

        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        this.sendAppIssueEmail();

        callback(componentBinding.item);
    }

    async sendAppIssueEmail() {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let adminEmailAddresses = await UserUtil.getAdminEmailAddresses();
        let messageBody = "";
        messageBody += "A new issue was submitted by " + this.global.user.name;
        //let account = await UserUtil.getAccountForUser(this.global.user);
        if (this.global.account) {
            messageBody += " from " + this.global.account.accountName;
        }
        messageBody += "<br/><br/>"
        messageBody += "<b>Issue Text: </b>" + componentBinding.getProperty("issueText") + "<br/>";
        messageBody += "<b>Submitted Date: </b>" + moment(componentBinding.getProperty("date")).format("MMMM DD, YYYY") + "<br/>";
        messageBody += "<b>Issue Type: </b>" + UserUtil.getAppIssueTypeLabel(componentBinding.getProperty("issueType"));
        UserUtil.sendEmail(adminEmailAddresses, "Visit NC Farms Issue Created", messageBody);
    }

    render() {
        let cf = this.state.componentFactory;

        let issueType = [];
        issueType.push(SelectItem.create("iosBug", "iOS App Bug"));
        issueType.push(SelectItem.create("androidBug", "Android App Bug"));
        issueType.push(SelectItem.create("websiteBug", "Admin Website Bug"));
        issueType.push(SelectItem.create("featureRequest", "Feature Request"));

        return (
            cf.renderBox(
                cf.renderBoxPadded([
                    cf.renderBoxPadded(cf.renderSelectField("issueType", "Issue Type", issueType, this.components[0])),
                    cf.renderBoxPadded(cf.renderTextField("issueText", "Describe your issue or feature", this.components[1], null, {type:"textarea", multiline:true}))
                ])
            )
        )
    }
}

export default dialog(AppIssueDialog)