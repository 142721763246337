export default class AssetRelationshipQuery {

    public static GetAssetRelationships = `query GetAssetRelationshipsQuery($assetId: String) {
        getAssetRelationships (
            assetId:$assetId
        ) {
            id
            relationshipDescription
            relationshipAssets
            assetId
        }
    }`;

    public static CreateAssetRelationship = `mutation CreateAssetRelationshipMutation($input: AssetRelationshipInput!) {
        createAssetRelationship(input: $input) {
            id
            relationshipDescription
            relationshipAssets
            assetId
        }
    }`;

    public static DeleteAssetRelationship = `mutation DeleteAssetRelationshipMutation($id: ID!) {
        deleteAssetRelationship(id: $id) {
            id
        }
    }`;
}