import * as React from 'reactn';
import Constants from "../util/Constants";
import {addCallback, getGlobal} from "reactn";
import visit_nc_farms_logo from '../image/visit_nc_farms_logo.png'
import {BaseIcon, Route} from "oca-lib-web";
import {ComponentFactory} from "oca-lib-web";
import Styles from "../style/Styles";
import FeatureEnum from "../util/FeatureEnum";

interface IProps {
    onSelect:(index:any) => void
}

interface IState {
    componentFactory:ComponentFactory,
    open: boolean
}

class Sidebar extends React.Component<IProps, IState> {

    constructor(props: any) {
        super();

        this.state = {
            componentFactory: new ComponentFactory(Styles.default),
            open: true
        }
    }

    componentDidMount() {
        var self = this;
        addCallback(global => {
            self.forceUpdate();
            return null;
        });
    }

    render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderDrawer("permanent", this.state.open, "left",
                cf.renderBoxProps({backgroundColor:Constants.WHITE_COLOR, center:true, height:'100vh'}, [
                    cf.renderBoxProps({marginLeft:"20px", marginRight:"20px", marginTop:"20px", marginBottom:"30px"},
                        <img src={visit_nc_farms_logo} width={50} className="center-img" />
                    ),
                    cf.renderBox(cf.renderIconButton(BaseIcon.ICON_LIST, () => Route.go(this, "list"))),
                    cf.renderBoxProps({marginTop:'20px'}, cf.renderIconButton(BaseIcon.ICON_MAP, () => Route.go(this, "map"))),
                    cf.renderBoxProps({marginTop:'20px'}, cf.renderIconButton(BaseIcon.ICON_EXPLORE, () => Route.go(this, "itineraries"))),
                    cf.renderBoxProps({marginTop:'20px'}, cf.renderIconButton(BaseIcon.ICON_EMAIL, () => Route.go(this, "messaging"))),
                    cf.renderBoxProps({marginTop:'20px'}, cf.renderIconButton(BaseIcon.ICON_CHAT_BUBBLE, () => Route.go(this, "notification"))),
                    cf.renderBoxProps({marginTop:'20px'}, cf.renderIconButton(BaseIcon.ICON_OFFER, () => Route.go(this, "sponsor"))),
                    FeatureEnum.INFORMATION_CENTER && getGlobal().user?.admin ? cf.renderBoxProps({marginTop:'20px'}, cf.renderIconButton(BaseIcon.ICON_FILE, () => Route.go(this, "information"))) : null,
                    getGlobal().user?.admin ? cf.renderBoxProps({marginTop:'20px'}, cf.renderIconButton(BaseIcon.ICON_BUILD, () => Route.go(this, "issue"))) : null,
                    getGlobal().user?.admin ? cf.renderBoxProps({marginTop:'20px'}, cf.renderIconButton(BaseIcon.ICON_SETTINGS, () => Route.go(this, "admin"))) : null,
                    cf.renderBoxProps({bottom:true, center:true, marginLeft:'20px'}, cf.renderBox(cf.renderIconButton(BaseIcon.ICON_PROFILE, () => Route.go(this, "profile")))),
                ])
            )
        )
    }
}

export default Sidebar