export default class MessageResources {

    private static ASSET_NAME = "Name";
    private static ASSET_ADDRESS = "Address";
    private static ASSET_PHONE_NUMBER = "Phone Number";
    private static ASSET_WEBSITE = "Website";
    private static ASSET_CONTACT_PREFERENCE = "Contact Preference";
    private static ASSET_EMAIL_ADDRESS = "Contact Email Address";
    private static ASSET_CONTACT_PHONE_NUMBER = "Contact Phone Number";
    private static ASSET_PAYMENT_METHODS = "Payment Options";
    private static ASSET_DESCRIPTION = "Description";
    private static ASSET_PRODUCTS = "Farm Products";
    private static ASSET_HOURS = "Hours";
    private static ASSET_FACEBOOK = "Facebook";
    private static ASSET_TWITTER = "Twitter";
    private static ASSET_INSTAGRAM = "Instagram";
    private static ASSET_CATEGORIES = "Asset Categories";
    private static ASSET_TAGS = "Asset Tags";
    private static ASSET_ACTIVE = "Active";

    public static getText(key:string):string {
        if (key == "assetName") {
            return this.ASSET_NAME;
        } else if (key == "assetAddress") {
            return this.ASSET_ADDRESS;
        } else if (key == "assetPhoneNumber") {
            return this.ASSET_PHONE_NUMBER;
        } else if (key == "assetWebsite") {
            return this.ASSET_WEBSITE;
        } else if (key == "assetContactPreference") {
            return this.ASSET_CONTACT_PREFERENCE;
        } else if (key == "assetEmailAddress") {
            return this.ASSET_EMAIL_ADDRESS;
        } else if (key == "assetContactPhoneNumber") {
            return this.ASSET_CONTACT_PHONE_NUMBER;
        } else if (key == "assetPaymentMethods") {
            return this.ASSET_PAYMENT_METHODS;
        } else if (key == "assetDescription") {
            return this.ASSET_DESCRIPTION;
        } else if (key == "assetProducts") {
            return this.ASSET_PRODUCTS;
        } else if (key == "assetHours") {
            return this.ASSET_HOURS;
        } else if (key == "assetFacebook") {
            return this.ASSET_FACEBOOK;
        } else if (key == "assetTwitter") {
            return this.ASSET_TWITTER;
        } else if (key == "assetInstagram") {
            return this.ASSET_INSTAGRAM;
        } else if (key == "assetCategories") {
            return this.ASSET_CATEGORIES;
        } else if (key == "assetTags") {
            return this.ASSET_TAGS;
        } else if (key == "isActive") {
            return this.ASSET_ACTIVE;
        }
        return "";
    }
}