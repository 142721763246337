import * as React from 'reactn';
import {ComponentFactory, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from "react-ga";
import Styles from "../style/Styles";

interface IState {
    assetRows?:Array<AssetModel>,
    componentFactory:ComponentFactory
}

class ContactPhoneCallDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        this.state = {
            assetRows: props.param ? props.param : {},
            componentFactory: new ComponentFactory(Styles.default)
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/sendMessage/contactPhoneCallDialog');
    }

    async handleSave(callback:any) {
        callback(true);
    }

    getAssetRow(item:AssetModel) {
        return [
            item.assetName,
            item.assetContactPhoneNumber
        ]
    }

    render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                cf.renderBoxPadded("The assets below have a phone call as their contact preference. We recommend that you keep this dialog option until you have reached out to each of these assets"),
                cf.renderTable(["Asset Name", "Phone Number"], this.state.assetRows, this.getAssetRow.bind(this))
            ])
        )
    }
}

export default dialog(ContactPhoneCallDialog)