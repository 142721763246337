import * as React from 'react';
//import { Authenticator, ConfirmSignIn, VerifyContact, RequireNewPassword } from 'aws-amplify-react/dist/Auth';
import { Authenticator } from 'aws-amplify-react/dist/Auth';
import {BrowserRouter as Router} from 'react-router-dom';
//import LoginPage from '../components/LoginPage'
//import SignupPage from '../components/SignupPage'
//import SignupConfirmPage from '../components/SignupConfirmPage'
//import ForgotPasswordPage from './ForgotPasswordPage'
//import ForgotPasswordConfirm from "./ForgotPasswordConfirmPage";
//import SignupConfirmLinkPage from "../components/SignupConfirmLinkPage";
//import AmplifyTheme from '../style/AmplifyTheme';
import LoginPage from "../../container/login/LoginPage";
import AuthSuccessPage from "./AuthSuccessPage";
import SignupPage from "../../container/login/SignupPage";
import ForgotPasswordPage from "../../container/login/ForgotPasswordPage";
import SignupConfirmPage from "../../container/login/SignupConfirmPage";

interface IProps {
    children: any,
    authSettings?: AuthSettings
}

interface IState {
    authState: string
}

class AuthenticatedComponent extends React.Component<IProps, IState> {

    public static defaultProps = {};

    public state = {
        authState: 'signIn'
    };

    constructor(props: any) {
        super(props);
        this.handleAuthStateChange = this.handleAuthStateChange.bind(this);
        // this.state = { authState: 'signIn' }
    }

    public handleAuthStateChange(state: any) {
        try {
            this.setState({ authState: state });

            if (this.props.authSettings.handleAuthStateChangeCallback) {
                this.props.authSettings.handleAuthStateChangeCallback(state);
            }

            switch (state) {
                case 'signedIn':
                    break;
                default:
                    break;
            }

        } catch (error) {
            console.error(error)
        }
    }

    public render() {
        //const props = this.props
        //const authState = this.state.authState;

        let federatedConfig = {};
        if (this.props.authSettings.googleId) {
            federatedConfig["google_client_id"] = this.props.authSettings.googleId;
        }
        if (this.props.authSettings.facebookId) {
            federatedConfig["facebook_app_id"] = this.props.authSettings.googleId;
        }

        return (
            <Router>
                <Authenticator
                    onStateChange={this.handleAuthStateChange}
                    hideDefault={true}
                    includeGreeting={false}
                    federated={federatedConfig}>
                        <LoginPage {... this.props} />
                        <SignupPage {... this.props} />
                        <SignupConfirmPage {... this.props} />
                        <ForgotPasswordPage {... this.props} />
                        <AuthSuccessPage>{this.props.children}</AuthSuccessPage>
                </Authenticator>
            </Router>
        )
    }
}

export default AuthenticatedComponent;