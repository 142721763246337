import {SelectItem} from "oca-lib-web";

export default class Constants {

    public static FONT_FAMILY = "Open Sans";
    public static WHITE_COLOR = "#ffffff";
    public static BLACK_COLOR = "#000000"
    public static BLUE_COLOR = "#1564FF";
    //public static GREEN_COLOR = "#2ECC71";
    public static GREEN_COLOR = "#28B351";
    public static RED_COLOR = "#DC503B";
    public static GRAY_COLOR = "#9e9fba";
    public static TRANSPARENT_COLOR = "transparent";
    public static LIGHT_GRAY_COLOR = "#D8D8D8";
    public static DARK_GRAY_COLOR = "#7b7b7b";
    public static YELLOW_COLOR = "#FFA000";

    public static COLORS = ["#00A5E3", "#8DD7BF", "#FF96C5", "#FFBF65",
        "#FC6238", "#FFD872", "#F2D4CC", "#E77577",
        "#6C88C4", "#C05780", "#FF828B", "#E7C582",
        "#00B0BA", "#0065A1", "#00CFAC", "#FF6F68",
        "#FFDACC", "#FF60A8", "#CFF800", "#FF5C77",
        "#4DD091", "#FFEC59", "#FFA23A", "#74737A"]

    public static HISTORY_TYPE_ASSET = "asset";

    public static MESSAGE_TYPE_EMAIL = "email";

    public static MESSAGE_TYPE_ASSET = "asset";
    public static MESSAGE_TYPE_USER = "user";

    public static ATTRIBUTE_TITLE = "oca_title";
    public static ATTRIBUTE_SUBTITLE = "oca_subtitle";

    //public static IMGIX_HOST = "https://oak-city-apps.imgix.net";
    //public static IMAGE_DIR = "public/image";
    //public static PUBLIC_DIR = "public";
    public static MAX_NUMBER_OF_ASSET_IMAGES = 9;

    public static GEOCODING_API = "https://maps.googleapis.com/maps/api/geocode/json?address={0}&key=AIzaSyDqk9oXM0dKqUfOERIdvrQ_NUvcJ9aEUZQ";

    //public static NC_COUNTIES_API = "https://opendata.arcgis.com/datasets/34acbf4a26784f189c9528c1cf317193_0.geojson";

    public static FILE_TYPE_FOLDER = 0;
    public static FILE_TYPE_FILE = 1;

    public static NOTIFICATION_TYPE_ASSET = "asset";
    public static NOTIFICATION_TYPE_ASSET_EVENT = "assetEvent";
    public static NOTIFICATION_TYPE_ITINERARY = "itinerary";

    // TODO: Move asset values to constants
    public static ASSET_GROUP_CATEGORIES = [
        SelectItem.create("farmFisheries", "Farms + Fisheries"),
        SelectItem.create("pickYourOwn", "Pick Your Own"),
        SelectItem.create("farmersMarket", "Farmers Markets"),
        SelectItem.create("localFoodDrink", "Local Food + Drink"),
        SelectItem.create("localShops", "Shop Local"),
        SelectItem.create("farmStaysLodging", "Farm Stays + Lodging"),
        SelectItem.create("toursTrails", "Tours + Trails")
    ];

    public static ALL_GROUP_CATEGORIES = [
        ... Constants.ASSET_GROUP_CATEGORIES,
        SelectItem.create("specialEvents", "Special Events")
    ]

    public static EVENT_RECURRENCE_SELECTION = [
        SelectItem.create("none", "None"),
        SelectItem.create("daily", "Daily"),
        SelectItem.create("weekly", "Weekly"),
        SelectItem.create("biweekly", "Biweekly"),
        SelectItem.create("monthly", "Monthly"),
        SelectItem.create("custom", "Custom")
    ];

    public static EVENT_CUSTOM_RECURRENCE_SELECTION = [
        SelectItem.create("sunday", "Sunday"),
        SelectItem.create("monday", "Monday"),
        SelectItem.create("tuesday", "Tuesday"),
        SelectItem.create("wednesday", "Wednesday"),
        SelectItem.create("thursday", "Thursday"),
        SelectItem.create("friday", "Friday"),
        SelectItem.create("saturday", "Saturday"),
    ];

    public static SEASONAL_MONTHS = [
        SelectItem.create("jan", "January"),
        SelectItem.create("feb", "February"),
        SelectItem.create("mar", "March"),
        SelectItem.create("apr", "April"),
        SelectItem.create("may", "May"),
        SelectItem.create("jun", "June"),
        SelectItem.create("jul", "July"),
        SelectItem.create("aug", "August"),
        SelectItem.create("sep", "September"),
        SelectItem.create("oct", "October"),
        SelectItem.create("nov", "November"),
        SelectItem.create("dec", "December"),
    ]

    public static PRODUCT_SEASONAL_MONTHS = [
        SelectItem.create("01", "January"),
        SelectItem.create("02", "February"),
        SelectItem.create("03", "March"),
        SelectItem.create("04", "April"),
        SelectItem.create("05", "May"),
        SelectItem.create("06", "June"),
        SelectItem.create("07", "July"),
        SelectItem.create("08", "August"),
        SelectItem.create("09", "September"),
        SelectItem.create("10", "October"),
        SelectItem.create("11", "November"),
        SelectItem.create("12", "December"),
    ]

    public static PRODUCT_SEASONAL_DAYS = [
        SelectItem.create("01", "01"),
        SelectItem.create("02", "02"),
        SelectItem.create("03", "03"),
        SelectItem.create("04", "04"),
        SelectItem.create("05", "05"),
        SelectItem.create("06", "06"),
        SelectItem.create("07", "07"),
        SelectItem.create("08", "08"),
        SelectItem.create("09", "09"),
        SelectItem.create("10", "10"),
        SelectItem.create("11", "11"),
        SelectItem.create("12", "12"),
        SelectItem.create("13", "13"),
        SelectItem.create("14", "14"),
        SelectItem.create("15", "15"),
        SelectItem.create("16", "16"),
        SelectItem.create("17", "17"),
        SelectItem.create("18", "18"),
        SelectItem.create("19", "19"),
        SelectItem.create("20", "20"),
        SelectItem.create("21", "21"),
        SelectItem.create("22", "22"),
        SelectItem.create("23", "23"),
        SelectItem.create("24", "24"),
        SelectItem.create("25", "25"),
        SelectItem.create("26", "26"),
        SelectItem.create("27", "27"),
        SelectItem.create("28", "28"),
        SelectItem.create("29", "29"),
        SelectItem.create("30", "30"),
        SelectItem.create("31", "31"),
    ]

    public static NOTIFICATION_TIMES = [
        SelectItem.create("0900", "9:00am"),
        SelectItem.create("1000", "10:00am"),
        SelectItem.create("1100", "11:00am"),
        SelectItem.create("1200", "12:00pm"),
        SelectItem.create("1300", "1:00pm"),
        SelectItem.create("1400", "2:00pm"),
        SelectItem.create("1500", "3:00pm"),
        SelectItem.create("1600", "4:00pm"),
        SelectItem.create("1700", "5:00pm"),
        SelectItem.create("1800", "6:00pm"),
    ];

    public static PICK_YOUR_OWN_CATEGORIES = [
        SelectItem.create("apples", "Apples"),
        SelectItem.create("blackberries", "Blackberries"),
        SelectItem.create("blueberries", "Blueberries"),
        SelectItem.create("christmas trees", "Christmas Trees"),
        SelectItem.create("flowers", "Flowers"),
        SelectItem.create("grapes", "Grapes"),
        SelectItem.create("peaches", "Peaches"),
        SelectItem.create("pecans", "Pecans"),
        SelectItem.create("pumpkins", "Pumpkins"),
        SelectItem.create("strawberries", "Strawberries"),
        SelectItem.create("sunflowers", "Sunflowers"),
        SelectItem.create("tulips", "Tulips"),
        SelectItem.create("watermelon", "Watermelon")
    ]

    public static getAssetCategoryLabel(value:string): string {
        for (let assetCategory of this.ALL_GROUP_CATEGORIES) {
            if (assetCategory.value == value) {
                return assetCategory.label;
            }
        }
        return null;
    }

    public static REGIONS = ["mountains", "piedmont", "coast"];
    public static COUNTIES = ['alamance', 'alexander', 'alleghany', 'anson', 'ashe', 'avery', 'beaufort', 'bertie', 'bladen', 'brunswick',
        'buncombe', 'burke', 'cabarrus', 'caldwell', 'camden', 'carteret', 'caswell', 'catawba', 'chatham', 'cherokee', 'chowan', 'clay', 'cleveland', 'columbus',
        'craven', 'cumberland', 'currituck', 'dare', 'davidson', 'davie', 'duplin', 'durham', 'edgecombe', 'forsyth', 'franklin', 'gaston',
        'gates', 'graham', 'granville', 'greene', 'guilford', 'halifax', 'harnett', 'haywood', 'henderson', 'hertford', 'hoke', 'hyde', 'iredell', 'jackson', 'johnston', 'jones',
        'lee', 'lenoir', 'lincoln', 'macon', 'madison', 'martin', 'mcdowell', 'mecklenburg', 'mitchell', 'montgomery', 'moore', 'nash', 'new hanover', 'northampton', 'onslow',
        'orange', 'pamlico', 'pasquotank', 'pender', 'perquimans', 'person', 'pitt', 'polk', 'randolph', 'richmond', 'robeson', 'rockingham', 'rowan', 'rutherford', 'sampson',
        'scotland', 'stanly', 'stokes', 'surry', 'swain', 'transylvania', 'tyrrell', 'union', 'vance', 'wake', 'warren', 'washington', 'watauga', 'wayne', 'wilkes', 'wilson',
        'yadkin', 'yancey'
    ];

}