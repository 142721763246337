import * as React from 'reactn';
import {
    ArrayUtil,
    base,
    BaseColor,
    DateComponent, IBaseProps,
    Route, StringUtil,
    SelectComponent,
    SelectItem,
    TextComponent, ComponentFactory, DialogUtil, DateUtil, BaseIcon, PopoverComponent
} from "oca-lib-web";
import {createRef} from "react";
import UserUtil from "../util/UserUtil";
import Sidebar from "../component/Sidebar";
import {addCallback} from "reactn";
import NotificationIssueDialog from "../component/NotificationIssueDialog";
import {Alert, AlertTitle} from "@mui/lab";
//import {Accordion, AccordionDetails, AccordionSummary, Typography} from "@material-ui/core";
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Constants from "../util/Constants";
import 'emoji-mart/css/emoji-mart.css'
import { Picker } from 'emoji-mart'
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import ImageUtil from "../util/ImageUtil";
import NotificationUtil from "../util/NotificationUtil";

interface IState {
    componentFactory:ComponentFactory,
    dialogs:Array<any>,
    targetTypes:any[],
    targetSelections:any[],
    assetTagOptions:Array<SelectItem>,
    productOptions:Array<SelectItem>,
    errorText?:string
}

class SendNotificationPage extends React.Component<IBaseProps, IState> {
    public pushNotificationTitleComponent = createRef<TextComponent>();
    public pushNotificationBodyComponent = createRef<TextComponent>();
    public scheduledDateComponent = createRef<DateComponent>();
    public scheduledTimeComponent = createRef<SelectComponent>();
    public targetTypeComponent = createRef<SelectComponent>();
    public targetSelectionComponent = createRef<SelectComponent>();
    public targetAssetTagComponent = createRef<SelectComponent>();
    public targetProductComponent = createRef<SelectComponent>();
    public notificationIssueDialog = createRef<any>();
    public emojiiPopoverComponent = createRef<PopoverComponent>();

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createNotificationHistory", null);
        componentFactory.setHandleUpdateCallback((value:any, key:string) => {
            if (key === "appTargetType" || key === "accountId") {
                this.onTargetTypeChanged(value);
            } else if (key == "pushNotificationTitle" || key == "pushNotificationBody" || key == "notificationPhoto") {
                this.forceUpdate();
            }
            this.setState({errorText:null})

        })
        let dialogs = [
            <NotificationIssueDialog {...componentFactory.renderDialogProps("Add Issue", "Save", this.notificationIssueCallback.bind(this), this.notificationIssueDialog)} />,
        ]

        this.state = {
            targetTypes: [],
            targetSelections: [],
            assetTagOptions: [],
            productOptions: [],
            componentFactory: componentFactory,
            dialogs:dialogs
        };
    }

    async componentWillMount() {
        addCallback(global => {
            this.initView();
            return null;
        });

        this.initView();
    }

    async initView() {
        if (!this.global.isDataLoaded) return;

        let cf = this.state.componentFactory;
        let id = Route.param(this, "id");
        if (id) {
            //let notificationHistoryList = await this.global.dataManager.get("getNotificationHistoryForAdmin");
            // TODO: Replace with single call for notification history
            const notificationHistoryList = await NotificationUtil.getNotificationHistoryForAdmin();
            let notification:NotificationHistoryModel = ArrayUtil.getItem(notificationHistoryList, "id", id);
            if (!notification) {
                console.error("Could not find notification for ", id);
                return;
            }
            this.state.componentFactory.getDefaultBinding().setItem(notification);
            this.onTargetTypeChanged(notification.appTargetType);
            if (this.scheduledDateComponent.current) {
                this.scheduledDateComponent.current.handleDateChange(DateUtil.date(notification.pushNotificationDate));
            }

            console.log(notification);
        } else {
            this.state.componentFactory.getDefaultBinding().setProperty("appTargetType", "asset");
            this.state.componentFactory.getDefaultBinding().setProperty("photoPreference", "primary");
            this.onTargetTypeChanged("asset");
            //this.state.componentFactory.addDefaultBinding(this.global.data.createNotificationHistory, null);
            //this.handleChanged("asset", "appTargetType");
        }

        let assetTags = await this.global.dataManager.get("getAssetTags");
        let products = await this.global.dataManager.get("getProducts");
        let targetTypes = [
            SelectItem.create("asset", "Asset"),
            SelectItem.create("assetEvent", "Asset Event"),
            SelectItem.create("itinerary", "Itinerary")
        ];
        if (this.global.user.admin) {
            targetTypes.push(SelectItem.create("assetCategory", "Asset Category"));
            targetTypes.push(SelectItem.create("notificationCenter", "Notification Center"));
            targetTypes.push(SelectItem.create("noTarget", "No Target"));
        }

        this.setState({targetTypes:targetTypes, assetTagOptions:cf.createOptions(assetTags, "id", "assetTagName"), productOptions:cf.createOptions(products, "productName", "productName")})

        this.forceUpdate();
    }

    notificationIssueCallback(item:any) {
        this.props.base.notify("Issue added");

        this.initView();
    }

    async onTargetTypeChanged(value:any) {
        let cf = this.state.componentFactory;
        let targetSelections = [];

        if (value === "asset" || value === "assetEvent") {
            //let currentUser = await this.global.data.getCurrentUser();
            //let account = await UserUtil.getAccountForUser(currentUser);
            let currentUser = this.global.user;
            let account = this.global.account;

            let assets = [];
            if (currentUser.admin) {
                let accountId = cf.getDefaultBinding().getProperty("accountId");
                if (accountId) {
                    assets = await this.global.dataManager.get("getAssets", accountId);
                }
            } else {
                assets = await this.global.dataManager.get("getAssets", account.id);
            }

            if (value === "asset") {
                for (let asset of assets) {
                    targetSelections.push(SelectItem.create(asset.id, asset.assetName));
                }

            } else if (value === "assetEvent") {
                let assetIdList = [];
                for (let asset of assets) {
                    assetIdList.push(asset.id);
                }

                let assetEvents = await this.global.dataManager.get("getAssetEvents");
                let currentDate = DateUtil.nowFormat("YYYYMMDD");
                console.log("length: ", assetEvents.length);
                for (let assetEvent of assetEvents) {
                    let endDate = DateUtil.format(assetEvent.assetEventEndDate, "YYYYMMDD");
                    if (assetEvent.assetEventTitle === "Meat Your Farmer: Farm Tour & Cooking Demo") {
                        console.log("found asset event", assetEvent);
                    }
                    if (assetIdList.indexOf(assetEvent.assetId) > -1 && currentDate <= endDate) {
                        console.log("adding asset event", assetEvent.assetEventTitle);
                        endDate = DateUtil.format(assetEvent.assetEventEndDate, "MM/DD/YY");
                        let startDate = DateUtil.format(assetEvent.assetEventStartDate, "MM/DD/YY");
                        let dateString = startDate === endDate ? startDate : startDate + " - " + endDate;
                        targetSelections.push(SelectItem.create(assetEvent.id, assetEvent.assetEventTitle + " (" + dateString + ")"));
                    }
                }
            }

        } else if (value === "itinerary") {
            let accountId = this.global.user.admin ? cf.getDefaultBinding().getProperty("accountId") : this.global.account.id;
            let itineraries = await this.global.dataManager.get("getItineraries", accountId);
            for (let itinerary of itineraries) {
                targetSelections.push(SelectItem.create(itinerary.id, itinerary.itineraryName));
            }
        }

        ArrayUtil.sort(targetSelections, "label");
        this.setState({targetSelections:targetSelections}, function(this:SendNotificationPage) {
            this.forceUpdate();
        }.bind(this));
    }

    async uploadPictureTapped(e:any) {
        /*if (!e.nativeEvent.target || !e.nativeEvent.target.files) { return }

        const file = e.nativeEvent.target.files[0];
        const fileName = String.uuid();
        //await AWSStorage.put(fileName, file);*/
        let result = await ImageUtil.uploadImage(e);
        if (!result.success) {
            this.props.base.notify(result.message, "error");
        } else {
            console.log("uploaded file: " + result.message);

            let componentBinding = this.state.componentFactory.getDefaultBinding();
            componentBinding.setProperty("customPhoto", result.message);
            this.forceUpdate();
        }
        //componentBinding.saveBinding();
    }

    async saveButtonTapped() {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!this.validate()) { return; }

        let pushNotificationBody = componentBinding.getProperty("pushNotificationBody");
        let appTargetType = componentBinding.getProperty("appTargetType");
        let appTargetSelection = componentBinding.getProperty("appTargetSelection");

        if (appTargetType == Constants.NOTIFICATION_TYPE_ASSET) {
            let asset:AssetModel = await this.global.dataManager.get("getAsset", appTargetSelection);
            let assetNamePrefix = asset.assetName.split("-")[0].trim();
            console.log(assetNamePrefix);
            if (pushNotificationBody.indexOf(assetNamePrefix) == -1) {
                this.setState({errorText: "Notification message must contain asset name"});
                return;
            }

        } else if (appTargetType == Constants.NOTIFICATION_TYPE_ASSET_EVENT) {
            let assetEvent:AssetEventModel = await this.global.dataManager.get("getAssetEvent", appTargetSelection);
            if (pushNotificationBody.indexOf(assetEvent.assetEventTitle) == -1) {
                this.setState({errorText: "Notification message must contain event name"});
                return;
            }

        } else if (appTargetType == Constants.NOTIFICATION_TYPE_ITINERARY) {
            let itinerary:Itinerary = await this.global.dataManager.get("getItinerary", appTargetSelection);
            if (pushNotificationBody.indexOf(itinerary.itineraryName) == -1) {
                this.setState({errorText: "Notification message must contain itinerary name"});
                return;
            }
        }

        // check to make sure there are no other notifications scheduled on the same day
        let notifications = [];
        if (this.global.user.admin) {
            notifications = await this.global.dataManager.get("getNotificationHistory", componentBinding.getProperty("accountId"));
        } else {
            notifications = await this.global.dataManager.get("getNotificationHistory", this.global.account.id);
        }
        let tooManyNotifcationScheduledPerDay = await this.tooManyNotificationsScheduledPerDay(notifications);
        if (tooManyNotifcationScheduledPerDay) return;
        if (this.containsSameRecentAsset(notifications)) return;
        if (this.containsSimilarNotificationBody(notifications)) return;

        // TODO: If they select a custom photo make sure that was has been specified
        let photoPreference = componentBinding.getProperty("photoPreference");
        let customPhoto = componentBinding.getProperty("customPhoto");
        if (photoPreference == "custom" && StringUtil.isNullOrEmpty(customPhoto)) {
            this.setState({errorText: "You must upload a custom photo"});
            return;
        }

        //let isNew = false;
        if (!componentBinding.getProperty("id")) {
            componentBinding.setProperty("id", StringUtil.uuid());
            componentBinding.setProperty("date", DateUtil.nowFormat());
            componentBinding.setProperty("numberOfDevices", 0);
            componentBinding.setProperty("numberOpened", 0);
            componentBinding.setProperty("notificationCenterOpened", 0);
            componentBinding.setProperty("completed", false);
            componentBinding.setProperty("inProcess", false);
            //isNew = true;
        }
        if (!this.global.user.admin) {
            componentBinding.setProperty("accountId", this.global.account.id);
        }
        if (!componentBinding.getProperty("userId")) {
            componentBinding.setProperty("userId", this.global.user.id);
        }
        componentBinding.setProperty("notificationIssues", null);

        console.log(componentBinding.item);
        console.log(componentBinding.getProperty("pushNotificationTime"));
        componentBinding.saveBinding();
        //await this.global.data.createNotificationHistory(pushNotificationHistory);

        //this.sendPushNotificationEmail(isNew);

        Route.goBack(this);
    }

    /*private async sendPushNotificationEmail(isNew:boolean):Promise<any> {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let appTargetType = componentBinding.getProperty("appTargetType");
        let appTargetSelection = componentBinding.getProperty("appTargetSelection");
        let appTargetSelectionFormatted = appTargetSelection;
        if (appTargetType == "asset") {
            //let asset = await this.dataAccess.queryFirst(Constants.ASSET_TABLE, "id", appTargetSelectionFormatted);
            let asset = await this.global.dataManager.get("getAsset", appTargetSelectionFormatted);
            appTargetSelectionFormatted = asset.assetName;
        }

        let adminEmailAddresses = await UserUtil.getAdminEmailAddresses();
        let action = isNew ? "created" : "updated";
        let subject = "Visit NC Farms Notification " + action;
        //let account = await this.dataAccess.queryFirst(Constants.ACCOUNT_TABLE, "id", notificationHistory.accountId);
        let account = await this.global.dataManager.get("getAccount", componentBinding.getProperty("accountId"));
        let message = "A notification was " + action + " by " + this.global.user.name + "  for " + account.accountName + "<br/><br/>";
        message += "<b>Notification title:</b> " + componentBinding.getProperty("pushNotificationTitle") + "<br/>";
        message += "<b>Notification body:</b> " + componentBinding.getProperty("pushNotificationBody") + "<br/>";
        message += "<b>Scheduled date:</b> " + DateUtil.format(componentBinding.getProperty("pushNotificationDate"), "MMMM DD, YYYY") + "<br/>";
        message += "<b>Scheduled time:</b> " + DateUtil.format(componentBinding.getProperty("pushNotificationTime"), "hh:mm a") + "<br/>";
        message += "<b>Target type:</b> " + componentBinding.getProperty("appTargetType") + "<br/>";
        message += "<b>Target selection:</b> " + appTargetSelectionFormatted + "<br/>";
        let assetTagList:Array<string> = [];
        let assetTags = componentBinding.getProperty("appTargetAssetTags") != null ? componentBinding.getProperty("appTargetAssetTags").split(",") : [];
        for (let assetTag of assetTags) {
            let assetTagOption = ArrayUtil.getItem(this.state.assetTagOptions, "value", assetTag);
            if (assetTagOption) {
                assetTagList.push(assetTagOption.label);
            }
        }

        message += "<b>Target asset tags:</b> " + assetTagList.join(",") + "<br/>";
        message += "<b>Target products:</b> " + (componentBinding.getProperty("appTargetProducts") || "") + "<br/>";

        UserUtil.sendEmail(adminEmailAddresses, subject, message);
    }*/

    validate():boolean {
        let success = true;
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) {
            this.setState({errorText:"Please fill out all fields"});
            return false;
        } else if (this.containsEmail()) {
            this.setState({errorText:"Notification message cannot contain an email address"});
            //this.props.base.notify("Notification message cannot contain an email address");
            return false;
        } else if (this.containsWebsite()) {
            this.setState({errorText:"Notification message cannot contain a website"});
            //this.props.base.notify("Notification message cannot contain a website");
            return false;
        }

        let STAGE = process.env.REACT_APP_STAGE;
        let ignoreDateConstraints = false;
        if (STAGE == "prod" || !ignoreDateConstraints) {
            let scheduledDate = this.scheduledDateComponent.current.getValue();
            let scheduledTime = this.scheduledTimeComponent.current.getValue();
            if (scheduledDate == null) {
                this.setState({errorText:"Schedule date is required"});
                //this.props.base.notify("Schedule date is required");
                success = false;
            } else if (scheduledTime == null) {
                this.setState({errorText:"Schedule time is required"});
                //this.props.base.notify("Schedule time is required");
                success = false;
            } else {
                let binding = this.state.componentFactory.getDefaultBinding();
                let notificationDate = DateUtil.format(binding.getProperty("pushNotificationDate") + " " + binding.getProperty("pushNotificationTime"), "YYYY-MM-DD HHmm");
                let hoursDiff = DateUtil.diff(notificationDate, DateUtil.nowFormat(), 'hours');

                console.log(hoursDiff);
                if (!this.global.user?.admin && hoursDiff < 24) {
                    this.setState({errorText:"Cannot schedule a push notification for less than 24 hours"});
                    //this.props.base.notify("Cannot schedule a push notification for less than 24 hours");
                    success = false;
                }
            }
        }
        return success;
    }

    public containsWebsite():boolean {
        let binding = this.state.componentFactory.getDefaultBinding();
        return new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?").test(binding.getProperty("pushNotificationBody"));
    }

    public containsEmail():boolean {
        let binding = this.state.componentFactory.getDefaultBinding();
        var re = /(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;
        return re.test(binding.getProperty("pushNotificationBody"));
    }

    public containsSameRecentAsset(notifications:Array<NotificationHistoryModel>):boolean {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let appTargetSelection = componentBinding.getProperty("appTargetSelection");
        let notificationId = componentBinding.getProperty("id");
        //let notificationDate = DateUtil.date(componentBinding.getProperty("pushNotificationDate"));
        let notificationDate = componentBinding.getProperty("pushNotificationDate")

        for (let previousNotification of notifications) {
            let previousNotificationDate = previousNotification.pushNotificationDate;
            let diffDays = DateUtil.diff(notificationDate, previousNotificationDate, 'days');
            console.log(diffDays);
            if (notificationId != previousNotification.id && previousNotification.appTargetSelection == appTargetSelection && diffDays == 0) {
                console.log(diffDays);
                this.setState({errorText: "You cannot create multiple notifications for an asset on the same day"});
                return true;
            }
        }
        return false;
        //return false;
    }

    public async tooManyNotificationsScheduledPerDay(notifications:Array<NotificationHistoryModel>):Promise<boolean> {
        /*let self = this;
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let notificationId = componentBinding.getProperty("id");
        let notificationDate = DateUtil.date(componentBinding.getProperty("pushNotificationDate"));
        return new Promise<boolean>(async function(resolve, reject) {
            let notificationCounty = await self.getCountyForAppTarget(componentBinding.item);
            for (let notification of notifications) {
                let existingNotificationDate = DateUtil.date(notification.pushNotificationDate);
                if (!self.global.user.admin && notification.id != notificationId && notificationDate.isSame(existingNotificationDate, 'day')) {
                    let differentCountiesForRegion = false;
                    if (self.global.account.enhancedRegion) {
                        let existingNotificationCounty = await self.getCountyForAppTarget(notification);
                        differentCountiesForRegion = notificationCounty != existingNotificationCounty;
                    }
                    if (!differentCountiesForRegion) {
                        self.setState({errorText: "You cannot schedule more than one push notification per day"});
                        resolve(true)
                    }
                }
            }
            resolve(false);
        });*/
        return false;
    }

    public async getCountyForAppTarget(notificationHistoryModel:NotificationHistoryModel):Promise<string> {
        let self = this;
        let asset:AssetModel = null;
        return new Promise<string>(async function(resolve, reject) {
            if (notificationHistoryModel.appTargetType == "asset") {
                asset = await self.global.dataManager.get("getAsset", notificationHistoryModel.appTargetSelection);
            } else if (notificationHistoryModel.appTargetType == "assetEvent") {
                let assetEvent = await self.global.dataManager.get("getAssetEvent", notificationHistoryModel.appTargetSelection);
                asset = await self.global.dataManager.get("getAsset", assetEvent.assetId);
            }
            resolve(asset?.assetCounty);
        })
    }

    public containsSimilarNotificationBody(notifications:Array<NotificationHistoryModel>):boolean {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let notificationId = componentBinding.getProperty("id");
        let notificationBody = componentBinding.getProperty("pushNotificationBody");
        let notificationDate = componentBinding.getProperty("pushNotificationDate");
        for (let previousNotification of notifications) {
            let previousNotificationDate = previousNotification.pushNotificationDate;
            let diff = DateUtil.diff(notificationDate, previousNotificationDate, 'months');
            let similar = this.isSimilarContent(notificationBody, previousNotification.pushNotificationBody);
            //console.log(diff + " - " + similar);
            if (notificationId != previousNotification.id && diff == 0 && similar >= .8) {
                this.setState({errorText:"Notification message is too similar to another recent notification that was created. Please make sure that messages are unique for each notification"});
                return true;
            }
        }

        return false;
    }

    public isSimilarContent(s1:string, s2:string) {
        var longer = s1;
        var shorter = s2;
        if (s1.length < s2.length) {
            longer = s2;
            shorter = s1;
        }
        var longerLength:number = longer.length;
        if (longerLength == 0) {
            return 1.0;
        }
        return (longerLength - this.editDistance(longer, shorter)) / longerLength;
    }

    private editDistance(s1:string, s2:string) {
        s1 = s1.toLowerCase();
        s2 = s2.toLowerCase();

        var costs = new Array();
        for (var i = 0; i <= s1.length; i++) {
            var lastValue = i;
            for (var j = 0; j <= s2.length; j++) {
                if (i == 0)
                    costs[j] = j;
                else {
                    if (j > 0) {
                        var newValue = costs[j - 1];
                        if (s1.charAt(i - 1) != s2.charAt(j - 1))
                            newValue = Math.min(Math.min(newValue, lastValue),
                                costs[j]) + 1;
                        costs[j - 1] = lastValue;
                        lastValue = newValue;
                    }
                }
            }
            if (i > 0)
                costs[s2.length] = lastValue;
        }
        return costs[s2.length];
    }

    public emjoiiTapped(e:any) {
        console.log(e);
        this.emojiiPopoverComponent.current.handleTouchTap(e);
    }

    public addEmoji(emoji:any) {
        console.log(emoji);
        let cf = this.state.componentFactory;
        let notificationMessage = cf.getDefaultBinding().getProperty("pushNotificationBody");
        cf.getDefaultBinding().setProperty("pushNotificationBody", (notificationMessage || "") + emoji.native);
        this.forceUpdate();
        this.emojiiPopoverComponent.current.handleRequestClose();
    }

    public isAdminType(appTargetType:string):boolean {
        return appTargetType == "assetCategory" || appTargetType == "notificationCenter" || appTargetType == "noTarget";
    }

    /*public showCountySpecific() {
        return (this.global.account ? this.state.componentFactory.renderBoxPadded(
            "Your notification will be sent to users in " +  this.global.account?.accountName + " and the " + this.global.account?.region + " region"
        ): null)
    }*/

    public renderBestPractices() {
        /*return (<Accordion style={{marginBottom:'15px'}}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>Review Push Notification Best Practices</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                    • Be specific. Why are you sending out the notification? What products, events, or seasonal activities?<br/>
                    • Be timely. Why is now the right time to let the users know about this?<br/>
                    • Personalize. Does the notification contain products, activities or other user interests (user tags).<br/>
                    • Draw users into the app. Instead of including duplicated information, create content that encourages them to open the app and learn more<br/>
                </Typography>
            </AccordionDetails>
        </Accordion>)*/
        return null;
    }

    handleDateChange(date:any) {
        //console.log(date);
        /*this.setState({ selectedDate: date }, (function(this:DateComponent) {
            if (this.props.onChange) {
                this.props.onChange(date, this.props.id);
            }
        }).bind(this));*/
    };

    public render() {
        const cf = this.state.componentFactory;
        let pushNotificationTitle = cf.getDefaultBinding().getProperty("pushNotificationTitle");
        let pushNotificationBody = cf.getDefaultBinding().getProperty("pushNotificationBody");
        let notificationIssues = cf.getDefaultBinding().getProperty("notificationIssues");
        let appTargetType = cf.getDefaultBinding().getProperty("appTargetType");
        let customPhoto = cf.getDefaultBinding().getProperty("customPhoto");
        let photoPreference = cf.getDefaultBinding().getProperty("photoPreference");

        let notificationPhotoOptions = [
            SelectItem.create("primary", "Primary Photo"),
            SelectItem.create("custom", "Custom Photo")
        ]

        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                this.state.dialogs,
                !this.global.isDataLoaded ?
                    VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                        :
                        cf.renderBoxProps({marginLeft: '90px'}, [
                            notificationIssues ?
                                <Alert style={{marginBottom: '10px'}} severity="error"><AlertTitle>You must correct the
                                    following issue before this notification can be sent</AlertTitle>{notificationIssues}
                                </Alert> : null,
                            this.state.errorText ? <Alert style={{marginBottom: '10px'}}
                                                          severity="error">{this.state.errorText}</Alert> : null,
                            VisualUtil.getHeaderDisplay("Notification Details", [
                                cf.renderButton("Save Changes", this.saveButtonTapped.bind(this)),
                                this.global.user?.admin ? cf.renderBoxProps({marginLeft: '20px'}, null) : null,
                                this.global.user?.admin ? cf.renderButton("Add Issue", DialogUtil.openFunction(this.notificationIssueDialog.current, cf.getDefaultBinding()?.item), {isFlat: true}) : null
                            ]),
                            this.renderBestPractices(),
                            cf.renderLine(),

                            this.global.user?.admin ? cf.renderBoxPadded(cf.renderSelectField("accountId", "Account", this.global.accountOptions, null, null, {required: true})) : null,
                            cf.renderBoxPadded(cf.renderTextField("pushNotificationTitle", "Notification Title (" + (pushNotificationTitle ? pushNotificationTitle.length : "0") + "/50)", this.pushNotificationTitleComponent, null, {
                                maxLength: 50,
                                required: true
                            })),
                            cf.renderBoxPadded(cf.renderHorizontalLayout([
                                        cf.renderTextField("pushNotificationBody", "Notification Message (" + (pushNotificationBody ? pushNotificationBody.length : "0") + "/160)", this.pushNotificationBodyComponent, null, {
                                            maxLength: 160,
                                            required: true
                                        }),
                                        cf.renderBox([
                                            cf.renderIconButton(BaseIcon.ICON_INSERT_EMOJI, this.emjoiiTapped.bind(this), "Add Emojii"),
                                            <PopoverComponent ref={this.emojiiPopoverComponent} title={"Add Emojii"}>
                                                <Picker onSelect={this.addEmoji.bind(this)}/>
                                            </PopoverComponent>
                                        ]),
                                    ], ['100%', null]
                                )
                            ),
                            cf.renderBoxPadded(cf.renderDateField("pushNotificationDate", "Scheduled Date", "date", this.scheduledDateComponent)),
                            cf.renderBoxPadded(cf.renderSelectField("pushNotificationTime", "Scheduled Time", Constants.NOTIFICATION_TIMES, this.scheduledTimeComponent, null)),

                            cf.renderLine(),
                            VisualUtil.getHeaderDisplay("Targeting", null),
                            cf.renderLine(),

                            cf.renderBoxPadded(cf.renderSelectField("appTargetType", "Target Type", this.state.targetTypes, this.targetAssetTagComponent, null, {required: true})),
                            this.isAdminType(appTargetType) ? cf.renderBoxPadded(cf.renderTextField("appTargetCustom", "Custom Target")) : cf.renderBoxPadded(cf.renderSelectField("appTargetSelection", "Target Asset", this.state.targetSelections, this.targetSelectionComponent, null, {required: true})),
                            cf.renderBoxPadded(cf.renderSelectField("appTargetAssetTags", "Target Asset Tags", this.state.assetTagOptions, this.targetAssetTagComponent, null, {multiple: true})),
                            cf.renderBoxPadded(cf.renderSelectField("appTargetProducts", "Target Products", this.state.productOptions, this.targetProductComponent, null, {multiple: true})),

                            cf.renderLine(),
                            VisualUtil.getHeaderDisplay("Photo", null),
                            cf.renderLine(),

                            cf.renderBoxPadded([
                                cf.renderSelectField("photoPreference", "Notification Photo", notificationPhotoOptions),
                                photoPreference == "custom" ? cf.renderBoxProps({marginTop: '20px'}, [
                                    cf.renderBox(ImageUtil.getPhoto(customPhoto, 100, true)),
                                    cf.renderBox(cf.renderUploadButton("Upload Image", "images", this.uploadPictureTapped.bind(this), null))
                                ]) : null
                            ])
                        ])
            ])
        )
    }
}

export default base(SendNotificationPage, Sidebar)