export default class AppIssueQuery {

    public static GetAppIssues = `query GetAppIssuesQuery {
        getAppIssues {
            id
            issueText
            issueType
            date
            userId
            status,
            note
        }
    }`;

    public static GetAppIssue = `query GetAppIssueQuery($id: ID) {
        getAppIssue (id:$id) {
            id
            issueText
            issueType
            date
            userId
            status
            note
        }
    }`;

    public static CreateAppIssue = `mutation CreateAppIssueMutation($input: AppIssueInput!) {
        createAppIssue(input: $input) {
            id
            issueText
            issueType
            date
            userId
            status
            note
        }
    }`;
}