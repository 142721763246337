import {ArrayUtil, P} from "oca-lib-web";
import { getGlobal } from 'reactn';
import InvokeFields from "./InvokeFields";

export default class UserUtil {

    public static hasAccount() {
        return getGlobal().isDataLoaded && (getGlobal().user.admin || getGlobal().account)
    }

    public static async getAccountForUser(user: UserModel, accounts:Array<AccountModel>): Promise<AccountModel> {
        let userAccount:AccountModel = null;
        //let accounts = await getGlobal().data.getAccounts();

        for (let account of accounts) {
            if (account.users && account.users.indexOf(user.id) > -1) {
                userAccount = account;
            }
        }

        return P.resolve(userAccount);
    }

    public static async isAssetWithinUsersCounty(item:AssetModel): Promise<boolean> {
        let user = getGlobal().user;
        if (user.admin) {
            return P.resolve(true);
        } else {
            let hasCounty = false;
            //let account = await this.getAccountForUser(user);
            let account = getGlobal().account;
            let counties = account.counties.split(",");
            for (let county of counties) {
                if (item["assetCounty"] == county) {
                    hasCounty = true;
                }
            }
            console.log(hasCounty);
            return P.resolve(hasCounty);
        }
    }

    public static async getAdminEmailAddresses():Promise<Array<string>> {
        var emailAddresses:Array<string> = [];
        var adminUsers = await getGlobal().dataManager.get("getUsers");
        adminUsers = ArrayUtil.getItems(adminUsers, "admin", true);
        for (let adminUser of adminUsers) {
            emailAddresses.push(adminUser.email);
        }
        return P.resolve(emailAddresses);
    }

    public static async sendEmail(toAddresses:Array<string>, messageSubject:string, messageBody:string) {
        let sendEmailInput:SendEmailInput = {
            toEmailAddresses: toAddresses.join(","),
            messageSubject: messageSubject,
            messageBody: messageBody,
            field: InvokeFields.sendEmail
        }

        await getGlobal().dataManager.perform("sendEmail", sendEmailInput);
    }

    public static getAppIssueTypeLabel(key:string) {
        if (key == "iosBug") {
            return "iOS App Bug";
        } else if (key == "androidBug") {
            return "Android App Bug";
        } else if (key == "websiteBug") {
            return "Admin Website Bug";
        } else if (key == "featureRequest") {
            return "Feature Request";
        }
        return "";
    }
}