import * as React from 'reactn';
import {ComponentFactory, ComponentUtil, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from 'react-ga';
import UserUtil from "../util/UserUtil";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
}

class CloseAppIssueDialog extends React.Component<IBaseDialogProps, IState> {

    public components = ComponentUtil.createRefs(1);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createAppIssue", props.param);

        this.state = {
            componentFactory:componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/closeIssueDialog');
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        componentBinding.setProperty("status", "close");

        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        this.sendAppResolutionEmail(componentBinding.item);

        callback(componentBinding.item);
    }

    async sendAppResolutionEmail(appIssue:AppIssueModel) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let adminEmailAddresses = await UserUtil.getAdminEmailAddresses();
        let messageBody = "";
        messageBody += "<b>The issue that you submitted has been updated</b><br/>";
        messageBody += componentBinding.getProperty("issueText");
        messageBody += "<br/><br/>"
        messageBody += "<i>Update from Visit NC Farms</i><br/>"
        messageBody += componentBinding.getProperty("note");
        UserUtil.sendEmail(adminEmailAddresses, "Visit NC Farms Issue Updated", messageBody);
    }

    render() {
        let cf = this.state.componentFactory;

        return (
            cf.renderBox(
                cf.renderBoxPadded([
                    cf.renderBoxPadded(cf.renderTextField("note", "Issue Resolution", this.components[1], null, {type:"textarea", multiline:true}))
                ])
            )
        )
    }
}

export default dialog(CloseAppIssueDialog)