import AWS from "../auth/aws/AWS";
import NotificationHistoryQuery from "../query/NotificationHistoryQuery";
import SponsorQuery from "../query/SponsorQuery";
import MessagingQuery from "../query/MessagingQuery";
import AssetQuery from "../query/AssetQuery";
import AWSAuth from "../auth/aws/AWSAuth";
import UserQuery from "../query/UserQuery";
import ProductQuery from "../query/ProductQuery";
import AccountQuery from "../query/AccountQuery";
import AssetImageQuery from "../query/AssetImageQuery";
import ProductGroupQuery from "../query/ProductGroupQuery";
import AssetRelationshipQuery from "../query/AssetRelationshipQuery";
import AssetTagQuery from "../query/AssetTagQuery";
import AssetTagGroupQuery from "../query/AssetTagGroupQuery";
import AssetEventQuery from "../query/AssetEventQuery";
import AppIssueQuery from "../query/AppIssueQuery";
import HistoryQuery from "../query/HistoryQuery";
import ItineraryQuery from "../query/ItineraryQuery";
import MessageHistoryQuery from "../query/MessageHistoryQuery";
import FileQuery from "../query/FileQuery";

export default class DataAccess {

    public getAccount(id:string): Promise<AccountModel> { return AWS.query(AccountQuery.GetAccount, {id: id}); }
    public getAccounts(): Promise<Array<AccountModel>> { return AWS.query(AccountQuery.GetAccounts); }
    public getAppIssue(id:string): Promise<AppIssueModel> { return AWS.query(AppIssueQuery.GetAppIssue, {id: id}); }
    public getAppIssues(): Promise<Array<AppIssueModel>> { return AWS.query(AppIssueQuery.GetAppIssues); }
    public getAsset(id:string): Promise<AssetModel> { return AWS.query(AssetQuery.GetAsset, {id: id}); }
    public getAssets(accountId:string): Promise<Array<AssetModel>> { return AWS.query(AssetQuery.GetAssets, {accountId: accountId}); }
    public getAssetsForAdmin(input:GetAssetsForAdminInput): Promise<Array<AssetModel>> { return AWS.query(AssetQuery.GetAssetsForAdmin, {input: input}); }
    public getAssetEvent(id:string): Promise<AssetEventModel> { return AWS.query(AssetEventQuery.GetAssetEvent, {id: id}); }
    public getAssetEvents(): Promise<Array<AssetEventModel>> { return AWS.query(AssetEventQuery.GetAssetEvents); }
    public getAssetEventsForAsset(assetId:string): Promise<Array<AssetEventModel>> { return AWS.query(AssetEventQuery.GetAssetEventsForAsset, {assetId: assetId}); }
    public getAssetImage(id:string): Promise<AssetImageModel> { return AWS.query(AssetImageQuery.GetAssetImage, {id: id}); }
    public getAssetRelationships(assetId:string): Promise<Array<AssetRelationshipModel>> { return AWS.query(AssetRelationshipQuery.GetAssetRelationships, {assetId: assetId}); }
    public getAssetTags(): Promise<Array<AssetTagModel>> { return AWS.query(AssetTagQuery.GetAssetTags); }
    public getAssetTagGroups(): Promise<Array<AssetTagGroupModel>> { return AWS.query(AssetTagGroupQuery.GetAssetTagGroups); }
    public getCountyFromCoordinates(item:GetCountyFromCoordinatesInput): Promise<any> { return AWS.query(AssetQuery.GetCountyFromCoordinates, {input: item})}
    public getFiles(item:FileInput): Promise<any> { return AWS.query(FileQuery.GetFiles, {input: item})}
    public getHistory(input:GetHistoryInput): Promise<Array<HistoryModel>> { return AWS.query(HistoryQuery.GetHistory, {input: input}); }
    public getItinerariesForAdmin(): Promise<Array<Itinerary>> { return AWS.query(ItineraryQuery.GetItinerariesForAdmin); }
    public getItineraries(account:string): Promise<Array<Itinerary>> { return AWS.query(ItineraryQuery.GetItineraries, {accountId:account}); }
    public getItinerary(id:string): Promise<Itinerary> { return AWS.query(ItineraryQuery.GetItinerary, {id:id}); }
    public getMessageStatistics(input:GetMessageStatisticsInput): Promise<Array<MessageStatistics>> { return AWS.query(MessagingQuery.GetMessageStatistics, {input: input}); }
    public getProducts(): Promise<Array<ProductModel>> { return AWS.query(ProductQuery.GetProducts); }
    public getProductGroups(): Promise<Array<ProductGroupModel>> { return AWS.query(ProductGroupQuery.GetProductGroups); }
    public getMessageHistory(accountId:string): Promise<Array<MessageHistory>> { return AWS.query(MessageHistoryQuery.GetMessageHistory, {accountId: accountId}); }
    public getMessageHistoryForAdmin(): Promise<Array<MessageHistory>> { return AWS.query(MessageHistoryQuery.GetMessageHistoryForAdmin); }
    public getNotificationHistory(accountId:string): Promise<Array<NotificationHistoryModel>> { return AWS.query(NotificationHistoryQuery.GetNotificationHistory, {accountId: accountId}); }
    //public getNotificationHistoryForAdmin(): Promise<Array<NotificationHistoryModel>> { return AWS.query(NotificationHistoryQuery.GetNotificationHistoryForAdmin); }
    public getSponsors(accountId:string): Promise<Array<SponsorModel>> { return AWS.query(SponsorQuery.GetSponsors, {accountId: accountId}); }
    public getSponsorsForAdmin(): Promise<Array<SponsorModel>> { return AWS.query(SponsorQuery.GetSponsorsForAdmin); }
    public getUsers(): Promise<Array<UserModel>> { return AWS.query(UserQuery.GetUsers); }
    public getUser(id: string): Promise<UserModel> { return AWS.query(UserQuery.GetUser, {id: id}); }
    public async getCurrentUser(): Promise<UserModel> {
        let user:any = await AWSAuth.getCurrentAuthenticatedUser();
        return this.getUser(user.username);
    }

    public createAccount(item: AccountInput): Promise<AccountModel> { return AWS.query(AccountQuery.CreateAccount, {input: item}); }
    public createAppIssue(item: AppIssueInput): Promise<string> { return AWS.query(AppIssueQuery.CreateAppIssue, {input: item}); }
    public createAssetBatch(item: any): Promise<string> { return AWS.query(AssetQuery.CreateAssets, {input: item}); }
    public createAsset(item: any): Promise<string> { return AWS.query(AssetQuery.CreateAsset, {input: item}); }
    public createAssetEvent(item: any): Promise<string> { return AWS.query(AssetEventQuery.CreateAssetEvent, {input: item}); }
    public createAssetImage(item: any): Promise<string> { return AWS.query(AssetImageQuery.CreateAssetImage, {input: item}); }
    public createAssetRelationship(item: any): Promise<string> { return AWS.query(AssetRelationshipQuery.CreateAssetRelationship, {input: item}); }
    public createAssetTag(item: any): Promise<AssetTagModel> { return AWS.query(AssetTagQuery.CreateAssetTag, {input: item}); }
    public createAssetTagGroup(item: any): Promise<AssetTagGroupModel> { return AWS.query(AssetTagGroupQuery.CreateAssetTagGroup, {input: item}); }
    public createFile(item: FileInput): Promise<FileModel> { return AWS.query(FileQuery.CreateFile, {input: item}); }
    public createHistory(item: any): Promise<string> { return AWS.query(HistoryQuery.CreateHistory, {input: item}); }
    public createItinerary(item: any): Promise<string> { return AWS.query(ItineraryQuery.CreateItinerary, {input: item}); }
    public createMessageHistory(item: MessageHistory): Promise<string> { return AWS.query(MessageHistoryQuery.CreateMessageHistory, {input: item}); }
    public createNotificationHistory(item: NotificationHistoryInput): Promise<string> { return AWS.query(NotificationHistoryQuery.CreateNotificationHistory, {input: item}); }
    public createProduct(item: ProductModel): Promise<string> { return AWS.query(ProductQuery.CreateProduct, {input: item}); }
    public createProductGroup(item: ProductGroupModel): Promise<string> { return AWS.query(ProductGroupQuery.CreateProductGroup, {input: item}); }
    public createSponsor(item: SponsorModel): Promise<string> { return AWS.query(SponsorQuery.CreateSponsor, {input: item}); }
    public createUser(item: UserModel): Promise<string> { return AWS.query(UserQuery.CreateUser, {input: item}); }

    public deleteAccount(item: AssetModel): Promise<string> { return AWS.query(AccountQuery.DeleteAccount, {id: item.id}); }
    public deleteAsset(item: AssetModel): Promise<string> { return AWS.query(AssetQuery.DeleteAsset, {id: item.id}); }
    public deleteAssetEvent(item: AssetEventModel): Promise<string> { return AWS.query(AssetEventQuery.DeleteAssetEvent, {id: item.id}); }
    public deleteAssetImage(item: AssetImageModel): Promise<string> { return AWS.query(AssetImageQuery.DeleteAssetImage, {id: item.id}); }
    public deleteAssetRelationship(item: AssetRelationshipModel): Promise<string> { return AWS.query(AssetRelationshipQuery.DeleteAssetRelationship, {id: item.id}); }
    public deleteAssetTag(item: AssetTagModel): Promise<string> { return AWS.query(AssetTagQuery.DeleteAssetTag, {id: item.id}); }
    public deleteAssetTagGroup(item: AssetTagGroupModel): Promise<string> { return AWS.query(AssetTagGroupQuery.DeleteAssetTagGroup, {id: item.id}); }
    public deleteFile(item: FileModel): Promise<FileModel> { return AWS.query(FileQuery.DeleteFile, {id: item.id}); }
    public deleteItinerary(item: Itinerary): Promise<string> { return AWS.query(ItineraryQuery.DeleteItinerary, {id: item.id}); }
    public deleteProduct(item: ProductModel): Promise<string> { return AWS.query(ProductQuery.DeleteProduct, {id: item.id}); }
    public deleteProductGroup(item: ProductGroupModel): Promise<string> { return AWS.query(ProductGroupQuery.DeleteProductGroup, {id: item.id}); }
    public deleteMessageHistory(item: MessageHistory): Promise<string> { return AWS.query(MessageHistoryQuery.DeleteMessageHistory, {id: item.id}); }
    public deleteNotificationHistory(item: NotificationHistoryModel): Promise<string> { return AWS.query(NotificationHistoryQuery.DeleteNotificationHistory, {id: item.id}); }
    public deleteSponsor(item: SponsorModel): Promise<string> { return AWS.query(SponsorQuery.DeleteSponsor, {id: item.id}); }
    public deleteUser(item: UserModel): Promise<string> { return AWS.query(UserQuery.DeleteUser, {id: item.id}); }
    public deleteCognitoUser(item: DeleteCognitoUserInput): Promise<string> { return AWS.query(UserQuery.DeleteCognitoUser, {input: item}); }

    public sendPushNotifications(item: SendPushNotificationInput): Promise<string> { return AWS.query(MessagingQuery.SendPushNotifications, {input: item}); }
    public sendEmail(item: SendEmailInput): Promise<string> { return AWS.query(MessagingQuery.SendEmail, {input: item}); }
    public emailAdmins(item: EmailAdminsInput): Promise<string> { return AWS.query(MessagingQuery.EmailAdmins, {input: item}); }
    public emailFarmAssets(item: any): Promise<string> { return AWS.query(MessagingQuery.EmailFarmAssets, {input: item}); }
    public isEmailAddressVerified(item: VerifyEmailAddressInput): Promise<string> { return AWS.query(MessagingQuery.IsEmailAddressVerified, {input: item}); }
    public verifyEmailAddress(item: VerifyEmailAddressInput): Promise<string> { return AWS.query(MessagingQuery.VerifyEmailAddress, {input: item}); }
}