import * as React from 'reactn';
import { addCallback } from 'reactn';
import {
    base,
    Route,
    IconButtonComponent,
    DialogUtil,
    ArrayUtil,
    BaseColor,
    BaseIcon,
    IBaseProps,
    SelectComponent,
    LocalCache, MenuButtonComponent, ButtonComponent, SelectItem, ComponentFactory, TextComponent, DateUtil
} from "oca-lib-web";
import {createRef} from "react";
import VerifyEmailAddressDialog from "../component/VerifyEmailAddressDialog";
import InvokeFields from "../util/InvokeFields";
import Sidebar from "../component/Sidebar";
import Constants from "../util/Constants";
//import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import ImportExportUtil from "../util/ImportExportUtil";
import AddAssetDialog from "../component/AddAssetDialog";
import AppIssueDialog from "../component/AppIssueDialog";
import AssetUtil from "../util/AssetUtil";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";
import StringUtil from "../util/StringUtil";

interface IState {
    componentFactory:ComponentFactory,
    dialogs:Array<any>,
    type?:string,
    //productOptions:Array<SelectItem>,
    //assetTagOptions:Array<SelectItem>,
    rows:Array<AssetModel>,
    activeAssets:number,
    selectedAccount?:AccountModel,
    selectedAssetCategory?:string,
    selectedAssetTag?:string,
    selectedProduct?:string,
    selectedStatus?:string,
    selectedCounty?:string,
    accountMissing:boolean,
    searchTerm?:string
}

class ListPage extends React.Component<IBaseProps, IState> {

    public appIssueDialog = createRef<any>();
    public addAssetDialog = createRef<any>();
    public verifyEmailAddressDialog = createRef<any>();
    public component = new ComponentFactory(Styles.default);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        let dialogs = [
            <AppIssueDialog {...componentFactory.renderDialogProps("Add Issue", "Save", this.submitAppIssueCallback.bind(this), this.appIssueDialog)} />,
            <AddAssetDialog {...componentFactory.renderDialogProps("Add New Asset", "Save", this.addAssetCallback.bind(this), this.addAssetDialog)} />,
            <VerifyEmailAddressDialog {...componentFactory.renderDialogProps("Verify Email Address", "Verify", this.verifyEmailAddressCallback.bind(this), this.verifyEmailAddressDialog)} />
        ]

        this.state = {
            componentFactory:componentFactory,
            dialogs:dialogs,
            //productOptions: [],
            //assetTagOptions: [],
            rows: [],
            activeAssets: 0,
            selectedAssetCategory: null,
            selectedCounty: null,
            selectedProduct: null,
            selectedAssetTag: null,
            selectedStatus: null,
            //accountSelections: [],
            accountMissing: false
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.loadSelectedAccountAndGetItems();
            return null;
        });

        if (this.global.isDataLoaded) {
            this.loadSelectedAccountAndGetItems();
        }
    }

    loadSelectedAccountAndGetItems() {
        //let cf = this.state.componentFactory;
        const selectedAccountId = LocalCache.getSession("selectedAccount");
        const selectedAssetCategory = LocalCache.getSession("selectedAssetCategory");
        const selectedCounty = LocalCache.getSession("selectedCounty");
        const selectedProduct = LocalCache.getSession("selectedProduct");
        const selectedAssetTag = LocalCache.getSession("selectedAssetTag");
        const selectedStatus = LocalCache.getSession("selectedStatus");
        let selectedAccount;
        console.log(selectedAccountId);
        if (selectedAccountId && selectedAccountId != "") {
            console.log("setting selected account")
            //selectedAccount = await this.global.dataManager.get("getAccount", selectedAccountId);
            selectedAccount = ArrayUtil.getItem(this.global.accounts, "id", selectedAccountId);
        }
        /*let products = await this.global.dataManager.get("getProducts");
        ArrayUtil.sort(products, "productName");
        let assetTags = await this.global.dataManager.get("getAssetTags");
        ArrayUtil.sort(assetTags, "assetTagName");
        let productOptions = [SelectItem.create("", "All Products")];
        for (let product of products) {
            productOptions.push(SelectItem.create(product.productName.toLowerCase(), product.productName))
        }
        let assetTagOptions = [SelectItem.create("", "All Asset Tags"), ...cf.createOptions(assetTags, "id", "assetTagName")];*/
        this.setState({selectedAccount:selectedAccount, selectedAssetCategory:selectedAssetCategory,
            selectedCounty:selectedCounty, selectedProduct:selectedProduct, selectedAssetTag:selectedAssetTag,
            selectedStatus:selectedStatus}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    /*
     * Fetch / Filters
     */

    async getItems() {
        let accountMissing = !this.global.account && !this.global.user?.admin;
        this.setState({accountMissing:accountMissing});

        if (!this.global.user || (!this.global.user.admin && !this.global.account)) { return; }

        let rows:Array<AssetModel> = [];
        if (this.global.user.admin && this.state.selectedAccount) {
            //rows = await this.global.data.getAssets(this.state.selectedAccount.id);
            rows = await this.global.dataManager.get("getAssets", this.state.selectedAccount.id);
        } else if (this.global.user.admin) {
            rows = await this.global.dataManager.get("getAssetsForAdmin", {field:InvokeFields.getAssetsForAdmin});
        } else {
            rows = await this.global.dataManager.get("getAssets", this.global.account.id);
            //rows = await this.global.data.getAssets(this.global.account.id);
        }
        //let rows = this.state.user.admin ? await DataAccess.getAssetsForAdmin() : await DataAccess.getAssets(account.id);
        rows = this.applyAssetCategory(rows);
        rows = this.applyAssetTags(rows);
        rows = this.applyProducts(rows);
        rows = this.applySearch(rows);
        rows = this.applyCounty(rows);
        rows = this.applyStatus(rows);
        let activeAssets = ArrayUtil.getItems(rows, "isActive", true);
        ArrayUtil.sort(rows, "assetName");
        console.log("Getting assets " + rows.length);

        this.setState({rows: rows, activeAssets: activeAssets.length});
    }

    applySearch(rows:Array<AssetModel>): Array<AssetModel> {
        if (!this.state.searchTerm) { return rows; }

        let filteredRows = [];
        for (let row of rows) {
            if (row.assetName.toLowerCase().indexOf(this.state.searchTerm.toLowerCase()) > -1) {
                filteredRows.push(row);
            }
        }
        return filteredRows;
    }

    applyAssetCategory(rows:Array<AssetModel>): Array<AssetModel> {
        if (!this.state.selectedAssetCategory) { return rows; }

        let filteredRows = [];
        for (let row of rows) {
            let assetCategories = row.assetCategories ? row.assetCategories.split(",") : [];
            if (assetCategories.includes(this.state.selectedAssetCategory)) {
                filteredRows.push(row);
            }
        }
        return filteredRows;
    }

    applyCounty(rows:Array<AssetModel>): Array<AssetModel> {
        if (!this.state.selectedCounty) { return rows; }
        return ArrayUtil.getItems(rows, "assetCounty", this.state.selectedCounty);
    }

    applyProducts(rows:Array<AssetModel>): Array<AssetModel> {
        if (!this.state.selectedProduct) { return rows; }
        //console.log(this.state.selectedProduct);

        let rowsWithProducts = [];
        for (let row of rows) {
            let rowProducts = row.assetProducts?.split(",");
            //console.log(rowProducts);
            if (ArrayUtil.contains(rowProducts, this.state.selectedProduct)) {
                rowsWithProducts.push(row);
            }
        }
        return rowsWithProducts;
    }

    applyAssetTags(rows:Array<AssetModel>): Array<AssetModel> {
        if (!this.state.selectedAssetTag) { return rows; }

        let rowsWithAssetTags = [];
        for (let row of rows) {
            let rowAssetTags = row.assetTags?.split(",");
            if (ArrayUtil.contains(rowAssetTags, this.state.selectedAssetTag)) {
                rowsWithAssetTags.push(row);
            }
        }
        return rowsWithAssetTags;
    }

    applyStatus(rows:Array<AssetModel>): Array<AssetModel> {
        if (!this.state.selectedStatus) { return rows; }

        let rowsWithStatus = [];
        let status = this.state.selectedStatus === "active" ? true : false;
        for (let row of rows) {
            if (row.isActive == status) {
                rowsWithStatus.push(row);
            }
        }
        return rowsWithStatus;
    }

    async importData(e:any) {
        var self = this;
        console.log("starting import...");
        if (!this.state.selectedAccount) {
            this.props.base.notify("Please select an account before importing");
            return;
        } else if (!e.nativeEvent.target || !e.nativeEvent.target.files) {
            this.props.base.notify("Something went wrong. Please reselect the file");
            return;
        }
        const file = e.nativeEvent.target.files[0];

        this.props.base.wait();

        await ImportExportUtil.importData(file, self.state.selectedAccount.id);

        self.props.base.done();
        await self.getItems();
        self.props.base.notify("Successfully imported assets");

    }

    async submitAppIssueCallback(item:any) {
        this.props.base.notify("Thank you! The NC Farms app team will look into your issue and respond to you soon");
    }

    async addAssetCallback(item:any) {
        console.log(item);
        this.loadSelectedAccountAndGetItems();
        this.props.base.notify("Asset created");
    }

    async deleteItemTapped(item:any) {
        await this.global.dataManager.delete("deleteAsset", item);
        this.getItems();

        this.props.base.notify("Asset deleted");
    }

    async selectedAccountChanged(value:any) {
        console.log("setting selected account: " + value)
        LocalCache.saveSession("selectedAccount", value);
        let selectedAccount;
        if (value !== "") {
            selectedAccount = await this.global.dataManager.get("getAccount", value);
        }
        this.setState({selectedAccount: selectedAccount, selectedCounty:null}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    selectedAssetCategoryChanged(value:any) {
        LocalCache.saveSession("selectedAssetCategory", value);
        console.log(value);
        this.setState({selectedAssetCategory: value}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    selectedAssetTagChanged(value:any) {
        LocalCache.saveSession("selectedAssetTag", value);
        this.setState({selectedAssetTag: value}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    selectedProductChanged(value:any) {
        LocalCache.saveSession("selectedProduct", value);
        this.setState({selectedProduct: value}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    selectedStatusChanged(value:any) {
        LocalCache.saveSession("selectedStatus", value);
        this.setState({selectedStatus: value}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    selectedCountyChanged(value:any) {
        LocalCache.saveSession("selectedCounty", value);
        this.setState({selectedCounty: value}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    resetAllFilters() {
        LocalCache.removeSession("selectedAccount");
        LocalCache.removeSession("selectedAssetCategory");
        LocalCache.removeSession("selectedAssetTag");
        LocalCache.removeSession("selectedProduct");
        LocalCache.removeSession("selectedCounty");
        LocalCache.removeSession("selectedStatus");
        this.setState({selectedAccount:null, selectedAssetCategory:null, selectedAssetTag:null, selectedProduct:null, selectedCounty:null, selectedStatus:null}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    areFiltersSet() {
        return this.state.selectedAccount != null || this.state.selectedAssetCategory != null || this.state.selectedAssetTag != null ||
            this.state.selectedProduct != null || this.state.selectedCounty != null || this.state.selectedStatus != null;
    }

    onSearchChanged(value:any, key:string) {
        this.setState({searchTerm: value}, function(this:ListPage) {
            this.getItems();
        }.bind(this));
    }

    onSearchUpdated(value:string) {
        this.getItems();
    }

    async openSendMessageDialog() {
        var assetIdList:Array<string> = [];
        for (let row of this.state.rows) {
            //if (row.assetEmailAddress) {
            if (AssetUtil.hasValidContactInfo(row)) {
                assetIdList.push(row.id);
            }
        }
        console.log("sending email for " + assetIdList.length + " assets");
        LocalCache.saveSession("ids", assetIdList.join(","));
        Route.go(this, 'sendMessage/asset');

        /*let input:VerifyEmailAddressInput = {
            emailAddress: this.global.user.email,
            field: InvokeFields.isEmailAddressVerified
        };
        let isVerified = await this.global.dataManager.perform("isEmailAddressVerified", input);
        if (isVerified["success"] == "true") {
            //DialogUtil.open(this.sendMessageDialog.current)
            var assetIdList:Array<string> = [];
            for (let row of this.state.rows) {
                //if (row.assetEmailAddress) {
                if (AssetUtil.hasValidContactInfo(row)) {
                    assetIdList.push(row.id);
                }
            }
            console.log("sending email for " + assetIdList.length + " assets");
            LocalCache.saveSession("ids", assetIdList.join(","));
            Route.go(this, 'sendMessage/asset');
        } else {
            DialogUtil.open(this.verifyEmailAddressDialog.current)
        }*/
    }

    verifyEmailAddressCallback(item:any) {
        console.log("verify email adddress");
        this.props.base.notify("An email has been sent to you. Please check your inbox for more instructions.");
    }

    sendMessageCallback(item:any) {
        this.props.base.notify("Email sent");
    }

    getRow(item:AssetModel) {
        var self = this;
        /*const actionLink = <Box>
            <IconButtonComponent icon={BaseIcon.ICON_EDIT} tooltip="Edit" color="primary" onTouchTap={Route.goFunction(self, 'item/' + item.id)} />
        </Box>*/
        let editFunction = Route.goFunction(self, 'item/' + item.id);
        let actions = this.state.componentFactory.renderEditDeleteButtons(editFunction);

        var row = [
            VisualUtil.getNameAvatarDisplay(item.primaryImage, item.assetName),
            item.assetAddress,
            StringUtil.capitalizeFirstLetter(item.assetCounty),
            item.isActive ? <span className="active">ACTIVE</span> : <span className="inactive">INACTIVE</span>,
            item.lastUpdated ? <span style={{fontStyle:'italic'}}>{DateUtil.format(item.lastUpdated, "MMMM DD, YYYY")}</span> : null,
            actions
        ];
        if (this.global.user?.admin) {
            let accountItem = ArrayUtil.getItem(this.global.accountOptions, "value", item.accountId)
            row.splice(4,0,accountItem?.label);
        }

        return row;
    }

    async overflowMenuTapped(value:number, e:any) {
        if (value === 0) {
            this.openSendMessageDialog();
        } else if (value === 1) {
            ImportExportUtil.exportAssets(this.state.rows);
        } else if (value === 2) {
            DialogUtil.open(this.appIssueDialog.current);
        }
    }

    render() {
        let cf = this.state.componentFactory;
        var headers = ["Primary Photo / Name", "Address", "County", "Active", "Last Updated", "Actions"];
        if (this.global.user?.admin) {
            headers.splice(4,0,"Account");
        }

        var self = this;
        var label = "New Asset";
        var menuItems = ["Contact Assets", "Export Assets", "Submit Issue"];
        var headerRowContent2 = [
            <ButtonComponent  primary={true} title={label} onTouchTap={async () => {
                let canAdd = false;
                if (!self.global.user) {
                    canAdd = false;
                } else if (self.global.user.admin) {
                    canAdd = true;
                } else if (self.global.account) {
                    //let county = await DataAccess.getCounty(self.state.user.county);
                    canAdd = self.state.activeAssets < self.global.account.assetLicense;
                }
                if (canAdd) {
                    //Route.go(self, 'item');
                    DialogUtil.open(this.addAssetDialog.current);
                } else {
                    self.props.base.notify("You have reached the max number of assets for your license");
                }
            }} />,
            <MenuButtonComponent menuItemLabels={menuItems} onTouchTap={this.overflowMenuTapped.bind(this)}>
                <IconButtonComponent icon={BaseIcon.ICON_MORE_VERT} tooltip="More Actions" color="primary" onTouchTap={() => {}} />
            </MenuButtonComponent>
        ]
        if (this.global.user?.admin) {
            headerRowContent2.splice(1, 0, <ButtonComponent primary title="Import" secondary style={{marginLeft:'20px'}} isFlat startIcon={<SaveAltIcon/>} uploadAccept=".csv" forUpload={true} onTouchTap={this.importData.bind(this)} />)
        }

        //var liveControl:any = null;
        var headerRowLeftContent:any[] = [];
        headerRowLeftContent.push(<TextComponent size="small" type="text" variant="outlined" adornment={BaseIcon.ICON_SEARCH} placeholder="Search assets" value={this.state.searchTerm} onChange={this.onSearchChanged.bind(this)} />)

        let assetCategoryOptions:Array<SelectItem> = [SelectItem.create("", "All Asset Categories")];
        assetCategoryOptions.push(...Constants.ASSET_GROUP_CATEGORIES);
        //assetCategoryOptions.splice(0,0, SelectItem.create("", "All Asset Categories"));
        headerRowLeftContent.push(<SelectComponent
            id="assetCategories"
            inputType="bootstrap"
            variant="outlined"
            style={{marginLeft:'20px'}}
            value={this.state.selectedAssetCategory || ""}
            options={assetCategoryOptions}
            onChange={this.selectedAssetCategoryChanged.bind(this)} />);

        headerRowLeftContent.push(<SelectComponent
            id="assetTags"
            inputType="bootstrap"
            variant="outlined"
            style={{marginLeft:'20px'}}
            value={this.state.selectedAssetTag || ""}
            options={this.global.assetTagOptions}
            onChange={this.selectedAssetTagChanged.bind(this)} />);

        headerRowLeftContent.push(<SelectComponent
            id="productTags"
            inputType="bootstrap"
            variant="outlined"
            style={{marginLeft:'20px'}}
            value={this.state.selectedProduct || ""}
            options={this.global.productOptions}
            onChange={this.selectedProductChanged.bind(this)} />);

        headerRowLeftContent.push(<SelectComponent
            id="statuses"
            inputType="bootstrap"
            variant="outlined"
            style={{marginLeft:'20px'}}
            value={this.state.selectedStatus || ""}
            options={SelectItem.fromArray([{id:"", label:"All Statuses"}, {id:"active", label:"Active"}, {id:"inactive", label:"Inactive"}], "id", "label")}
            onChange={this.selectedStatusChanged.bind(this)} />);

        //headerRowLeftContent.push(cf.renderSelectField("productOptions", null, this.state.productOptions, null, null, {inputType:'bootstrap', multiple:true, width:200}));
        //headerRowLeftContent.push(cf.renderSelectField("assetTagOptions", null, this.state.assetTagOptions, null, null, {inputType:"bootstrap"}));

        let counties:Array<string> = [];
        if (this.state.selectedAccount?.counties) {
            counties = this.state.selectedAccount?.counties.split(",");
        } else if (this.global.account) {
            counties = this.global.account.counties?.split(",");
        }
        if (counties && counties.length > 0) {
            ArrayUtil.sort(counties);
        }

        if (this.global.user && this.global.user.admin) {
            headerRowLeftContent.push(<SelectComponent
                id="accounts"
                inputType="bootstrap"
                variant="outlined"
                style={{marginLeft:'20px'}}
                value={this.state.selectedAccount ? this.state.selectedAccount.id : ""}
                options={this.global.accountOptions}
                onChange={this.selectedAccountChanged.bind(this)} />)
        }

        // TODO: Remove this enhanced region
        //let enhancedRegion = this.global.account?.enhancedRegion;
        //let enhancedRegion = false
        if (counties && counties.length > 1) {
            let countyOptions:Array<SelectItem> = [];
            for (let county of counties) {
                countyOptions.push(SelectItem.create(county, StringUtil.capitalizeFirstLetter(county)));
            }
            countyOptions.splice(0, 0, SelectItem.create("", "All Counties"));
            headerRowLeftContent.push(<SelectComponent
                id="counties"
                inputType="bootstrap"
                variant="outlined"
                style={{marginLeft:'20px'}}
                value={this.state.selectedCounty || ""}
                options={countyOptions}
                onChange={this.selectedCountyChanged.bind(this)}/>);
        }

        if (this.areFiltersSet()) {
            headerRowLeftContent.push([<span style={{marginLeft:'20px'}}>&nbsp;</span>, cf.renderButton("Reset Filters", this.resetAllFilters.bind(this), {isFlat: true})]);
        }

        let licenseText = !self.global.user?.admin ? "Using " + this.state.activeAssets + " out of " + this.global.account?.assetLicense + " assets available" : null;

        return (
            cf.renderBoxProps({fullHeight:true, background:BaseColor.WHITE},[
                this.state.dialogs,
                cf.renderBoxProps({marginLeft:'90px'},
                    !this.global.isDataLoaded ?
                        VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                        :
                        [VisualUtil.getHeaderDisplay(this.global.account ? this.global.account.accountName : null, headerRowContent2),
                            cf.renderLine(),
                            cf.renderHorizontalLayout([cf.renderBoxProps({marginLeft:'20px', marginRight:'20px', marginTop:'20px', marginBottom:'20px'}, headerRowLeftContent)], ['100%']),
                            cf.renderTable(headers, this.state.rows, this.getRow.bind(this), {fitToContent:true, bottomDetailText:licenseText})
                        ]
                )
            ])
        )
    }
}

export default base(ListPage, Sidebar)