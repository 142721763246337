export default class ProductGroupQuery {

    public static GetProductGroups = `query GetProductGroupQuery {
        getProductGroups {
            id
            productGroupName
        }
    }`;

    public static CreateProductGroup = `mutation CreateProductGroupMutation($input: ProductGroupInput!) {
        createProductGroup(input: $input) {
            id
            productGroupName
        }
    }`;

    public static DeleteProductGroup = `mutation DeleteProductGroupMutation($id: ID!) {
        deleteProductGroup(id: $id) {
            id
        }
    }`;
}