export default class MessageHistoryQuery {

    public static GetMessageHistory = `query GetMessageHistoryQuery($accountId: String) {
        getMessageHistory (accountId:$accountId) {
            id
            messageSubject
            messageBody
            messageType
            messageScheduledDate
            messageScheduledTime
            assetIds
            date
            userId
            accountId
        }      
    }`;

    public static GetMessageHistoryForAdmin = `query GetMessageHistoryForAdminQuery {
        getMessageHistoryForAdmin {
            id
            messageSubject
            messageBody
            messageType
            messageScheduledDate
            messageScheduledTime
            assetIds
            date
            userId
            accountId
        }      
    }`;

    public static CreateMessageHistory = `mutation CreateMessageHistoryQuery($input: MessageHistoryInput!) {
        createMessageHistory(input: $input) {
            id
            messageSubject
            messageBody
            messageType
            assetIds
            date
            userId
            accountId
        }
    }`;

    public static DeleteMessageHistory = `mutation DeleteMessageHistoryQuery($id: ID!) {
        deleteMessageHistory(id: $id) {
            id
        }
    }`;
}