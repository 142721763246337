import * as React from 'reactn';
import {ArrayUtil, ComponentFactory, dialog, IBaseDialogProps, SelectItem} from "oca-lib-web";
import ReactGA from 'react-ga';
import AssetUtil from "../util/AssetUtil";
import {Checkbox} from "@mui/material";
import Styles from "../style/Styles";
import {getGlobal} from "reactn";

export enum SelectionType {
    Asset,
    AssetEvent,
    User
}

interface IState {
    componentFactory:ComponentFactory,
    items:Array<SelectItem>,
    selectedItems:Array<any>,
    type?:SelectionType
}

class AssetSelectionDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, null, null);

        this.state = {
            componentFactory:componentFactory,
            items: [],
            selectedItems: []
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/assetSelectionDialog');

        const type = this.props.param.type;
        const selectedIds = this.props.param.selected;
        //const accountId = this.props.param.accountId;
        const id = this.props.param.id;
        let selectedItems = [];
        let items = [];

        items = await this.loadSelections(type, id);

        if (selectedIds) {
            for (let selectedId of selectedIds) {
                for (let item of items) {
                    if (selectedId == item.id) {
                        selectedItems.push(item);
                    }
                }
            }
        }
        this.setState({items:items, type:type, selectedItems:selectedItems});
    }

    async loadSelections(type:SelectionType, id?:string):Promise<Array<any>> {
        return new Promise(async (resolve) => {
            let items = [];
            if (type === SelectionType.Asset) {
                if (getGlobal().user.admin || !id) {
                    items = await AssetUtil.getAssetList();
                } else if (id) {
                    items = await this.global.dataManager.get("getAssets", id);
                    items = ArrayUtil.getItems(items, "active", true);
                    ArrayUtil.sort(items, "assetName");
                }

            } else if (type === SelectionType.AssetEvent) {
                items = await this.global.dataManager.get("getAssetEventsForAsset", id);
                ArrayUtil.sort(items, "assetEventTitle");

            } else if (type === SelectionType.User) {
                items = await this.global.dataManager.get("getUsers");
                ArrayUtil.sort(items, "name");
            }
            resolve(items);
        });
    }

    async handleSave(callback:any) {
        callback(this.state.selectedItems);
    }

    onRowSelection(item:any, data:AssetModel) {
        let selectedItems = this.state.selectedItems;
        let found = false;
        for (let selectedItem of selectedItems) {
            if (data.id == selectedItem.id) {
                found = true;
            }
        }
        if (found) {
            ArrayUtil.deleteItemByKey(data, "id", selectedItems);
        } else if (this.state.type == SelectionType.AssetEvent) {
            selectedItems = [data];
        } else {
            selectedItems.push(data);
        }
        console.log(selectedItems);
        this.setState({selectedItems:selectedItems})
    }

    isRowSelected(item:AssetModel):boolean {
        let selectedItems = this.state.selectedItems;
        for (let selectedItem of selectedItems) {
            if (item.id === selectedItem.id) return true;
        }
        return false;
    }

    getHeaders() {
        if (this.state.type === SelectionType.Asset) {
            return ["Selection", "Asset Name"];
        } else if (this.state.type === SelectionType.AssetEvent) {
            return ["Selection", "Event Name"];
        } else if (this.state.type === SelectionType.User) {
            return ["Selection", "User Name"];
        }
        return [];
    }

    // Render Row

    renderRow(item:any) {
        if (this.state.type === SelectionType.Asset) {
            return this.renderAssetRow(item);
        } else if (this.state.type === SelectionType.AssetEvent) {
            return this.renderAssetEventRow(item);
        } else if (this.state.type === SelectionType.User) {
            return this.renderUserRow(item);
        }
        return [];
    }

    renderAssetRow(item:AssetModel) {
        let row = [
            <Checkbox color="primary" checked={this.isRowSelected(item)}/>,
            item.assetName
        ];
        return row;
    }

    renderAssetEventRow(item:AssetEventModel) {
        let row = [
            <Checkbox color="primary" checked={this.isRowSelected(item)}/>,
            item.assetEventTitle
        ];
        return row;
    }

    renderUserRow(item:UserModel) {
        let row = [
            <Checkbox color="primary" checked={this.isRowSelected(item)}/>,
            item.name
            ]
        return row;
    }

    render() {
        let cf = this.state.componentFactory;

        return (
            cf.renderBox(
                cf.renderBoxPadded([
                    cf.renderTableWithSelection(this.getHeaders(), this.state.items, this.renderRow.bind(this), this.onRowSelection.bind(this), {fitToContent:true})
                ])
            )
        )
    }
}

export default dialog(AssetSelectionDialog)