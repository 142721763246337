export default class HistoryQuery {

    public static GetHistory = `query GetHistoryQuery($input: GetHistoryInput) {
        getHistory(input:$input) {
            uniqueId
            type
            content
            userId
            date
        }
    }`;

    public static CreateHistory = `mutation CreateHistoryMutation($input: HistoryInput!) {
        createHistory(input: $input) {
            uniqueId
            type
            content
            userId
            date
        }
    }`;
}