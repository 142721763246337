import * as React from 'reactn';
import {ComponentFactory, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from 'react-ga';
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
}

class NotificationPrefsDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        let notificationPrefs = JSON.parse(props.param);
        componentFactory.addDefaultBinding(null, null, notificationPrefs);

        this.state = {
            componentFactory:componentFactory
        };
    }

    async componentDidMount() {
        ReactGA.modalview('/item/notificationPrefsDialog');
    }

    public render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                cf.renderBoxPadded(cf.renderTextField("distance", "Distance", null, null, {disabled:true})),
                cf.renderBoxPadded(cf.renderTextField("regions", "Regions", null, null, {disabled:true})),
                cf.renderBoxPadded(cf.renderTextField("categories", "Categories", null, null, {disabled:true, multiline:true})),
                cf.renderBoxPadded(cf.renderTextField("activities", "Activities", null, null, {disabled:true, multiline:true})),
                cf.renderBoxPadded(cf.renderTextField("product groups", "Product Groups", null, null, {disabled:true, multiline:true})),
            ])
        )
    }
}

export default dialog(NotificationPrefsDialog)