export default class NotificationHistoryQuery {

    public static SendPushNotifications = `mutation SendPushNotifications ($input: SendPushNotificationInput) {
        sendPushNotifications (input: $input) {
            success
        }
    }`;

    public static SendEmail = `mutation SendEmailQuery($input: SendEmailInput!) {
        sendEmail(input: $input) {
            success
        }
    }`;

    public static EmailFarmAssets = `mutation EmailFarmAssetsQuery($input: EmailFarmAssetsInput!) {
        emailFarmAssets(input: $input) {
            success
        }
    }`;

    public static EmailAdmins = `mutation EmailAdminsQuery($input: EmailAdminsInput!) {
        emailAdmins(input: $input) {
            success
        }
    }`;

    public static IsEmailAddressVerified = `query IsEmailAddressVerifiedQuery($input: VerifyEmailAddressInput!) {
        isEmailAddressVerified(input: $input) {
            success
        }
    }`;

    public static VerifyEmailAddress = `mutation VerifyEmailAddressMutation($input: VerifyEmailAddressInput!) {
        verifyEmailAddress(input: $input) {
            success
        }
    }`;

    public static GetMessageStatistics = `query GetMessageStatistics($input: GetMessageStatisticsInput!) {
        getMessageStatistics(input: $input) {
            statName
            statValue
        }
    }`;
}