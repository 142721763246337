export default class AssetTagGroupQuery {

    public static GetAssetTagGroups = `query GetAssetTagGroupsQuery {
        getAssetTagGroups {
            id
            assetTagGroupName
            seasonStart
            seasonEnd
        }
    }`;

    public static CreateAssetTagGroup = `mutation CreateAssetTagGroupMutation($input: AssetTagGroupInput!) {
        createAssetTagGroup(input: $input) {
            id
            assetTagGroupName
            seasonStart
            seasonEnd
        }
    }`;

    public static DeleteAssetTagGroup = `mutation DeleteAssetTagGroupMutation($id: ID!) {
        deleteAssetTagGroup(id: $id) {
            id
        }
    }`;
}