import * as React from 'reactn';
import {ComponentFactory, DateUtil, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from 'react-ga';
import InvokeFields from "../util/InvokeFields";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    items:Array<MessageStatistics>,
    messageHistory:MessageHistory
}

class MessageStatisticsDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, null, null);

        this.state = {
            componentFactory:componentFactory,
            items: [],
            messageHistory: props.param
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/messageStatisticsDialog');

        this.getMessageStatistics();
    }

    async getMessageStatistics() {
        let messageStatistics:MessageStatistics[] = [
            {statName:'TxnEmailsSent', statValue:'0'},
            {statName:'TxnEmailsDelivered', statValue:'0'},
            {statName:'TxnEmailsUniqueOpens', statValue:'0'},
            {statName:'TxnEmailsUniqueClicks', statValue:'0'}
        ];
        let messageStatisticsReturned:MessageStatistics[] = [];
        let input = {
            field: InvokeFields.getMessageStatistics,
            date: this.state.messageHistory.date,
            accountId: this.state.messageHistory.accountId
        }
        messageStatisticsReturned = await this.global.dataManager.get("getMessageStatistics", input)
        for (let messageStatisticReturned of messageStatisticsReturned) {
            for (let messageStatistic of messageStatistics) {
                if (messageStatisticReturned.statName == messageStatistic.statName) {
                    messageStatistic.statValue = messageStatisticReturned.statValue;
                }
            }
        }

        for (let messageStatistic of messageStatistics) {
            if (messageStatistic.statName == "TxnEmailsSent") {
                messageStatistic.statName = "Emails Sent";
            } else if (messageStatistic.statName == "TxnEmailsDelivered") {
                messageStatistic.statName = "Emails Delivered";
            } else if (messageStatistic.statName == "TxnEmailsUniqueOpens") {
                messageStatistic.statName = "Emails Opened";
            } else if (messageStatistic.statName == "TxnEmailsUniqueClicks") {
                messageStatistic.statName = "Email Links Clicked";
            }
            messageStatistic.statValue = parseInt(messageStatistic.statValue).toString();
        }
        console.log(messageStatistics);

        console.log("number of message statistics: " + messageStatistics.length);
        //ArrayUtils.sort(messageStatistics, "date", true);

        this.setState({items:messageStatistics});
    }

    async handleSave(callback:any) {
        callback();
    }

    // Render

    renderRow(item:MessageStatistics) {
        return [
            item.statName,
            item.statValue
        ]
    }

    render() {
        let cf = this.state.componentFactory;

        return (
            cf.renderBox(
                cf.renderBoxPadded([
                    cf.renderBox("Message statistics for " + DateUtil.format(this.state.messageHistory.date, "MMMM DD, YYYY")),
                    cf.renderTable(["Statistic", "Value"], this.state.items, this.renderRow.bind(this))
                ])
            )
        )
    }
}

export default dialog(MessageStatisticsDialog)