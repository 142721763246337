import * as React from 'reactn';
import {ComponentFactory, ComponentUtil, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from "react-ga";
import Styles from "../style/Styles";

interface IState {
    //user?:UserModel,
    componentFactory:ComponentFactory
}

class AddUserDialog extends React.Component<IBaseDialogProps, IState> {

    //public userNameComponent = createRef<TextComponent>();
    //public userEmailComponent = createRef<TextComponent>();
    public components = ComponentUtil.createRefs(2);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createUser", props.param);

        this.state = {
            //user: props.param ? props.param : {},
            componentFactory: componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/userDialog');
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    /*handleChanged(value:any, key:string) {
        let user = this.state.user;
        user[key] = value;
        this.setState({user:user});
    }*/

    render() {
        let cf = this.state.componentFactory;

        return cf.renderBox([
            cf.renderBoxPadded(cf.renderTextField("name", "User Name", this.components[0])),
            cf.renderBoxPadded(cf.renderTextField("email", "User Email", this.components[1])),
            cf.renderBoxPadded(cf.renderSwitchField("admin", "Admin"))
        ])
    }
}

export default dialog(AddUserDialog)