import {ArrayUtil, PaletteOptions, Render, SelectItem} from "oca-lib-web";
import './style/style.css';
import './style/base.css';
import RouteManager from "./auth/components/RouteManager";
import 'react-bulma-components/dist/react-bulma-components.min.css';
import Amplify from 'aws-amplify';
import aws_exports_dev from './aws-exports-dev';
import aws_exports_prod from './aws-exports-prod';
import {Palette} from "oca-lib-web";
import Constants from "./util/Constants";
import ListPage from "./container/ListPage";
import ItemPage from "./container/ItemPage";
import NotificationPage from "./container/NotificationPage";
import SponsorPage from "./container/SponsorPage";
import AdminPage from "./container/AdminPage";
import SendNotificationPage from "./container/SendNotificationPage";
import DataAccess from "./data/DataAccess";
import { setGlobal, getGlobal } from 'reactn';
import UserUtil from "./util/UserUtil";
import SendMessagePage from "./container/SendMessagePage";
import ProfilePage from "./container/ProfilePage";
import NotificationDetailPage from "./container/NotificationDetailPage";
import ReactGA from 'react-ga';
import AppIssuePage from "./container/AppIssuePage";
import DataManager from "./util/DataManager";
import ItineraryPage from "./container/ItineraryPage";
import ItineraryDetailPage from "./container/ItineraryDetailPage";
import MessagePage from "./container/MessagePage";
import MessageDetailPage from "./container/MessageDetailPage";
import MapPage from "./container/MapPage";
import AssetEventPage from "./container/AssetEventPage";
import InformationPage from "./container/InformationPage";

let STAGE = process.env.REACT_APP_STAGE;
if (STAGE == "dev") {
    Amplify.configure(aws_exports_dev);
} else if (STAGE == "prod") {
    Amplify.configure(aws_exports_prod);
}

RouteManager.addAuthenticatedRouteWithComponent("/", ListPage);
RouteManager.addAuthenticatedRouteWithComponent("/list", ListPage);
RouteManager.addAuthenticatedRouteWithComponent("/information", InformationPage);
RouteManager.addAuthenticatedRouteWithComponent("/item", ItemPage);
RouteManager.addAuthenticatedRouteWithComponent("/item/:id", ItemPage);
//RouteManager.addAuthenticatedRouteWithComponent("/assetEvent", AssetEventPage);
RouteManager.addAuthenticatedRouteWithComponent("/assetEvent/:id/:type", AssetEventPage);
RouteManager.addAuthenticatedRouteWithComponent("/itineraries", ItineraryPage);
RouteManager.addAuthenticatedRouteWithComponent("/itinerary", ItineraryDetailPage);
RouteManager.addAuthenticatedRouteWithComponent("/itinerary/:id", ItineraryDetailPage);
RouteManager.addAuthenticatedRouteWithComponent("/map", MapPage);
RouteManager.addAuthenticatedRouteWithComponent("/messaging", MessagePage);
RouteManager.addAuthenticatedRouteWithComponent("/messageDetail/:id", MessageDetailPage);
RouteManager.addAuthenticatedRouteWithComponent("/notification", NotificationPage);
RouteManager.addAuthenticatedRouteWithComponent("/notificationDetail/:id", NotificationDetailPage);
RouteManager.addAuthenticatedRouteWithComponent("/profile", ProfilePage);
RouteManager.addAuthenticatedRouteWithComponent("/sendNotification", SendNotificationPage);
RouteManager.addAuthenticatedRouteWithComponent("/sendNotification/:id", SendNotificationPage);
//RouteManager.addAuthenticatedRouteWithComponent("/sendMessage", SendMessagePage);
RouteManager.addAuthenticatedRouteWithComponent("/sendMessage/:type/:id", SendMessagePage);
RouteManager.addAuthenticatedRouteWithComponent("/sendMessage/:type", SendMessagePage);
RouteManager.addAuthenticatedRouteWithComponent("/sponsor", SponsorPage);
RouteManager.addAuthenticatedRouteWithComponent("/admin", AdminPage);
RouteManager.addAuthenticatedRouteWithComponent("/issue", AppIssuePage);

ReactGA.initialize('UA-178055687-1');
ReactGA.pageview(window.location.pathname + window.location.search);

setGlobal({dataManager: new DataManager(new DataAccess())});
//new DataManager(new DataAccess()).clear();

var handleAuthStateChangeCallback = async (arg1: string) => {
    console.log("Setting global state");
    //let data = getGlobal().data;
    let dataManager = getGlobal().dataManager;

    //let user = await data.getCurrentUser();
    let account = null;

    //let accounts = await data.getAccounts();
    let accounts = await dataManager.get("getAccounts");
    let products = await dataManager.get("getProducts");
    let assetTags = await dataManager.get("getAssetTags");
    let assetTagGroups = await dataManager.get("getAssetTagGroups");
    let users = await dataManager.get("getUsers");
    ArrayUtil.sort(accounts, "accountName");
    ArrayUtil.sort(assetTags, "assetTagName");
    ArrayUtil.sort(assetTagGroups, "assetTagGroupName");
    ArrayUtil.sort(products, "productName");

    let user = await dataManager.get("getCurrentUser");
    if (user) {
        account = await UserUtil.getAccountForUser(user, accounts);
    }

    let accountOptions:Array<SelectItem> = [];
    accountOptions.push(SelectItem.create("", "All Accounts"));
    accountOptions.push(...SelectItem.fromArray(accounts, "id", "accountName"));

    let productOptions:Array<SelectItem> = [];
    productOptions.push(SelectItem.create("", "All Products"));
    for (let product of products) {
        productOptions.push(SelectItem.create(product.productName.toLowerCase(), product.productName))
    }

    let assetTagOptions:Array<SelectItem> = [];
    assetTagOptions.push(SelectItem.create("", "All Asset Tags"));
    assetTagOptions.push(...SelectItem.fromArray(assetTags, "id", "assetTagName"));

    console.log(user);
    console.log(account);

    setGlobal({user:user, account:account, accounts:accounts, accountOptions:accountOptions, assetTags:assetTags, assetTagOptions:assetTagOptions, assetTagGroups:assetTagGroups,
        products:products, productOptions:productOptions, users:users, isDataLoaded:true});
};

// change me
let settings:AuthSettings = {
    title: 'Visit NC Farms',
    signupEnabled: true,
    loginSuccessRoute: '/',
    handleAuthStateChangeCallback: handleAuthStateChangeCallback
};

let pallette:PaletteOptions = {
    primary: Constants.GREEN_COLOR,
    secondary: Constants.LIGHT_GRAY_COLOR
};

Render.renderReactDOM(Palette.create(pallette, RouteManager.renderRouter(settings)));
