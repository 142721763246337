import AWSGraphql from "./AWSGraphql";

export default class AWS {

    public static async query(query: any, params?: any): Promise<any> {
        let item = await AWSGraphql.do(query, params);
        return this.P(item);
    }

    private static P(item: any): Promise<any> {
        return new Promise((resolve, reject) => {
            if (item && item.data && Object.keys(item.data).length > 0) {
                let keys = Object.keys(item.data);
                resolve(item.data[keys[0]]);
            } else {
                reject("OCA: Query returned no results");
            }
        });
    }
}