export default class AssetTagQuery {

    public static GetAssetTags = `query GetAssetTagsQuery {
        getAssetTags {
            id
            assetTagName
            assetCategories
            assetTagGroupId
            filterPriority
        }
    }`;

    public static CreateAssetTag = `mutation CreateAssetTagMutation($input: AssetTagInput!) {
        createAssetTag(input: $input) {
            id
            assetTagName
            assetCategories
            assetTagGroupId
            filterPriority
        }
    }`;

    public static DeleteAssetTag = `mutation DeleteAssetTagMutation($id: ID!) {
        deleteAssetTag(id: $id) {
            id
        }
    }`;
}