export default class UserQuery {

    public static CreateUser = `mutation CreateUserQuery($input: UserInput!) {
        createUser(input: $input) {
            id
            email
            name
            phone
            county
            live
            admin
            photo
        }
    }`;

    public static DeleteUser = `mutation DeleteUserMutation($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }`;

    public static DeleteCognitoUser = `mutation DeleteCognitoUserMutation($input: DeleteCognitoUserInput) {
        deleteCognitoUser(input: $input) {
            success
        }
    }`;

    public static GetUsers = `query GetUsers {
        getUsers {
            id
            email
            name
            phone
            county
            live
            admin
            photo
        }
    }`;

    public static GetUser = `query GetUserQuery($id: ID) {
        getUser(
            id:$id
        ) {
            id
            email
            name
            phone
            county
            live
            admin
            photo
        }
    }`;
}