export default class FileQuery {

    public static GetFiles = `query GetFilesQuery {
        getFiles {
            id
            fileName
            fileType
            folderId
            fileDate
            fileUserId
        }
    }`;

    public static CreateFile = `mutation CreateFileMutation($input: FileInput!) {
        createFile(input: $input) {
            id
            fileName
            fileType
            folderId
            fileDate
            fileUserId
        }
    }`;

    public static DeleteFile = `mutation DeleteFileMutation($id: ID!) {
        deleteFile(id: $id) {
            id
        }
    }`;
}