import * as React from 'reactn';
import {
    ArrayUtil,
    base, BaseColor, ComponentFactory, DateComponent, DateUtil, DialogUtil,
    IBaseProps, P, Route,
} from "oca-lib-web";
import {addCallback} from "reactn";
import Sidebar from "../component/Sidebar";
import {createRef} from "react";
//import AWSStorage from "../auth/aws/AWSStorage";
import NotificationPrefsDialog from "../component/NotificationPrefsDialog";
import Styles from "../style/Styles";
import VisualUtil from 'src/util/VisualUtil';
import UserUtil from "../util/UserUtil";
import NotificationUtil from "../util/NotificationUtil";

interface IState {
    componentFactory:ComponentFactory,
    dialogs:Array<any>,
    sentDetail:Array<any>,
    sentLocationRows:Array<any>,
    openedLocationRows:Array<any>,
    searchTerm?:string
}

class NotificationDetailPage extends React.Component<IBaseProps, IState> {

    public scheduledDateComponent = createRef<DateComponent>();
    public scheduledTimeComponent = createRef<DateComponent>();
    public notificationPrefsDialog = createRef<any>();

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(null, null, null);
        componentFactory.setHandleUpdateCallback((value:any, key:string) => {
            if (key == "openedNotificationsOnly") {
                this.forceUpdate();
            }
        })

        let dialogs = [
            <NotificationPrefsDialog {...componentFactory.renderDialogProps("Notification Prefs", "OK", null, this.notificationPrefsDialog)} />,
        ]

        this.state = {
            componentFactory:componentFactory,
            dialogs: dialogs,
            sentDetail: [],
            sentLocationRows: [],
            openedLocationRows: [],
            searchTerm: null
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.getNotification();
            return null;
        });
        this.getNotification();
    }

    async getNotification() {
        if (this.global.user == null) return;

        // Populate notification

        let id = Route.param(this, "id");
        var notifications = [];
        if (this.global.user?.admin) {
            // TODO: Replace with single call for notification history
            //notifications = await this.global.dataManager.get("getNotificationHistoryForAdmin");
            notifications = await NotificationUtil.getNotificationHistoryForAdmin();
        } else if (this.global.account) {
            notifications = await this.global.dataManager.get("getNotificationHistory", this.global.account.id);
        }
        let item:NotificationHistoryModel = ArrayUtil.getItem(notifications, "id", id);
        item = await this.setFormattedValues(item);
        console.log(item);
        this.state.componentFactory.getDefaultBinding().setItem(item);
        this.forceUpdate()

        /*let sentContents = await AWSStorage.getContents("_notifications/" + item.id + "/sent.json");
        let openContents = await AWSStorage.list("_notifications/" + item.id);
        console.log(openContents);
        console.log("openContents count: " + openContents.length);

        // Send Contents

        if (sentContents) {
            let sentDetailObject = JSON.parse(sentContents);
            for (let sentDetailItem of sentDetailObject) {
                for (let openContentItem of openContents) {
                    if (openContentItem.key.indexOf(sentDetailItem.notificationToken) > -1) {
                        sentDetailItem["open"] = "Yes";
                    }
                }
            }
            //this.setState({sentDetail:sentDetailObject});
            //this.setSentDetails(item);

            let sentLocationRows = [];
            for (let sentDetailItem of sentDetailObject) {
                let county = sentDetailItem.countyName || "<no location>";
                let found = false;
                for (let sentLocationRow of sentLocationRows) {
                    if (sentLocationRow.county == county) {
                        found = true;
                        sentLocationRow.count += 1;
                    }
                }
                if (!found) {
                    sentLocationRows.push({
                        county: county,
                        count: 1
                    })
                }
            }
            ArrayUtil.sortForInt(sentLocationRows, "count", true)
            this.setState({sentLocationRows:sentLocationRows, sentDetail:sentDetailObject});
        }

        // Opened

        if (openContents) {
            let openedLocationRows = [];
            for (let openContentItem of openContents) {
                if (openContentItem.key.indexOf("sent") > -1) continue;

                let openContentObject = await AWSStorage.getContents(openContentItem.key);
                let openContentObjectJson = JSON.parse(openContentObject);
                let county = openContentObjectJson.county || "<no location>";
                let found = false;
                for (let openedLocationRow of openedLocationRows) {
                    if (openedLocationRow.county == county) {
                        found = true;
                        openedLocationRow.count += 1;
                    }
                }
                if (!found) {
                    openedLocationRows.push({
                        county: county,
                        count: 1
                    })
                }
            }

            ArrayUtil.sortForInt(openedLocationRows, "count", true);
            this.setState({openedLocationRows:openedLocationRows});
        }*/
    }

    filterSentDetail(sentDetail:Array<any>): Array<any> {
        let cf = this.state.componentFactory;
        let openedNotificationsOnly = cf.getDefaultBinding().getProperty("openedNotificationsOnly");
        console.log("openedNotificationsOnly: " + openedNotificationsOnly);
        if (!openedNotificationsOnly) {
            return sentDetail;
        }
        let sentOpenDetail = [];
        for (let sentDetailItem of sentDetail) {
            if (sentDetailItem.open) {
                sentOpenDetail.push(sentDetailItem);
            }
        }
        return sentOpenDetail;
    }

    async setFormattedValues(item:NotificationHistoryModel): Promise<NotificationHistoryModel> {
        item["pushNotificationDateFormatted"] = DateUtil.format(item["pushNotificationDate"], "MMMM DD, YYYY");
        let dateFormat = item["pushNotificationTime"]?.length == 4 ? "HHmm" : null;
        item["pushNotificationTimeFormatted"] = DateUtil.format(item["pushNotificationTime"], "hh:mm a", dateFormat);
        if (item["appTargetType"] == "asset") {
            item["appTargetTypeFormatted"] = "Asset";
            let asset = await this.global.dataManager.get("getAsset", item["appTargetSelection"]);
            item["appTargetSelectionFormatted"] = asset.assetName;
        } else if (item["appTargetType"] == "assetEvent") {
            item["appTargetTypeFormatted"] = "Event";
            item["appTargetSelectionFormatted"] = "TBD"
            //let asset = await this.global.data.getAsset(item["appTargetSelection"]);
            //item["appTargetSelectionFormatted"] = asset.assetName;
        }
        let allAssetTags = await this.global.dataManager.get("getAssetTags");
        let appTargetAssetTags = item["appTargetAssetTags"]?.split(",") || [];
        let assetTagList = [];
        for (let appTargetAssetTag of appTargetAssetTags) {
            let appTargetAssetTagObject = ArrayUtil.getItem(allAssetTags, "id", appTargetAssetTag);
            if (appTargetAssetTagObject) {
                assetTagList.push(appTargetAssetTagObject.assetTagName);
            }
        }
        item["appTargetAssetTagsFormatted"] = assetTagList.join(",");
        return P.resolve(item);
    }

    onViewNotificationPrefs(item:any) {
        console.log(item);
        DialogUtil.open(this.notificationPrefsDialog.current, item.notificationPrefs);
    }

    onSearchChanged(value:any, key:string) {
        this.setState({searchTerm: value}, function(this:NotificationDetailPage) {
            //this.setSentDetails(this.state.item);
        }.bind(this));
    }

    // Render methods

    public renderRow(item:{county:string,count:number}) {
        return [
            item.county,
            item.count
        ]
    }

    public renderSentDetailRow(item:any) {
        let cf = this.state.componentFactory;
        //console.log(item);
        return [
            item.notificationToken,
            item.deviceType,
            item.countyName || "<no location>",
            item.notificationPrefs ? cf.renderButton("View", ((e) => { this.onViewNotificationPrefs(item)}).bind(this), {isFlat:true}) : null,
            item.location || "<no location>",
            item.open || "No"
        ]
    }

    public render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBoxProps({fullHeight:true, background:BaseColor.WHITE}, [
                this.state.dialogs,
                cf.renderBoxProps(Styles.MARGIN_SIDEBAR, [
                    VisualUtil.getHeaderDisplay("Notification Details"),
                    cf.renderLine(),
                    cf.renderSizedColumns("half", [
                        cf.renderBoxPadded(cf.renderTextField("pushNotificationTitle", "Push Notification Title", null, null, {disabled:true})),
                        cf.renderBoxPadded(cf.renderTextField("pushNotificationBody", "Push Notification Body", null, null, {disabled:true})),
                        cf.getDefaultBinding().getProperty("pushNotificationDate") ? cf.renderBoxPadded(cf.renderTextField("pushNotificationDateFormatted", "Push Notification Date", null, null, {disabled:true})) : null,
                        cf.getDefaultBinding().getProperty("pushNotificationTime") ? cf.renderBoxPadded(cf.renderTextField("pushNotificationTimeFormatted", "Push Notification Time", null, null, {disabled:true})) : null,
                    ],[
                        cf.renderBoxPadded(cf.renderTextField("appTargetTypeFormatted", "Target Type", null, null, {disabled:true})),
                        cf.renderBoxPadded(cf.renderTextField("appTargetSelectionFormatted", "Target Selection", null, null, {disabled:true})),
                        cf.renderBoxPadded(cf.renderTextField("appTargetAssetTagsFormatted", "Target Asset Tags", null, null, {disabled:true})),
                        cf.renderBoxPadded(cf.renderTextField("appTargetProducts", "Target Products", null, null, {disabled:true})),
                        this.global.user?.admin ? cf.renderBoxPadded(cf.renderTextField("id", "Push Notification Id", null, null, {disabled:true})) : null
                    ]),
                ])
            ])
        )
    }

    public renderSent() {
        let cf = this.state.componentFactory;
        let actions = [
            cf.renderSwitchField("openedNotificationsOnly", "Opened Only")
        ]
        return (
            cf.renderBoxProps({}, [
                VisualUtil.getHeaderDisplay("Notification Tokens", actions),
                cf.renderTable(["Notification Token", "Device Type", "County", "Notification Prefs", "Location", "Opened"], this.filterSentDetail(this.state.sentDetail), this.renderSentDetailRow.bind(this))
            ])
        )
    }

    public renderOpenSentLocations() {
        let cf = this.state.componentFactory;
        return (
            !this.global.isDataLoaded ?
                VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                    :
                    cf.renderBoxProps({}, [
                        VisualUtil.getHeaderDisplay("Open / Sent Locations"),
                        cf.renderSizedColumns("half", [
                            cf.renderTable(["County", "Sent Count"], this.state.sentLocationRows, this.renderRow.bind(this))
                        ], [
                            cf.renderTable(["County", "Open Count"], this.state.openedLocationRows, this.renderRow.bind(this))
                        ])
                    ])
        )
    }
}

export default base(NotificationDetailPage, Sidebar)