import {DateUtil, P} from "oca-lib-web";
import { getGlobal } from 'reactn';

export default class MessageUtil {

    public static async createMessageHistory(id:string, messageSubject:string, messageBody:string, messageType:string, assetIds:string, messageScheduledDate?:string, messageScheduledTime?:string):Promise<any> {
        let user = getGlobal().user;
        let account = getGlobal().account;
        if (!user || !account) {
            return P.resolve(false);
        }

        let input:MessageHistory = {
            id: id,
            messageSubject: messageSubject,
            messageBody: messageBody,
            messageType:  messageType,
            messageScheduledDate: messageScheduledDate,
            messageScheduledTime: messageScheduledTime,
            assetIds: assetIds,
            accountId:account.id,
            userId: user.id,
            date:!messageScheduledDate ? DateUtil.nowFormat() : null,
        }
        await getGlobal().dataManager.save("createMessageHistory", input);
    }
}