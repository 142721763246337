export default class InvokeFields {

    public static createHistory = "createHistory";
    public static getHistory = "getHistory";
    public static getAssetsForAdmin = "getAssetsForAdmin";
    public static importAssets = "importAssets";
    public static deleteCognitoUser = "deleteCognitoUser";
    public static emailAdmins = "emailAdmins";
    public static emailFarmAssets = "emailFarmAssets";
    public static getCountyFromCoordinates = "getCountyFromCoordinates";
    public static getMessageStatistics = "getMessageStatistics";
    public static sendPushNotifications = "sendPushNotifications";
    public static sendEmail = "sendEmail";
    public static isEmailAddressVerified = "isEmailAddressVerified";
    public static verifyEmailAddress = "verifyEmailAddress";
    public static uploadFile = "uploadFile";
    public static removeFile = "deleteFile";
}