import * as React from 'reactn';
import {ComponentFactory, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from "react-ga";
import ComponentUtil from "../util/ComponentUtil";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory
}

class AddAssetTagGroupDialog extends React.Component<IBaseDialogProps, IState> {

    public components = ComponentUtil.createRefs(1);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createAssetTagGroup", props.param);

        this.state = {
            componentFactory:componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/assetTagGroupDialog');
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox(
                cf.renderBoxPadded([
                    cf.renderBoxPadded(cf.renderTextField("assetTagGroupName", "Asset Tag Group Name", this.components[0])),
                    cf.renderBoxPadded(cf.renderDateField("seasonStart", "Season Start", "date")),
                    cf.renderBoxPadded(cf.renderDateField("seasonEnd", "Season End", "date")),
                ])
            )
        )
    }
}

export default dialog(AddAssetTagGroupDialog)