import * as React from 'reactn';
import StaticMap, {
    Marker,
    NavigationControl,
    FlyToInterpolator
} from "react-map-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import "../style/map_page.css";
import DeckGL, {GeoJsonLayer} from "deck.gl";
import * as d3Ease from 'd3-ease';
import {addCallback} from "reactn";
import MapModeIcon from "@mui/icons-material/Map";
import ThreeDModeIcon from "@mui/icons-material/ThreeSixty";
import LocateUserIcon from "@mui/icons-material/MyLocation";
import LightViewIcon from "@mui/icons-material/Category";
import StreetsViewIcon from "@mui/icons-material/Streetview";
import SatelliteViewIcon from "@mui/icons-material/Satellite";
//import atlasPNG from "../images/out.png";
//import atlasMAP from "../images/out.json";
//import farmPng from "../image/farm.png";
//import farmMap from "../image/farm.json";
import MapUtil from "../util/MapUtil";
import FeatureUtil from "../util/FeatureUtil";
import {ArrayUtil, base, Http, P, Route} from "oca-lib-web";
import Sidebar from "../component/Sidebar";
import {point, BBox, bbox, featureCollection} from '@turf/turf';
import WebMercatorViewport from 'viewport-mercator-project';
import Constants from "../util/Constants";
import InvokeFields from "../util/InvokeFields";
import {ScatterplotLayer} from 'deck.gl';

enum MapMode {
    Flat = "Flat",
    Perspective = "Perspective"
}

enum ViewMode {
    Light = "Light",
    Streets = "Streets",
    Satellite = "Satellite"
}

interface IGeoPoint {
    latitude: number;
    longitude: number;
}

interface ITooltip {
    name: string;
    title: string;
    subtitle: string;
    x: number;
    y: number;
}

interface IProps {
}

interface IState {
    mode: ViewMode,
    viewState: any,
    viewport:any,
    userLocation?: IGeoPoint,
    tooltipGeo?: ITooltip,
    tooltipPoint?: ITooltip,
    layersLocation?: IGeoPoint,
    geoLayers: Array<object>,
    selectedAccount?: string,
    assets:Array<AssetModel>
}

class MapPage extends React.Component<IProps, IState> {

    private NC_COUNTIES_URL = "https://opendata.arcgis.com/datasets/34acbf4a26784f189c9528c1cf317193_0.geojson";

    public static defaultProps = {};

    public state = {
        mode: ViewMode.Light,
        viewState: {
            latitude: 34.7699792,
            longitude: -76.8579785,
            zoom: 14,
            bearing: 0,
            pitch: 0,
            transitionDuration: 2000,
            transitionInterpolator: new FlyToInterpolator(),
            transitionEasing: d3Ease.easeCubic
        },
        viewport: {},
        geoLayers: [] as object[],
        tooltipGeo: null,
        tooltipPoint: null,
        userLocation: null,
        selectedAccount: null,
        assets:[]
    };

    componentDidMount() {
        addCallback(global => {
            /*if (this.global.project) {
                this.initMap(this.global.project.id);
            }*/
            this.initMap();
            return null;
        });
        /*if (this.global.project) {
            this.initMap(this.global.project.id)
        }*/
        this.initMap();

        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);

        this.updateViewState = this.updateViewState.bind(this);
        this.updateViewportNoPitch = this.updateViewportNoPitch.bind(this);
        this.updateMapMode = this.updateMapMode.bind(this);
        this.updateViewMode = this.updateViewMode.bind(this);
        this.locateUser = this.locateUser.bind(this);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        console.log(window.innerWidth);
        //this.setState({ width: window.innerWidth, height: window.innerHeight });
    }

    // Map Methods

    async initMap() {
        if (!this.global.user || (!this.global.user.admin && !this.global.account)) { return; }

        let geoLayers = [];
        geoLayers.push(... (await this.drawCounties()));
        geoLayers.push(... (await this.drawPoints()));
        this.setState({geoLayers:geoLayers});
    }

    async drawPoints() {
        let accountAssets:Array<AssetModel> = []
        if (this.global.user.admin && this.state.selectedAccount) {
            accountAssets = await this.global.dataManager.get("getAssets", this.state.selectedAccount.id);
        } else if (this.global.user.admin) {
            accountAssets = await this.global.dataManager.get("getAssetsForAdmin", {field:InvokeFields.getAssetsForAdmin});
        } else {
            accountAssets = await this.global.dataManager.get("getAssets", this.global.account.id);
        }

        let features:any[] = [];
        for (let accountAsset of accountAssets) {
            let properties = {};
            properties[Constants.ATTRIBUTE_TITLE] = accountAsset.assetName;
            properties[Constants.ATTRIBUTE_SUBTITLE] = accountAsset.assetName;
            var assetFeature = point([accountAsset.assetLongitude, accountAsset.assetLatitude], properties);
            //console.log(assetFeature);
            features.push(assetFeature);
        }

        //console.log(features)
        //console.log("adding feature points: " + features.length);
        let geoLayers = [];
        geoLayers.push(this.renderIconLayer("large", {index:200, info:{}, data:features}));

        this.setState({assets:accountAssets});
        //this.setState({geoLayers:geoLayers});
        return P.resolve(geoLayers);
    }

    async drawCounties(): Promise<any> {
        let geoLayers = [];
        let data = await Promise.all([this.global.dataManager.get("getAccounts"), Http.get(this.NC_COUNTIES_URL)]);
        let accounts = data[0];
        let countyFeatures = data[1];
        //let accounts =  this.global.data.getAccounts();
        //let countyFeatures = await Http.get(this.NC_COUNTIES_URL);

        let selectedFeatures = [];
        for (let countyFeature of countyFeatures.features) {
            let properties = countyFeature["properties"];
            let countyName = properties["CO_NAME"].toLowerCase();
            let counties = [];
            if (this.global.user.admin) {
                for (let account of accounts) {
                    if (account.counties) {
                        counties.push(... account.counties.split(","));
                    }
                }
            } else if (this.global.account?.counties) {
                counties = this.global.account.counties.split(",");
            }

            for (let county of counties) {
                if (county == countyName) {
                    //console.log("Adding county: " + countyName);
                    let properties = {};
                    properties[Constants.ATTRIBUTE_TITLE] = countyName;
                    properties[Constants.ATTRIBUTE_SUBTITLE] = countyName;
                    countyFeature.properties = properties;
                    selectedFeatures.push(countyFeature);
                    //console.log(countyFeature);
                }
            }
        }
        geoLayers.push(this.renderGeoJsonLayer({index:0, info:{}, data:selectedFeatures}));
        //return geoLayers;

        var bounds = bbox(featureCollection(selectedFeatures));
        console.log(bounds);
        this.fitBounds(bounds);

        return P.resolve(geoLayers)
        //this.setState({geoLayers:geoLayers});
    }

    fitBounds(bounds:BBox) {
        const {longitude, latitude, zoom} = new WebMercatorViewport({width:600, height:400})
            .fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]], {
                padding: 20,
            });
        /*console.log(this.state.viewState);
        const viewport = new WebMercatorViewport(this.state.viewState);
        const fitBounds = viewport.fitBounds(
            [[-73.9876, 40.7661], [-72.9876, 41.7661]],
            {padding: 20, offset: [0, -40]}
        );
        console.log(fitBounds);*/
        let state:any = {
            viewState: {
                ...this.state.viewState,
                latitude: latitude,
                longitude: longitude,
                zoom: zoom
            }
        }
        this.setState(state);
    }

    async locateUser() {
        let location: IGeoPoint | null = await new Promise(resolve => {
            if ("geolocation" in navigator) {
                navigator.geolocation.getCurrentPosition(
                    position =>
                        resolve({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude
                        }),
                    error => {
                        console.error(error);
                        resolve(null);
                    },
                    {
                        timeout: 25000,
                        enableHighAccuracy: false
                    }
                );
            } else {
                console.error("Geolocation IS NOT available");
                resolve(null);
            }
        });

        if (!location) return;

        let state: any = {
            viewState: {
                ...this.state.viewState, // copy previous values
                ...location,
                //transitionDuration: 2000,
                //transitionInterpolator: new FlyToInterpolator(),
                //transitionEasing: d3Ease.easeCubic
            },
            userLocation: location
        };
        //this.setState(state, this.renderLayers);
        this.setState(state);
    }

    updateLocation(latitude:number, longitude:number, zoom:number) {
        //this.updateViewState({latitude:latitude, longitude:longitude, zoom:zoom});
        let state:any = {
            viewState: {
                ...this.state.viewState,
                latitude: latitude,
                longitude: longitude,
                zoom: zoom
            }
        }
        this.setState(state);
    }

    updateViewState({viewState}: any) {
        viewState = {...this.state.viewState, ...viewState};
        this.setState({viewState});
    }

    // "Look North" button also tries to reset the pitch, so we prevent this here
    updateViewportNoPitch(viewport: any) {
        viewport.pitch = this.state.viewState.pitch;
        this.updateViewState({viewState: viewport});
    }

    updateMapMode(mode: MapMode) {
        switch (mode) {
            case MapMode.Flat:
                this.updateViewState({
                    viewState: {
                        pitch: 0,
                        //transitionDuration: 2000, // animate the change
                        //transitionInterpolator: new FlyToInterpolator()
                    }
                });
                break;
            case MapMode.Perspective:
                this.updateViewState({
                    viewState: {
                        pitch: 60,
                        //transitionDuration: 2000, // animate the change
                        //transitionInterpolator: new FlyToInterpolator()
                    }
                });
                break;
        }
    }

    updateViewMode(mode: ViewMode) {
        this.setState({mode});
    }

    /*fitBounds(bounds:BBox) {
        const {longitude, latitude, zoom} = new WebMercatorViewport(this.state.viewState)
            .fitBounds([[bounds[0], bounds[1]], [bounds[2], bounds[3]]], {
                padding: 20,
            });

        let state:any = {
            viewState: {
                ...this.state.viewState,
                latitude: latitude,
                longitude: longitude,
                zoom: zoom
            }
        }
        this.setState(state);
    }*/

    // Tooltip Methods

    handleHover(type: string, info: any, {object, x, y}: any) {
        //console.log("Hovered", info, object);
        //const {name, title, subtitle} = FeatureUtil.getTitle(info, object, "properties");
        if (!object) return;

        const title = FeatureUtil.getTitle(object);
        const subtitle = FeatureUtil.getSubtitle(object);
        const name = title;
        // TODO: Unexpected use of 'name'
        const tooltip = name || title ? {name, title, subtitle, x, y} : null;
        switch (type) {
            case "geo":
                this.setState({tooltipGeo: tooltip});
                break;
            case "point":
                this.setState({tooltipPoint: tooltip});
                break;
        }
    }

    // Map Render methods

    renderGeoJsonLayer({index, info, data}: any) {
        return new GeoJsonLayer({
            id: `geojson-layer-${index}`,
            data,
            pickable: true,
            opacity: 1,
            filled: true,
            stroked: true,
            extruded: true,
            lineWidthMinPixels: 13,
            lineWidthMaxPixels: 15,
            lineJointRounded: true,
            pointRadiusScale: 0,
            onHover: this.handleHover.bind(this, "geo", info),
            _subLayerProps: {
                "polygons-fill": {
                    getPolygonOffset: (d: any) => [0, -d.layerIndex * 100],
                    getElevation: (d: any) => FeatureUtil.getFeature3DHeight(info, d.sourceFeature.feature),
                    getFillColor: (d: any) => FeatureUtil.getFeatureColor(info, d.sourceFeature.feature, "polygon"),
                    getLineColor: (d: any) => FeatureUtil.getLineColor()
                },
                "line-strings": {
                    getPolygonOffset: (d: any) => [0, -10000.0 - d.layerIndex * 100],
                    getColor: (d: any) => FeatureUtil.getFeatureColor(info, d.sourceFeature.feature, "line")
                }
            }
        });
    }

    handleIconClick(info:any) {
        let properties = info.object.properties;
        if (properties["oca_title"]) {
            console.log(properties["oca_title"])
            let assetName = properties["oca_title"];
            let asset = ArrayUtil.getItem(this.state.assets, "assetName", assetName);
            console.log(asset);
            if (asset) {
                Route.go(this, 'item/' + asset.id);
            }
        }
        //console.log(info.object);
    }

    /*renderIconLayer(size: string, {index, info, data}: any) {
        return new IconLayer({
            id: `icon-layer-${size}-${index}`,
            parameters: { depthTest: false },
            data,
            pickable: true,
            onHover: this.handleHover.bind(this, "point", info),
            onClick: (info, event) => console.log('Clicked:', info, event),
            iconAtlas: farmPng,
            iconMapping: farmMap,
            getPosition: (d: any) => MapUtil.getProp(d, "geometry.coordinates"),
            getIcon: (d: any) => "map-point-google-map-marker-gif-11562858751s4qufnxuml",
            getSize: (d: any) => (size === "small" ? 30 : 45)
        });
    }*/

    renderIconLayer(size: string, {index, info, data}: any) {
        return new ScatterplotLayer({
            id: `icon-layer-${size}-${index}`,
            parameters: { depthTest: false },
            data,
            pickable: true,
            onHover: this.handleHover.bind(this, "point", info),
            onClick: (info, event) => this.handleIconClick(info),
            stroked: false,
            filled: true,
            getPosition: (d: any) => MapUtil.getProp(d, "geometry.coordinates"),
            getRadius: 2000,
            getFillColor: [255, 0, 0],
            getLineColor: d => [0, 0, 0]
        });
    }

    renderTooltip() {
        let tooltip = this.state.tooltipPoint;
        if (!tooltip) tooltip = this.state.tooltipGeo;
        if (!tooltip) return null;
        let line1, line2;
        if (tooltip.name) {
            line1 = tooltip.name;
        } else if (tooltip.title) {
            line1 = tooltip.title;
            line2 = tooltip.subtitle;
        }
        return (
            <div className="map-page--tooltip"
                 style={{left: tooltip.x, top: tooltip.y}}>
                <div className="map-page--tooltip--text">
                    <div className="map-page--tooltip--text--title">{line1}</div>
                    {!!line2 && (
                        <div className="map-page--tooltip--text--subtitle">{line2}</div>
                    )}
                </div>
                <div className="map-page--tooltip--triangle"/>
            </div>
        );
    }

    renderNavigationControls() {
        const {
            viewState: {pitch},
            mode,
        } = this.state;

        return (
            <div className="map-page--controls">
                <NavigationControl onViewportChange={this.updateViewportNoPitch}/>
                <div className="map-page--controls--spacer"/>

                <div className="mapboxgl-ctrl mapboxgl-ctrl-group ">
                    <button title="My Location" className="mapboxgl-ctrl-icon " onClick={this.locateUser}>
                        <LocateUserIcon/>
                    </button>
                </div>

                <div className="map-page--controls--spacer"/>

                <div className="mapboxgl-ctrl mapboxgl-ctrl-group ">
                    <button title="Flat Mode"
                            className={"mapboxgl-ctrl-icon " + (pitch ? "" : "map-page--controls--selected")}
                            onClick={() => this.updateMapMode(MapMode.Flat)}>
                        <MapModeIcon/>
                    </button>
                    <button
                        title="3D Mode"
                        className={"mapboxgl-ctrl-icon " + (pitch ? "map-page--controls--selected" : "")}
                        onClick={() => this.updateMapMode(MapMode.Perspective)}>
                        <ThreeDModeIcon/>
                    </button>
                </div>

                <div className="map-page--controls--spacer"/>

                <div className="mapboxgl-ctrl mapboxgl-ctrl-group ">
                    <button
                        title="Light View"
                        className={"mapboxgl-ctrl-icon " + (mode === ViewMode.Light ? "map-page--controls--selected" : "")}
                        onClick={() => this.updateViewMode(ViewMode.Light)}>
                        <LightViewIcon/>
                    </button>
                    <button
                        title="Streets View"
                        className={"mapboxgl-ctrl-icon " + (mode === ViewMode.Streets ? "map-page--controls--selected" : "")}
                        onClick={() => this.updateViewMode(ViewMode.Streets)}>
                        <StreetsViewIcon/>
                    </button>
                    <button
                        title="Satellite View"
                        className={"mapboxgl-ctrl-icon " + (mode === ViewMode.Satellite ? "map-page--controls--selected" : "")}
                        onClick={() => this.updateViewMode(ViewMode.Satellite)}>
                        <SatelliteViewIcon/>
                    </button>
                </div>
            </div>)
    }

    public render() {
        /*const {
            viewState: {latitude, longitude},
            mode,
            geoLayers,
        } = this.state;*/

        if (this.state.viewState.latitude === null || this.state.viewState.longitude === null) return null;

        //let layers = geoLayers;
        //console.log(this.state.geoLayers);
        //if (zoom >= 16) layers = layers.concat(largePointLayers);
        //else if (zoom >= 15) layers = layers.concat(smallPointLayers);
        //console.log(this.state.geoLayers);
        //var layers = this.state.geoLayers;
        return (
            <div className="map-page">
                <DeckGL
                    controller
                    width="100vw"
                    height="100vh"
                    viewState={this.state.viewState}
                    layers={this.state.geoLayers}
                    getCursor={() => "default"}
                    onViewStateChange={this.updateViewState}>
                    {/*
                    // @ts-ignore */}
                    <StaticMap ref={this.mapComponent} mapStyle={MapUtil.getMapStyle(this.state.mode)}>
                        {this.renderNavigationControls()}
                        {this.state.userLocation && (
                            <Marker latitude={this.state.userLocation.latitude} longitude={this.state.userLocation.longitude}>
                                <div className="mapboxgl-user-location-dot map-page--marker"/>
                            </Marker>
                        )}
                    </StaticMap>
                    { this.renderTooltip() }
                </DeckGL>
            </div>
        );
    }
}

export default base(MapPage, Sidebar)