import {ArrayUtil, ComponentBinding, Http, P} from "oca-lib-web";
import Constants from "./Constants";
import moment from "moment";
import { getGlobal } from 'reactn';
import {booleanPointInPolygon, point} from "@turf/turf";
import UserUtil from "./UserUtil";
import InvokeFields from "./InvokeFields";

export default class AssetUtil {

    public static getAssetList():Promise<Array<any>> {
        return new Promise<Array<any>>(async function(resolve, reject) {
            let assets = [];
            // TODO: Cache assets on main global
            if (getGlobal().user.admin) {
                assets = await getGlobal().dataManager.get("getAssetsForAdmin", {field:InvokeFields.getAssetsForAdmin});
            } else if (getGlobal().account) {
                assets = await getGlobal().dataManager.get("getAssets", getGlobal().account.id);
            }
            ArrayUtil.sort(assets, "assetName");
            resolve(assets);
        })
    }

    public static getAssetCounty(assetLatitude:number, assetLongitude:number): string {
        var data = require('../data/NC_Counties.json');
        let features = data["features"];
        let userPoint = point([assetLongitude, assetLatitude]);
        let countyFromAsset = "";
        for (let feature of features) {
            let withinPoint = booleanPointInPolygon(userPoint, feature);
            if (withinPoint) {
                let properties = feature["properties"];
                let countyName = properties["CO_NAME"];
                countyFromAsset = countyName.toLowerCase();
                console.log("asset is being located in : " + countyFromAsset);
            }
        }
        return countyFromAsset;
    }

    public static async validateCorrectCounty(item:AssetModel): Promise<boolean> {
        let isAssetWithinUsersCounty = await UserUtil.isAssetWithinUsersCounty(item);
        return P.resolve(getGlobal().user?.admin || isAssetWithinUsersCounty)
    }

    public static hasValidContactInfo(item:AssetModel):boolean {
        if (item.assetContactPreference == "email") {
            return item.assetEmailAddress != null;
        } else if (item.assetContactPreference == "text" || item.assetContactPreference == "phone") {
            return item.assetContactPhoneNumber != null;
        }
        return false;
    }

    public static isActive(asset:AssetModel): boolean {
        if (!asset.isActive) { return false; }

        if (this.isSpecialEvent(asset)) {
            let assetEndDate = asset.assetEventEndDate;
            let currentDate = moment().format("YYYYMMDD");
            if (assetEndDate < currentDate) {
                return false;
            }
        }
        return true;
    }

    public static isSpecialEvent(asset:AssetModel) {
        return asset.assetCategories && asset.assetCategories.includes("specialEvents");
    }

    public static async getActiveAssets(accountId:string): Promise<number> {
        let activeAssets = 0;
        let assets = await getGlobal().dataManager.get("getAssets", accountId);
        for (let asset of assets) {
            if (this.isActive(asset)) {
                activeAssets += 1;
            }
        }
        return P.resolve(activeAssets);
    }

    public static async addAssetFields(componentBinding:ComponentBinding): Promise<boolean> {
        console.log("trying to geocode address:" + componentBinding.getProperty("assetAddress"));

        let geoCodingAPI = Constants.GEOCODING_API.replace("{0}", componentBinding.getProperty("assetAddress"));
        let data = await Http.get(geoCodingAPI);
        console.log(data);
        let results = data["results"];
        let assetOverrideLocation = componentBinding.getProperty("assetOverrideLocation");
        if (results && results.length > 0) {
            let location = results[0]["geometry"]["location"];
            console.log("Found location: " + location.lat, location.lng);
            if (!assetOverrideLocation) {
                componentBinding.setProperty("assetLatitude", location.lat);
                componentBinding.setProperty("assetLongitude", location.lng);
                componentBinding.setProperty("assetCounty", this.getAssetCounty(location.lat, location.lng));
            }
            //console.log("Found county: " + componentBinding.getProperty("assetCounty"));
        } else {
            console.error("Could not find location")
        }

        //componentBinding.setProperty("accountId", accountId);
        console.log(componentBinding.getProperty("isActive"))
        if (componentBinding.getProperty("isActive") === undefined) {
            componentBinding.setProperty("isActive", true);
        }

        return P.resolve(true);
    }

    async validateCorrectCounty(item:AssetModel): Promise<boolean> {
        let isAssetWithinUsersCounty = await UserUtil.isAssetWithinUsersCounty(item);
        return P.resolve(getGlobal().user?.admin || isAssetWithinUsersCounty)
    }

    // TODO: Move asset validation here so that it can also be used for import
}