export default class AssetImageQuery {

    public static GetAssetImage = `query GetAssetImageQuery($id: ID) {
        getAssetImage (
            id:$id
        ) {
            id
            comment
            numberOfLikes
            numberOfShares
            isActive
        }
    }`;

    public static CreateAssetImage = `mutation CreateAssetImageMutation($input: AssetImageInput!) {
        createAssetImage(input: $input) {
            id
            comment
            numberOfLikes
            numberOfShares
            isActive
        }
    }`;

    public static DeleteAssetImage = `mutation DeleteAssetImageMutation($id: ID!) {
        deleteAssetImage(id: $id) {
            id
        }
    }`;
}