import * as React from 'reactn';
import {ComponentFactory, dialog, IBaseDialogProps} from "oca-lib-web";
import ReactGA from 'react-ga';
import AssetUtil from "../util/AssetUtil";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
}

class UpdateLocationDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createAsset", props.param);
        componentFactory.setHandleUpdateCallback((value:any, key:string) => {
            this.forceUpdate();
        })

        this.state = {
            componentFactory:componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/updateLocationDialog');
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let lat = componentBinding.getProperty("assetLatitude");
        let lng = componentBinding.getProperty("assetLongitude");
        componentBinding.setProperty("assetCounty", AssetUtil.getAssetCounty(lat, lng));
        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    render() {
        let cf = this.state.componentFactory;
        let overrideLocation = cf.getDefaultBinding()?.getProperty("assetOverrideLocation");
        return (
            cf.renderBox(
                cf.renderBoxPadded([
                    cf.renderBoxPadded(cf.renderTextField("assetCounty", "Asset County", null, null, {disabled:true})),
                    cf.renderBoxPadded(cf.renderSwitchField("assetOverrideLocation", "Override Location")),
                    overrideLocation ? cf.renderBoxPadded(cf.renderTextField("assetLatitude", "Asset Latitude", null, null, {type:'number'})) : null,
                    overrideLocation ? cf.renderBoxPadded(cf.renderTextField("assetLongitude", "Asset Longitude", null, null, {type:'number'})) : null,
                ])
            )
        )
    }
}

export default dialog(UpdateLocationDialog)