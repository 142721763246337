import * as React from 'reactn';
import {
    base,
    BaseColor,
    ButtonComponent,
    DialogUtil,
    Route,
    StringUtil,
    SelectItem,
    ArrayUtil,
    IBaseProps,
    LocalCache, ComponentFactory, SwitchComponent, Validater, DateUtil
} from "oca-lib-web";
import AWSStorage from "../auth/aws/AWSStorage";
import {createRef} from "react";
import Constants from "../util/Constants";
import AssetUtil from "../util/AssetUtil";
import Imgix from "react-imgix";
import AddCommentDialog from "../component/AddCommentDialog";
import ImageUtil from "../util/ImageUtil";
import VerifyEmailAddressDialog from "../component/VerifyEmailAddressDialog";
import InvokeFields from "../util/InvokeFields";
import Sidebar from "../component/Sidebar";
import AddAssetRelationshipDialog from "../component/AddAssetRelationshipDialog";
import AddEventDialog from "../component/AddEventDialog";
import {addCallback} from "reactn";
import MapUtil from "../util/MapUtil";
import UpdateLocationDialog from "../component/UpdateLocationDialog";
import {Alert} from "@mui/lab";
import HistoryUtil from "../util/HistoryUtil";
import MessageResources from "../util/MessageResources";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";
import NotificationUtil from "../util/NotificationUtil";
import {paymentMethods} from "../types/PaymentMethods";
import AssetEventUtil from "../util/AssetEventUtil";
import {contactPreferences} from "../types/ContactPreferences";

interface IState {
    componentFactory:ComponentFactory,
    dialogs:Array<any>,
    id?: string,
    item?: AssetModel,
    pictures: any[],
    assetList: Array<AssetModel>,
    assetEvents: Array<AssetEventModel>,
    assetImages: Array<AssetImageModel>,
    assetRelationshipRows: Array<AssetRelationshipModel>,
    notifications: Array<NotificationHistoryModel>,
    history: Array<HistoryModel>,
    fields: any[],
    //productOptions: any[],
    //accountOptions: any[],
    assetTagOptions: any[],
    currentIndex: number,
    didUpdateAddress: boolean,
    hasChanges: boolean,
    isSaving: boolean,
    errorText?: string,
    updatedFields: any[]
}

class ItemPage extends React.Component<IBaseProps, IState> {

    public assetEventDialog = createRef<any>();
    public addAssetRelationshipDialog = createRef<any>();
    public addCommentDialog = createRef<any>();
    public verifyEmailAddressDialog = createRef<any>();
    public updateLocationDialog = createRef<any>();

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        //componentFactory.addDefaultBinding(this.global.data.createAsset, null);

        // binding?

        let dialogs = [
            <AddCommentDialog {...componentFactory.renderDialogProps("Add Comment", "Save", this.saveCommentCallback.bind(this), this.addCommentDialog)}  />,
            <AddAssetRelationshipDialog {...componentFactory.renderDialogProps("Add Asset Relationship", "Save", this.saveAssetRelationshipCallback.bind(this), this.addAssetRelationshipDialog)}  />,
            <VerifyEmailAddressDialog {...componentFactory.renderDialogProps("Verify Email Address", "Save", this.verifyEmailAddressCallback.bind(this), this.verifyEmailAddressDialog)}  />,
            <AddEventDialog {...componentFactory.renderDialogProps("Asset Event", "Save", this.saveAssetEventCallback.bind(this), this.assetEventDialog)}  />,
            <UpdateLocationDialog {...componentFactory.renderDialogProps("Update Location", "Save", this.initView.bind(this), this.updateLocationDialog)}  />,
        ]

        this.state = {
            componentFactory:componentFactory,
            dialogs:dialogs,
            item: {},
            pictures: [],
            fields: [],
            assetList: [],
            assetEvents: [],
            assetImages: [],
            notifications: [],
            assetRelationshipRows: [],
            //productOptions: [],
            //accountOptions: [],
            assetTagOptions: [],
            history: [],
            currentIndex: 0,
            didUpdateAddress: false,
            hasChanges: false,
            isSaving: false,
            updatedFields: []
        };
    }

    componentDidMount() {
        addCallback(global => {
            this.initView();
            return null;
        });

        if (this.global.isDataLoaded) {
            this.initView();
        }
    }

    async initView() {
        let cf = this.state.componentFactory;
        let id = Route.param(this, "id");
        //let item = id ? await this.getItem(id) : { isActive:true };
        let item:AssetModel = await this.global.dataManager.get("getAsset", id);
        console.log(item);

        this.state.componentFactory.addDefaultBinding(this.global.dataManager, "createAsset", item);
        this.state.componentFactory.setHandleUpdateCallback((value: any, key: string) => {
           if (key == "assetAddress") {
               this.setState({didUpdateAddress:true});
           } else if (key == "assetTags" || key == "assetCategories") {
               this.forceUpdate();
           }

           let assetContactPreference = cf.getDefaultBinding().getProperty("assetContactPreference")
           if (!assetContactPreference) {
               if (key == "assetEmailAddress") {
                   cf.getDefaultBinding().setProperty("assetContactPreference", "email");
               }
               if (key == "assetContactPhoneNumber") {
                   cf.getDefaultBinding().setProperty("assetContactPreference", "text");
               }
           }
           let updatedFields = this.state.updatedFields;
           let fieldName = MessageResources.getText(key);
           if (updatedFields.indexOf(fieldName) == -1) {
               updatedFields.push(fieldName);
           }

           this.setState({errorText:null, hasChanges:true, updatedFields:updatedFields});
        });

        //let accountOptions = [];
        //let assetTagOptions = [];
        //let productOptions = [];
        //let products = await this.global.data.getProducts();
        /*let products = await this.global.dataManager.get("getProducts");
        ArrayUtil.sort(products, "productName");

        for (let product of products) {
            productOptions.push(SelectItem.create(product.productName.toLowerCase(), product.productName));
        }

        //let accounts = await this.global.data.getAccounts();
        let accounts = await this.global.dataManager.get("getAccounts");
        ArrayUtil.sort(accounts, "accountName");
        for (let account of accounts) {
            accountOptions.push(SelectItem.create(account.id, account.accountName));
        }*/

        //let assetTags = await this.global.data.getAssetTags();
        //let assetTagGroups = await this.global.data.getAssetTagGroups();
        //let assetTags = await this.global.dataManager.get("getAssetTags");
        //let assetTagGroups = await this.global.dataManager.get("getAssetTagGroups");

        let assetTagOptions = [];
        for (let assetTag of this.global.assetTags) {
            let assetTagGroup = ArrayUtil.getItem(this.global.assetTagGroups, "id", assetTag.assetTagGroupId);
            assetTagOptions.push(SelectItem.create(assetTag.id, assetTagGroup.assetTagGroupName + " - " + assetTag.assetTagName));
        }
        ArrayUtil.sort(assetTagOptions, "label");

        const notifications = await this.global.dataManager.get("getNotificationHistory", item.accountId);

        this.setState({id: id, item:item, assetTagOptions:assetTagOptions, notifications:notifications}, function(this:ItemPage) {
            this.getPictures();
            this.getAssetEvents(id);
            this.getAssetRelationships(id);
            this.getHistory();

        }.bind(this));
    }

    /*
     * Getters
     */

    async getAssetEvents(id:string) {
        //let assetEvents = await this.global.data.getAssetEventsForAsset(id);
        let assetEvents = await this.global.dataManager.get("getAssetEventsForAsset", id);
        ArrayUtil.sort(assetEvents, "assetEventStartDate", true);

        console.log("Got " + assetEvents.length + " asset event");
        this.setState({assetEvents:assetEvents});
    }

    async getAssetRelationships(id:string) {
        //let assetRelationships = await this.global.data.getAssetRelationships(id);
        let assetRelationships = await this.global.dataManager.get("getAssetRelationships", id);

        // only create the asset list if we have asset relationships
        let assets = [];
        if (assetRelationships.length > 0) {
            if (this.global.user.admin) {
                assets = await this.global.dataManager.get("getAssetsForAdmin", {field:InvokeFields.getAssetsForAdmin});
            } else if (this.global.account) {
                assets = await this.global.dataManager.get("getAssets", this.global.account.id);
            }
        }
        console.log("Got " + assetRelationships.length + " asset relationships");
        this.setState({assetRelationshipRows:assetRelationships, assetList:assets});
    }

    async getHistory() {
        let history = await HistoryUtil.getHistory(this.state.item.id, Constants.HISTORY_TYPE_ASSET);
        //let users = await this.global.dataManager.get("getUsers");
        for (let historyItem of history) {
            historyItem["userName"] = ArrayUtil.getItem(this.global.users, "id", historyItem.userId)?.name;
        }
        ArrayUtil.sort(history, "date", true);

        this.setState({history:history});
    }

    async getPictures() {
        //console.log("dir: " + Constants.PUBLIC_DIR + "/" + this.state.id);
        let pictures = await AWSStorage.list(this.state.id);
        let assetImages:Array<AssetImageModel> = [];
        for (let picture of pictures) {
            //let assetImage = await this.global.data.getAssetImage(picture.key);
            let assetImage = await this.global.dataManager.get("getAssetImage", picture.key);
            if (assetImage) {
                assetImages.push(assetImage);
            }
        }

        this.setState({pictures: pictures, assetImages:assetImages});
    }

    /*
     * Picture methods
     */

    async uploadPictureTapped(e:any) {
        if (!e.nativeEvent.target || !e.nativeEvent.target.files) { return }

        console.log("bytes: " + e.nativeEvent.target.files[0].size);
        if(e.nativeEvent.target.files[0].size > 1048576) {
            this.props.base.notify("Image is too big to upload (max 1MB)", "error");
            return;
        }

        const file = e.nativeEvent.target.files[0];
        let fullFileName = this.state.id + "/" + StringUtil.uuid();
        console.log(fullFileName);
        await AWSStorage.put(fullFileName, file);

        this.getPictures();
        this.props.base.notify("Image uploaded");

        // Add history
        let message = "Added image - " + fullFileName;
        await HistoryUtil.createHistory(this.state.id, Constants.HISTORY_TYPE_ASSET, this.global.user.id, message);
        this.getHistory();
    }

    async removePicture(picture:any) {
        console.log(picture);
        console.log("about to delete: " + JSON.stringify(picture));
        await AWSStorage.remove(picture.key);

        let assetImage = ArrayUtil.getItem(this.state.assetImages, "id", picture.key);
        if (assetImage) {
            await this.global.dataManager.delete("deleteAssetImage", assetImage);
        }

        this.getPictures();
        this.props.base.notify("Image deleted");

        // Add history
        let message = "Deleted image - " + picture.key;
        await HistoryUtil.createHistory(this.state.id, Constants.HISTORY_TYPE_ASSET, this.global.user.id, message);
        this.getHistory();
    }

    /*
     * Save / Delete methods
     */

    async saveButtonTapped(e:any) {
        this.setState({hasChanges:false, isSaving:true}, async function(this:ItemPage) {
            //var item = this.state.item;
            let componentBinding = this.state.componentFactory.getDefaultBinding()

            /*if (!this.state.id) {
                componentBinding.setProperty("id", String.uuid());
            }*/
            componentBinding.setProperty("lastUpdated", DateUtil.now());
            console.log(componentBinding.item);

            let accountId = componentBinding.getProperty("accountId");
            if (!accountId) {
                //let account = await UserUtil.getAccountForUser(this.global.user);
                let account = this.global.account;
                accountId = account?.id;
                componentBinding.setProperty("accountId", accountId);
            }
            if (!accountId) {
                //this.props.base.notify("Please selected an account");
                this.setState({errorText:"Please select an account"});
                return;
            }
            await AssetUtil.addAssetFields(componentBinding);

            if (!this.validateFields(componentBinding.item)) {
                return;
            }

            if (this.state.didUpdateAddress) {
                let validateCorrectCounty = await AssetUtil.validateCorrectCounty(componentBinding.item);
                if (!validateCorrectCounty) {
                    this.setState({errorText: "You don't have access to create assets for this county"});
                    return;
                }
            }

            //await this.global.data.createAsset(item);
            componentBinding.saveBinding();
            this.setState({isSaving:false}, function(this:ItemPage) {
                this.props.base.notify("Asset saved successfully. It may take up to 24 hours for your changes to be reflected in the app.");
                this.getHistory()
            }.bind(this));

            // Add history
            let message = "Updated fields - " + this.state.updatedFields.join(",");
            await HistoryUtil.createHistory(this.state.id, Constants.HISTORY_TYPE_ASSET, this.global.user.id, message);

        }.bind(this));
    }

    async deleteItemTapped() {
        await this.global.dataManager.delete("deleteAsset", this.state.item);
        Route.goBack(this);

        this.props.base.notify("Asset deleted");
    }

    /*
     * Validation methods
     */

    validateFields(item:AssetModel):boolean {
        if (!item.assetName || item.assetName == "") {
            this.setState({errorText:"Name is required"});
            //this.props.base.notify("Name is required");
            return false;
        } else if (!item.assetAddress || item.assetAddress == "") {
            this.setState({errorText:"Address is required"});
            //this.props.base.notify("Address is required");
            return false;
        } else if (item.assetPhoneNumber && !Validater.isPhoneNumber(item.assetPhoneNumber)) {
            this.setState({errorText:"Phone number must be in the format XXX-XXX-XXXX"});
            //this.props.base.notify("Phone number must be in the format XXX-XXX-XXXX");
            return false;
        } else if (item.assetEmailAddress && !Validater.isEmailAddress(item.assetEmailAddress)) {
            this.setState({errorText:"Email address must be in the format asset@email.com"});
            //this.props.base.notify("Email address must be in the format asset@email.com");
            return false;
        } else if (item.assetWebsite && !Validater.isURL(item.assetWebsite)) {
            // TODO: we need to mandate http
            this.setState({errorText:"Website must be in the format http://website.com"});
            //this.props.base.notify("Website must be in the format http://website.com");
            return false;
        } else if (item.assetFacebook && !item.assetFacebook.startsWith("https://www.facebook.com")) {
            this.setState({errorText:"Facebook must begin with https://www.facebook.com"});
            //this.props.base.notify("Facebook must begin with https://www.facebook.com");
            return false;
        } else if (item.assetTwitter && !item.assetTwitter.startsWith("https://twitter.com")) {
            this.setState({errorText:"Twitter must begin with https://twitter.com"});
            //this.props.base.notify("Twitter must begin with https://twitter.com");
            return false;
        } else if (item.assetInstagram && !item.assetInstagram.startsWith("https://www.instagram.com")) {
            this.setState({errorText:"Instagram must begin with https://www.instagram.com"});
            //this.props.base.notify("Instagram must begin with https://www.instagram.com");
            return false;
        } else if (this.hasOnlineStoreAssetTag() && (!item.assetOnlineStore && item.assetOnlineStore == "")) {
            this.setState({errorText:"Online Store website is required when adding the online store asset tag"});
            return false;
        }

        return true;
    }

    /*
     * Callback methods
     */

    saveCommentCallback(item:any) {
        this.props.base.notify("Image saved");
        this.getPictures();
    }

    async saveAssetRelationshipCallback(item:AssetRelationshipModel) {
        item.assetId = this.state.item.id;
        await this.global.dataManager.save("createAssetRelationship", item);

        this.props.base.notify("asset relationship saved");
        this.getAssetRelationships(this.state.item.id);

        // Add history
        let message = "Added relationship - " + item.relationshipDescription;
        await HistoryUtil.createHistory(this.state.id, Constants.HISTORY_TYPE_ASSET, this.global.user.id, message);
        this.getHistory();
    }

    async openSendMessageDialog(type:string) {
        LocalCache.saveSession("ids", this.state.item.id);
        Route.go(this, 'sendMessage/asset');

        /*let input:VerifyEmailAddressInput = {
            emailAddress: this.global.user.email,
            field: InvokeFields.isEmailAddressVerified
        };
        let isVerified = await this.global.dataManager.perform("isEmailAddressVerified", input);
        if (isVerified["success"] == "true") {
            LocalCache.saveSession("ids", this.state.item.id);
            Route.go(this, 'sendMessage/asset');
        } else {
            DialogUtil.open(this.verifyEmailAddressDialog.current);
        }*/
    }

    async deleteAssetRelationshipTapped(item:AssetRelationshipModel) {
        await this.global.dataManager.delete("deleteAssetRelationship", item);

        this.getAssetRelationships(this.state.id);
        this.props.base.notify("Asset relationship deleted");

        // Add history
        let message = "Deleted relationship - " + item.relationshipDescription;
        await HistoryUtil.createHistory(this.state.id, Constants.HISTORY_TYPE_ASSET, this.global.user.id, message);
        this.getHistory();
    }

    hasOnlineStoreAssetTag():boolean {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let assetTags = componentBinding.getProperty("assetTags");
        if (assetTags) {
            let assetTagOnlineStoreId;
            for (let assetTagOption of this.state.assetTagOptions) {
                if (assetTagOption.label.indexOf("Online Store") > -1) {
                    assetTagOnlineStoreId = assetTagOption.value;
                }
            }
            //console.log(assetTagOnlineStoreId);
            if (assetTagOnlineStoreId) {
                return assetTags.indexOf(assetTagOnlineStoreId) > -1;
            }
        }
        return false;
    }

    hasPickYourOwnCategory():boolean {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let assetCategories = componentBinding.getProperty("assetCategories");
        if (assetCategories) {
            return assetCategories.indexOf("pickYourOwn") > -1;
        }
        return false;
    }

    /*
     * Dialog callback
     */

    verifyEmailAddressCallback(item:any) {
        this.props.base.notify("An email has been sent to you. Please check your inbox for more instructions.");
    }

    /*
     * Menu item actions
     */

    performActionForItem(index:any) {
        if (index == 0) {
            this.openSendMessageDialog("email");
        } else if (index == 1) {
            //DialogUtil.open(this.assetDeleteDialog.current)
            this.props.base.alert("Confirm Delete", "Are you sure you want to delete this asset? This action cannot be undone.", this.deleteItemTapped.bind(this), this.state.item);
        }
    }

    emailAssetAction() {
        this.openSendMessageDialog("email");
    }

    deleteAssetAction() {
        this.props.base.alert("Confirm Delete", "Are you sure you want to delete this asset? This action cannot be undone.", this.deleteItemTapped.bind(this), this.state.item);
    }

    /*
     * Render methods
     */

    renderAssetDetails() {
        if (!this.global.isDataLoaded) return null;
        if (!this.state.componentFactory.getDefaultBinding()) return null;

        let assetOptions = Constants.ASSET_GROUP_CATEGORIES;
        ArrayUtil.sort(assetOptions, "label");

        let cf = this.state.componentFactory;

        return (
            cf.renderHorizontalLayout([cf.renderBox([
                this.state.errorText ? <Alert severity="error">{this.state.errorText}</Alert> : null,
                //cf.renderBoxProps({padding:'8px', background:BaseColor.MAIN_BACKGROUND}, cf.renderBoxPadded(banner)),
                this.global.user?.admin ? cf.renderBoxPadded(cf.renderSelectField("accountId", "Account", this.global.accountOptions)) : null,
                cf.renderBoxPadded(cf.renderTextField("assetName", "Name", null, null, {required:true})),
                cf.renderBoxPadded(cf.renderTextField("assetAddress", "Address (123 Main Street, Chapel Hill, NC)", null, null, {required:true})),
                cf.renderBoxPadded(cf.renderTextField("assetPhoneNumber", "Phone Number (XXX-XXX-XXXX)")),
                cf.renderBoxPadded(cf.renderTextField("assetWebsite", "Website (http://website.com)")),
                cf.renderBoxPadded(cf.renderTextField("assetOwnerName", "Owner Name")),
                cf.renderBoxPadded(cf.renderSelectField("assetContactPreference", "Owner Contact Preference", contactPreferences)),
                cf.renderBoxPadded(cf.renderTextField("assetEmailAddress", "Owner Email Address (asset@email.com)")),
                cf.renderBoxPadded(cf.renderTextField("assetContactPhoneNumber", "Owner Phone Number (XXX-XXX-XXXX)")),
                cf.renderBoxPadded(cf.renderSelectField("assetPaymentMethods", "Payment Options", paymentMethods, null, null, {multiple:true})),
                cf.renderBoxPadded(cf.renderTextField("assetDescription", "Description", null, null, {type:"textarea", multiline:true})),
                cf.renderBoxPadded(cf.renderSelectField("assetProducts", "Farm Products", this.global.productOptions, null, null, {multiple:true})),
                cf.renderBoxPadded(cf.renderTextField("assetHours", "Hours", null, null, {maxLength:100})),
                cf.renderBoxPadded(cf.renderTextField("assetFacebook", "Facebook")),
                cf.renderBoxPadded(cf.renderTextField("assetTwitter", "Twitter")),
                cf.renderBoxPadded(cf.renderTextField("assetInstagram", "Instagram")),
                cf.renderBoxPadded(cf.renderSelectField("assetCategories", "Asset Categories", assetOptions, null, null, {multiple:true})),
                this.hasPickYourOwnCategory() ? cf.renderBoxPadded(cf.renderSelectField("assetUPickProducts", "Pick Your Own Products", Constants.PICK_YOUR_OWN_CATEGORIES, null, null, {multiple:true})) : null,
                cf.renderBoxPadded(cf.renderSelectField("assetTags", "Asset Tags", this.state.assetTagOptions, null, null, {multiple:true})),
                this.hasOnlineStoreAssetTag() ? cf.renderBoxPadded(cf.renderTextField("assetOnlineStore", "Online Store (http://square.com)")) : null,
                cf.renderBoxPadded(cf.renderSwitchField("isActive", "Active"))
            ]), cf.renderBox([
                this.state.item && this.state.item.assetLongitude ? <img src={MapUtil.getMapForPoint(this.state.item.assetLatitude, this.state.item.assetLongitude, 400, 400)}/> : null,
                cf.renderBox([cf.renderButton("Contact Asset", this.emailAssetAction.bind(this)), cf.renderButton("Delete Asset", this.deleteAssetAction.bind(this), {style:{marginLeft:"10px"}, secondary:true, primary:false})])
            ])], ["70%","30%"])
        )
    }

    // Asset Events

    async saveAssetEventCallback(item:AssetEventModel) {
        item.assetId = this.state.item.id;
        await this.global.dataManager.save("createAssetEvent", item);

        this.props.base.notify("event saved");
        this.getAssetEvents(this.state.item.id);

        // Add history
        let message = "Added event - " + item.assetEventTitle;
        await HistoryUtil.createHistory(this.state.id, Constants.HISTORY_TYPE_ASSET, this.global.user.id, message);
        this.getHistory();
    }

    async deleteAssetEvent(item:AssetEventModel) {
        await this.global.dataManager.delete("deleteAssetEvent", item);

        this.getAssetEvents(this.state.id);
        this.props.base.notify("Event deleted");

        // Add history
        let message = "Deleted event - " + item.assetEventTitle;
        await HistoryUtil.createHistory(this.state.id, Constants.HISTORY_TYPE_ASSET, this.global.user.id, message);
        this.getHistory();
    }

    renderAssetEventRow(item:AssetEventModel) {
        let editFunction = Route.goFunction(this, 'assetEvent/' + item.id + "/update");
        let deleteFunction = () => {
            if (!NotificationUtil.doesAssetEventHaveNotification(item, this.state.notifications)) {
                this.props.base.alert("Confirm Delete", "Are you sure you want to delete this event? This action cannot be undone.", this.deleteAssetEvent.bind(this), item);
            } else {
                this.props.base.notify("Cannot delete event because there is a push notification associated with it.", "error");
            }
        }
        let actions = this.state.componentFactory.renderEditDeleteButtons(editFunction,deleteFunction);

        return [
            item.assetEventTitle,
            DateUtil.format(item.assetEventStartDate, "MM/DD/YYYY"),
            DateUtil.format(item.assetEventEndDate, "MM/DD/YYYY"),
            !AssetEventUtil.hasAssetEventPassed(item) ? actions : null
        ]
    }

    renderAssetEvents() {
        let cf = this.state.componentFactory;
        let newButton = cf.renderButton("New Event", Route.goFunction(this, 'assetEvent/' + this.state.id + "/create"));
        return ([
            VisualUtil.renderActionBar(null, newButton),
            cf.renderLine(),
            cf.renderTable(["Event Title", "Start Date", "End Date", ""], this.state.assetEvents, this.renderAssetEventRow.bind(this))
        ])
    }

    //
    // Asset Relationships
    //

    renderAssetRelationshipRow(item:AssetRelationshipModel) {
        let editFunction = DialogUtil.openFunction(this.addAssetRelationshipDialog.current, item);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this asset relationship? This action cannot be undone.", this.deleteAssetRelationshipTapped.bind(this), item);
        let actions = this.state.componentFactory.renderEditDeleteButtons(editFunction,deleteFunction);

        let relationshipAssetList = item.relationshipAssets.split(",");
        let relationshipAssetNameList = [];
        for (let relationshipAsset of relationshipAssetList) {
            let asset = ArrayUtil.getItem(this.state.assetList, "id", relationshipAsset);
            relationshipAssetNameList.push(asset?.assetName || relationshipAsset);
        }

        return [
            item.relationshipDescription,
            relationshipAssetNameList.join(", "),
            actions
        ]
    }

    renderAssetRelationships() {
        let cf = this.state.componentFactory;
        return ([
            VisualUtil.renderActionBar(null, cf.renderButton("New Asset Relationship", DialogUtil.openFunction(this.addAssetRelationshipDialog.current))),
            cf.renderLine(),
            cf.renderTable(["Label", "Assets", ""], this.state.assetRelationshipRows, this.renderAssetRelationshipRow.bind(this), {fitToContent:true})
        ])
    }

    //
    // Asset Images
    //

    handleSwitchChanged(key) {
        console.log(key);
        let binding = this.state.componentFactory.getDefaultBinding();
        binding.setProperty("primaryImage", key);

        // TODO: more validation

        binding.saveBinding();
        this.props.base.notify("Primary image updated");
    }

    renderAssetImageRow(picture:any) {
        let cf = this.state.componentFactory;
        let editFunction = DialogUtil.openFunction(this.addCommentDialog.current, picture.key);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this comment? This action cannot be undone.", this.removePicture.bind(this), picture);
        let actions = cf.renderEditDeleteButtons(editFunction,deleteFunction);

        let assetImage = ArrayUtil.getItem(this.state.assetImages, "id", picture.key);
        let binding = this.state.componentFactory.getDefaultBinding();
        let primaryImage = binding.getProperty("primaryImage");
        return [
            <Imgix height={100} width={100} src={ImageUtil.fetch(picture.key)} />,
            assetImage ? assetImage.comment : "",
            assetImage ? assetImage.isActive ? <span className="active">ACTIVE</span> : <span className="inactive">INACTIVE</span> : <span className="active">ACTIVE</span>,
            <SwitchComponent id="assetSwitch" primary checked={primaryImage == picture.key} onChange={(e:any) => this.handleSwitchChanged(picture.key)} />,
            actions
        ];
    }

    renderAssetImages() {
        let cf = this.state.componentFactory;
        const headers = ["Image", "Comment", "Active", "Primary", "Action"];
        const headerRowContent = [<ButtonComponent primary disabled={this.state.pictures.length >= Constants.MAX_NUMBER_OF_ASSET_IMAGES} title="Upload" forUpload={true} uploadAccept="images" onTouchTap={this.uploadPictureTapped.bind(this)} />]

        return ([
            VisualUtil.renderActionBar(null, headerRowContent),
            cf.renderLine(),
            cf.renderTable(headers, this.state.pictures, this.renderAssetImageRow.bind(this))
        ])
    }

    // Render User History

    renderHistoryRow(item:HistoryModel) {
        return [
            item.content,
            DateUtil.format(item.date, "MMMM DD, YYYY hh:mm a"),
            item["userName"]
        ]
    }

    renderHistory() {
        let cf = this.state.componentFactory;
        return (
            cf.renderTable(["Action", "Date", "User"], this.state.history, this.renderHistoryRow.bind(this))
        )
    }

    render() {
        let cf = this.state.componentFactory;

        const tabs = ["Details", "Events", "Relationships", "Images", "History"];
        const actions = [
            cf.renderButton("Save", this.saveButtonTapped.bind(this), {disabled: !this.state.hasChanges}),
            cf.renderBoxProps({marginLeft: '20px'}, null),
            this.global.user?.admin ? cf.renderButton("Update Location", DialogUtil.openFunction(this.updateLocationDialog.current, cf.getDefaultBinding()?.item), {isFlat: true}) : null,
        ];

        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                this.state.dialogs,
                cf.renderBoxProps({marginLeft: '90px'}, [
                    !this.global.isDataLoaded ?
                        VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                            :
                            [VisualUtil.getHeaderDisplay("Edit Asset", actions),
                                cf.renderLine(),
                                cf.renderTabs(tabs, [
                                    this.renderAssetDetails(),
                                    this.renderAssetEvents(),
                                    this.renderAssetRelationships(),
                                    this.renderAssetImages(),
                                    this.renderHistory()
                                ])
                            ]
                ])
            ])
        )
    }
}

export default base(ItemPage, Sidebar)