import * as React from 'reactn';
import {ComponentFactory, dialog, IBaseDialogProps} from "oca-lib-web";
import ComponentUtil from "../util/ComponentUtil";
import ReactGA from "react-ga";
import Styles from "../style/Styles";

interface IState {
    //productGroupModel?:ProductGroupModel,
    componentFactory:ComponentFactory
}

class AddProductGroupDialog extends React.Component<IBaseDialogProps, IState> {

    public components = ComponentUtil.createRefs(2);

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(this.global.dataManager, "createProductGroup", props.param);

        this.state = {
            //productGroupModel: props.param ? props.param : {},
            componentFactory: componentFactory
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/admin/productGroupDialog');
    }

    async handleSave(callback:any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) { return; }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        callback(componentBinding.item);
    }

    render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox(
                cf.renderBoxPadded([
                    cf.renderTextField("productGroupName", "Product Group Name", this.components[0], null, {required:true})
                ])
            )
        )
    }
}

export default dialog(AddProductGroupDialog)