import * as React from 'reactn';
import {base, BaseColor, IBaseProps, ComponentFactory} from "oca-lib-web";
import logo from '../../image/visit_nc_farms_logo.png';
import AWSAuth from "../../auth/aws/AWSAuth";
import loginHero from '../../image/heroLogin.png';
import { ForgotPassword } from "aws-amplify-react";
import { getGlobal } from 'reactn';
import Styles from "../../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    emailAddress?: string,
    delivery?: any
}

class ForgotPasswordPage extends ForgotPassword<IBaseProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(getGlobal().dataManager, null, {});
        this.state = {
            componentFactory: componentFactory
        };
    }

    // Events

    // Event Handlers

    public async sendPasswordTapped() {
        let cf = this.state.componentFactory;
        try {
            //const username = this.emailAddressComponent.current ? this.emailAddressComponent.current.getValue() : "";
            let emailAddress = cf.getDefaultBinding().getProperty("emailAddress");
            console.log("Sending forgot password for : " + emailAddress);

            let delivery = await AWSAuth.forgotPassword(emailAddress);
            this.setState({emailAddress:emailAddress, delivery:delivery});
            //sessionStorage.setItem("username", userName);
            //this.props.onStateChange("requireNewPassword");

        } catch (err) {
            console.log(err);
            // @ts-ignore
            this.props.base.notify(err.message);
        }
    };

    public async confirmPasswordTapped() {
        let cf = this.state.componentFactory;
        let confirmationCode = cf.getDefaultBinding().getProperty("confirmationCode");
        let password = cf.getDefaultBinding().getProperty("password");
        try {
            await AWSAuth.forgotPasswordConfirm(this.state.emailAddress, confirmationCode, password);
            this.props.onStateChange("signIn");

        } catch (err) {
            console.log(err);
            // @ts-ignore
            this.props.base.notify(err.message);
        }
    }

    // Render

    sendView() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                <h2 className="loginTextLight" style={{marginTop:'20px', marginBottom:'20px'}}>We'll send you an email with instructions on how to reset it.</h2>,
                cf.renderBoxPadded(cf.renderTextField("emailAddress", "Email Address", null, null, {required:true})),
                cf.renderBoxPadded(cf.renderButton("Send Email", this.sendPasswordTapped.bind(this), {primary:true, disableElevation:true, width:'100%'}))
            ])
        )
    }

    submitView() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                <h2 className="loginTextLight" style={{marginTop:'20px', marginBottom:'20px'}}>Enter the code that was sent to your email address and your new password</h2>,
                cf.renderBoxPadded(cf.renderTextField("confirmationCode", "Confirmation Code", null, null, {required:true})),
                cf.renderBoxPadded(cf.renderTextField("password", "Password", null, null, {required:true, type:'password'})),
                cf.renderBoxPadded(cf.renderButton("Reset Password", this.confirmPasswordTapped.bind(this), {primary:true, disableElevation:true, width:'100%'}))
            ])
        )
    }

    showComponent(theme:any) {
        let cf = this.state.componentFactory;
        return (
            cf.renderBoxProps({fullHeight:true, background:BaseColor.WHITE}, [
                cf.renderHorizontalLayout([
                    <img src={loginHero} width="100%" />,
                    cf.renderBoxProps({pad:true, center:true, width:'600px', marginLeft:'auto', marginRight:'auto'},[
                        cf.renderBoxPadded([
                            cf.renderBoxPadded(<img src={logo} width={125} className="center-img" />),
                            this.state.delivery ? this.submitView() : this.sendView()
                        ])
                    ])
                ], ['33%','67%'])
            ])
        )
    }
}

export default base(ForgotPasswordPage)