import PapaParse from "papaparse";
import {ArrayUtil, DateUtil, File, Http} from "oca-lib-web";
import AssetUtil from "./AssetUtil";
import InvokeFields from "./InvokeFields";
import { getGlobal } from 'reactn';
import Constants from "./Constants";

export default class ImportExportUtil {

    // Import

    public static async importData(file:any, accountId:string) {
        var self = this;
        let assetTags = await getGlobal().dataManager.get("getAssetTags");

        console.log("about to parse data");
        PapaParse.parse(file, {
            header: true,
            skipEmptyLines: true,
            complete: async function(results:any) {
                console.log(results);

                var fileData = results.data;

                console.log("File data to submit:");
                console.log(fileData);

                var assets = [];
                for (let dataItem of fileData) {
                    if (!self.allFieldsEmpty(dataItem)) {
                        //await AssetUtil.addFields(dataItem, accountId);
                        let geoCodingAPI = Constants.GEOCODING_API.replace("{0}", dataItem["assetAddress"]);
                        let data = await Http.get(geoCodingAPI);
                        let results = data["results"];
                        if (results && results.length > 0) {
                            let location = results[0]["geometry"]["location"];
                            console.log("Found location: " + location.lat, location.lng);
                            dataItem["assetLatitude"] = location.lat;
                            dataItem["assetLongitude"] = location.lng;
                            dataItem["assetCounty"] = await AssetUtil.getAssetCounty(location.lat, location.lng);
                            if (!dataItem["assetCounty"] || dataItem["assetCounty"] == "") {
                                console.log("Could not find county for " + dataItem["assetName"]);
                            } else {
                                console.log("Found county: " + dataItem["assetCounty"]);
                            }
                        } else {
                            console.error("Could not find location");
                            console.log(dataItem["assetAddress"]);
                        }
                        if (dataItem["assetEmailAddress"] && dataItem["assetEmailAddress"] != "") {
                            dataItem["assetContactPreference"] = "email";
                        }
                        dataItem["accountId"] = accountId;
                        dataItem["isActive"] = true;
                        dataItem["lastUpdated"] = DateUtil.now();

                        assets.push(self.convertAssetTags(self.removeListSpaces(self.convertProductsToLowercase(self.convertEmptyToNullColumns(dataItem))), assetTags));
                    }
                }

                console.log(assets);

                let input = {
                    field: InvokeFields.importAssets,
                };
                input["assets"] = assets;
                console.log(input);

                /*for (let asset of assets) {
                    await getGlobal().dataManager.save("createAsset", asset);
                }*/
                await getGlobal().dataManager.save("createAssetBatch", input);
            }
        });
    }

    private static allFieldsEmpty(dataItem:any): boolean {
        for (let column of this.getColumnNames()) {
            if (dataItem[column]?.length > 0) {
                return false;
            }
        }
        return true;
    }

    /*private static removeInvalidCategoriesAndProducts(dataItem:any) {
        for (let column of this.getColumnNames()) {
            if (column == "assetProducts" || column == "assetCategories") {
                if (dataItem[column]) {

                }
            }
        }
        return dataItem;
    }*/

    private static removeListSpaces(dataItem:any):any {
        for (let column of this.getColumnNames()) {
            if (column == "assetProducts" || column == "assetCategories" || column == "assetPaymentMethods" || column == "assetTags") {
                if (dataItem[column]) {
                    let dataItemList = dataItem[column].split(", ");
                    dataItem[column] = dataItemList.join(',');
                }
            }
        }
        return dataItem;
    }

    private static convertAssetTags(dataItem:any, assetTags:Array<AssetTagModel>):any {
        //console.log(assetTags);
        for (let column of this.getColumnNames()) {
            if (column == "assetTags" && dataItem[column] && dataItem[column] != "") {
                let assetTagNames = dataItem[column].split(",");
                //console.log(assetTagNames);
                let assetTagIds = [];
                for (let assetTagName of assetTagNames) {
                    let assetTagItem = null;
                    for (let assetTag of assetTags) {
                        //console.log(assetTag.assetTagName.toLowerCase() + "-" + assetTagName.toLowerCase());
                        if (assetTag.assetTagName.toLowerCase() == assetTagName.toLowerCase()) {
                            assetTagItem = assetTag;
                        }
                    }
                    if (assetTagItem) {
                        assetTagIds.push(assetTagItem.id);
                    }
                }
                dataItem[column] = assetTagIds.join(",");
            }
        }
        return dataItem;
    }

    private static convertAssetTagIdsToLabels(dataItem:any, assetTags:Array<AssetTagModel>):any {
        if (!dataItem || dataItem == "") { return "" }

        let assetTagIds = dataItem.split(",");
        let assetTagNames = [];
        for (let assetTagId of assetTagIds) {
            let assetTag:AssetTagModel = ArrayUtil.getItem(assetTags, "id", assetTagId);
            if (assetTag) {
                assetTagNames.push(assetTag.assetTagName);
            }
        }
        return assetTagNames.join(",");
    }

    private static convertProductsToLowercase(dataItem:any):any {
        for (let column of this.getColumnNames()) {
            if (column == "assetProducts" || column == "assetPaymentMethods") {
                dataItem[column] = dataItem[column] ? dataItem[column].toLowerCase() : dataItem[column];
            }
        }
        return dataItem;
    }

    private static convertEmptyToNullColumns(dataItem:any) {
        for (let column of this.getColumnNames()) {
            dataItem[column] = dataItem[column]?.trim();
            if (dataItem[column] == '') {
                dataItem[column] = null;
            }
        }
        return dataItem;
    }

    // Export

    public static async exportAssets(rows:Array<any>) {
        let assetTags = await getGlobal().dataManager.get("getAssetTags");
        let headerFields = this.getColumnNames();
        headerFields.push(... ["assetLatitude", "assetLongitude"]);
        let userData = [];

        for (let row of rows) {
            let item = [];
            for (let column of headerFields) {
                if (column == "assetTags") {
                   item.push(this.convertAssetTagIdsToLabels(row[column], assetTags));
                } else {
                    item.push(row[column]);
                }
            }

            userData.push(item);
        }

        var csv = PapaParse.unparse({
            fields: headerFields,
            data: userData
        });

        let filename = "asset_export.csv";
        File.download(csv, filename);
    }

    public static exportAssetTemplate() {
        var csv = PapaParse.unparse({
            fields: this.getColumnNames(),
            data: [this.getColumnSampleValues()]
        });

        let filename = "visitncfarms_asset_template.csv";
        File.download(csv, filename);
    }

    // TODO: Update these
    public static getColumnNames(): string[] {
        let headerFields:string[] = ["assetName",
            "assetAddress",
            "assetPhoneNumber",
            "assetWebsite",
            "assetEmailAddress",
            "assetDescription",
            "assetProducts",
            "assetHours",
            "assetFacebook",
            "assetTwitter",
            "assetInstagram",
            "assetCategories",
            "assetTags",
            "assetPaymentMethods"];

        return headerFields;
    }

    public static getColumnSampleValues(): string[] {
        let sampleValues:string[] = ["",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "",
            "farmFisheries,pickYourOwn,farmersMarket,localFoodDrink,localShops,farmStaysLodging,toursTrails",
            "",
            "cash,check,credit,ebt"];
        return sampleValues;
    }
 }