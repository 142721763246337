import {Auth} from 'aws-amplify';

export default class AWSAuth {

    public static signIn(username: string, password: string): Promise<void> {
        return new Promise((resolve, reject) => {
            Auth.signIn(username, password)
                .then((user:any) => {
                    console.log(user);
                    resolve(user);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public static signOut(): Promise<void> {
        return new Promise((resolve, reject) => {
            Auth.signOut()
                .then((data:any) => {
                    console.log(data);
                    resolve(data);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public static signUp(username: string, password: string, attributes?: any): Promise<void> {
        return new Promise((resolve, reject) => {
            Auth.signUp({
                username,
                password,
                attributes
            })
                .then((data:any) => {
                    console.log(data);
                    resolve(data);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public static signUpConfirm(username: string, code: string): Promise<void> {
        return new Promise((resolve, reject) => {
            Auth.confirmSignUp(username, code)
                .then((data:any) => {
                    console.log(data);
                    resolve(data);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public static resendConfirm(username: string): Promise<void> {
        return new Promise((resolve, reject) => {
            Auth.resendSignUp(username)
                .then((data:any) => {
                    console.log(data);
                    resolve(data);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public static forgotPassword(username: string): Promise<void> {
        return new Promise((resolve, reject) => {
            Auth.forgotPassword(username)
                .then((data:any) => {
                    console.log(data);
                    resolve(data);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public static forgotPasswordConfirm(username: string, code: string, newPassword: string): Promise<void> {
        return new Promise((resolve, reject) => {
            Auth.forgotPasswordSubmit(username, code, newPassword)
                .then((data:any) => {
                    console.log(data);
                    resolve(data);
                })
                .catch((err:any) => {
                    console.log(err);
                    reject(err);
                });
        });
    }

    public static getCurrentUserInfo(): Promise<any> {
        return new Promise((resolve, reject) => {
            const currentUserInfo = Auth.currentUserInfo();
            resolve(currentUserInfo);
        });
    }

    public static getCurrentAuthenticatedUser(): Promise<any> {
        return new Promise((resolve, reject) => {
            const user = Auth.currentAuthenticatedUser();
            resolve(user);
        });
    }
}