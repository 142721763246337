import * as React from 'reactn';
import {base, BaseColor, IBaseProps, ComponentFactory, LocalCache} from "oca-lib-web";
import logo from '../../image/visit_nc_farms_logo.png';
import AWSAuth from "../../auth/aws/AWSAuth";
import loginHero from '../../image/heroLogin.png';
import { ConfirmSignUp } from "aws-amplify-react";
import { getGlobal } from 'reactn';
import Styles from "../../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    admin?:UserModel,
    account?:AccountModel
}

class SignupConfirmPage extends ConfirmSignUp<IBaseProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(getGlobal().dataManager, null, {});
        this.state = {
            componentFactory: componentFactory
        };
    }

    // Init

    componentDidMount() {
    }

    async confirmUserButtonTapped(e:any) {
        try {
            const binding = this.state.componentFactory.getDefaultBinding();
            const emailAddress = LocalCache.getSession("emailAddress");
            const confirmationCode = binding.getProperty("adminConfirmationCode");

            this.props.base.wait();

            await AWSAuth.signUpConfirm(emailAddress, confirmationCode);

            this.props.base.done();
            this.props.onStateChange('signIn');

        } catch (err) {
            console.log(err);

            this.props.base.done();
            this.props.base.notify(err.message);
        }

        //Route.go(this, "patient")
    }

    // Render

    showComponent(theme:any) {
        let cf = this.state.componentFactory;
        const emailAddress = LocalCache.getSession("emailAddress");
        return (
            cf.renderBoxProps({fullHeight:true, background:BaseColor.WHITE}, [
                cf.renderHorizontalLayout([
                    <img src={loginHero} width="100%" />,
                    cf.renderBoxProps({pad:true, center:true, width:'600px', marginLeft:'auto', marginRight:'auto'},[
                        cf.renderBoxPadded([
                            cf.renderBoxPadded(<img src={logo} width={125} className="center-img" />),
                            <h2 className="loginTextLight" style={{marginTop:'20px', marginBottom:'20px'}}>Please enter the confirmation code that was sent to <span style={{color:'blue', fontWeight:500}}>{emailAddress}</span></h2>,
                            cf.renderBoxPadded(cf.renderTextField("adminConfirmationCode", "Confirmation Code", null, null, {required:true})),
                            cf.renderBoxPadded(cf.renderButton("Confirm", this.confirmUserButtonTapped.bind(this), {primary:true, disableElevation:true, width:'100%'}))
                        ])
                    ])
                ], ['33%','67%'])
            ])
        )
    }

    /*public render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBoxProps({fullHeight:true, center:true, background:BaseColor.MAIN_BACKGROUND}, [
                cf.renderBoxProps({pad:true, center:true, width:'500px', marginLeft:'auto', marginRight:'auto'},[
                    cf.renderPaper([
                        cf.renderBoxPadded([
                            cf.renderBoxPadded(<img src={logo} className="center-img" />),
                            <h1 className="sidebarTitle">Wellbeing Monitor</h1>,
                            <h2 className="sidebarSubtitle" style={{marginTop:'50px', marginBottom:'20px'}}>Please enter the code that was sent to your email address</h2>,

                            cf.renderBoxPadded(cf.renderTextField("adminConfirmationCode", "Confirmation Code", null, null, {required:true})),
                            cf.renderBoxPadded(cf.renderButton("Confirm", this.confirmUserButtonTapped.bind(this), {primary:true}))
                        ])
                    ])
                ])
            ])
        )
    }*/
}

export default base(SignupConfirmPage)