import * as React from 'reactn';
import {base, BaseColor, IBaseProps, ComponentFactory, LocalCache, P} from "oca-lib-web";
import logo from '../../image/visit_nc_farms_logo.png';
import AWSAuth from "../../auth/aws/AWSAuth";
import loginHero from '../../image/heroLogin.png';
import { SignUp } from "aws-amplify-react";
import { getGlobal } from 'reactn';
import Styles from "../../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    admin?:UserModel,
    account?:AccountModel
}

class SignupPage extends SignUp<IBaseProps, IState> {

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.addDefaultBinding(getGlobal().dataManager, null, {});
        this.state = {
            componentFactory: componentFactory
        };
    }

    // Init

    componentDidMount() {
        /*addCallback(global => {
            this.fetchData()
            return null;
        });
        if (this.global.isDataLoaded) {
            this.fetchData();
        }*/
        this.fetchData();
    }

    async fetchData() {
        console.log("about to fetch")
        /*let id = Route.param(this, "id");
        let admin:UserModel = await this.global.dataManager.get("getAdmin", id);
        let account:AccountModel = await this.global.dataManager.get("getAccount", admin.accountId);
        console.log(admin);
        this.setState({admin:admin, account:account});
        this.state.componentFactory.addDefaultBinding(this.global.dataManager, "createAdmin", admin);*/
        this.forceUpdate();
    }

    async saveUserButtonTapped(e:any) {
        console.log("about to save user");
        this.props.base.wait();

        //let cf = this.state.componentFactory;
        let user = await this.signup();
        if (user) {
            //let userSub = user.userSub;
            console.log(user);

            //let updatedUser = { ...cf.getDefaultBinding().item}
            //updatedUser.id = userSub;
            //updatedUser.adminPassword = undefined;

            // create user with updated information
            //await this.global.dataManager.save("createAdmin", updatedUser);

            // delete old user
            //await getGlobal().dataManager.delete("deleteAdmin", cf.getDefaultBinding().item);

            //Route.go(this, 'newUserConfirm');
            this.props.onStateChange('confirmSignUp');
        }

        this.props.base.done();
    }

    async signup():Promise<any> {
        let binding = this.state.componentFactory.getDefaultBinding();
        try {
            let emailAddress = binding.getProperty("adminEmailAddress");
            let password = binding.getProperty("adminPassword");
            console.log("Signing up with: " + emailAddress);

            let attributes = {}
            attributes["custom:adminFirstName"] = binding.getProperty("adminFirstName");
            attributes["custom:adminLastName"] = binding.getProperty("adminLastName");
            attributes["custom:adminPhoneNumber"] = binding.getProperty("adminPhoneNumber");
            //attributes["custom:accountId"] = this.state.account.id;

            let user = await AWSAuth.signUp(emailAddress, password, attributes);
            LocalCache.saveSession("emailAddress", emailAddress);

            return P.resolve(user);

        } catch (err) {
            console.log(err);
            this.props.base.done();
            this.props.base.notify(err.message, "error");

            return P.resolve(null);
        }
    }

    // Render

    showComponent(theme:any) {
        let cf = this.state.componentFactory;
        return (
            cf.renderBoxProps({fullHeight:true, background:BaseColor.WHITE}, [
                cf.renderHorizontalLayout([
                    <img src={loginHero} width="100%" />,
                    cf.renderBoxProps({pad:true, center:true, width:'600px', marginLeft:'auto', marginRight:'auto'},[
                        cf.renderBoxPadded([
                            cf.renderBoxPadded(<img src={logo} width={125} className="center-img" />),
                            <h2 className="loginTextLight" style={{marginTop:'20px', marginBottom:'20px'}}>Please enter your information below to create your account. After creating your account, you will need to reach out to a Visit NC Farms administrator to gain access to assets for your region or county.</h2>,
                            cf.renderHorizontalLayout([
                                    cf.renderBoxPadded(cf.renderTextField("adminFirstName", "First Name", null, null, {required:true})),
                                    cf.renderBoxPadded(cf.renderTextField("adminLastName", "Last Name", null, null, {required:true}))
                                ], ['50%','50%']
                            ),
                            cf.renderBoxPadded(cf.renderTextField("adminEmailAddress", "Email Address", null, null)),
                            cf.renderBoxPadded(cf.renderTextField("adminPhoneNumber", "Phone Number", null, null, {required:true})),
                            cf.renderBoxPadded(cf.renderTextField("adminPassword", "Password", null, null, {required:true, type:'password'})),
                            cf.renderBoxPadded(cf.renderButton("Signup", this.saveUserButtonTapped.bind(this), {primary:true, disableElevation:true, width:'100%'}))
                        ])
                    ])
                ], ['33%','67%'])
            ])
        )
    }
}

export default base(SignupPage)