import * as React from 'reactn';
import {
    ArrayUtil,
    base,
    BaseColor, BaseIcon,
    ButtonComponent, DialogUtil, File, IBaseProps, IconButtonComponent,
    LocalCache, MenuButtonComponent, SelectComponent, Route, ComponentFactory, SelectItem
} from "oca-lib-web";
import {createRef} from "react";
import AddProductDialog from "../component/AddProductDialog";
import PapaParse from "papaparse";
import AddAccountDialog from "../component/AddAccountDialog";
import AddProductGroupDialog from "../component/AddProductGroupDialog";
import AddUserDialog from "../component/AddUserDialog";
import Sidebar from "../component/Sidebar";
import AddAssetTagDialog from "../component/AddAssetTagDialog";
import AddAssetTagGroupDialog from "../component/AddAssetTagGroupDialog";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {addCallback} from "reactn";
import ImportExportUtil from "../util/ImportExportUtil";
import moment from "moment";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import InvokeFields from "../util/InvokeFields";

interface IState {
    componentFactory: ComponentFactory,
    dialogs:Array<any>,
    userRows: Array<UserModel>
    accountRows: Array<AccountModel>,
    assetTagRows: Array<AssetTagModel>,
    assetTagGroupRows: Array<AssetTagGroupModel>,
    productRows: Array<ProductModel>,
    productGroupRows: Array<ProductGroupModel>,
    currentIndex: number,
    selectedAccountId?: string,
    selectedProductGroup?: string,
    assets: Array<AssetModel>
}

class AdminPage extends React.Component<IBaseProps, IState> {
    public addAccountDialog = createRef<any>();
    public addAssetTagDialog = createRef<any>();
    public addAssetGroupDialog = createRef<any>();
    public addProductDialog = createRef<any>();
    public addProductGroupDialog = createRef<any>();
    public addUserDialog = createRef<any>();
    //public component = new ComponentFactory();

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);

        let dialogs = [
            <AddAccountDialog {...componentFactory.renderDialogProps("Add Account", "Save", this.updateData.bind(this), this.addAccountDialog)} />,
            <AddAssetTagDialog {...componentFactory.renderDialogProps("Add Asset Tag", "Save", this.updateData.bind(this), this.addAssetTagDialog)} />,
            <AddAssetTagGroupDialog {...componentFactory.renderDialogProps("Add Asset Tag Group", "Save", this.updateData.bind(this), this.addAssetGroupDialog)} />,
            <AddProductDialog {...componentFactory.renderDialogProps("Add Product", "Save", this.updateData.bind(this), this.addProductDialog)} />,
            <AddProductGroupDialog {...componentFactory.renderDialogProps("Add Product Group", "Save", this.updateData.bind(this), this.addProductGroupDialog)} />,
            <AddUserDialog {...componentFactory.renderDialogProps("Add User", "Save", this.updateData.bind(this), this.addUserDialog)} />,
        ]

        this.state = {
            componentFactory: componentFactory,
            dialogs:dialogs,
            userRows: [],
            accountRows: [],
            assetTagRows: [],
            assetTagGroupRows: [],
            productRows: [],
            productGroupRows: [],
            currentIndex: 0,
            assets: []
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.updateData();
            return null;
        });
        this.updateData();
    }

    // Action methods
    async updateData() {
        this.getAccounts();
        this.getAssetTags();
        this.getAssetTagGroups();
        this.getProducts();
        this.getProductGroups();
        this.getUsers();

        let assets = await this.global.dataManager.get("getAssetsForAdmin", {field:InvokeFields.getAssetsForAdmin});
        let activeAssets = ArrayUtil.getItems(assets, "isActive", true);
        this.setState({assets:activeAssets});
    }

    async getAccounts() {
        let accounts = await this.global.dataManager.get("getAccounts");
        ArrayUtil.sort(accounts, "accountName");

        this.setState({accountRows: accounts});
    }

    async getAssetTags() {
        let assetTags = await this.global.dataManager.get("getAssetTags");
        ArrayUtil.sort(assetTags, "assetTagName");

        this.setState({assetTagRows: assetTags});
    }

    async getAssetTagGroups() {
        let assetTagGroups = await this.global.dataManager.get("getAssetTagGroups");
        ArrayUtil.sort(assetTagGroups, "assetTagGroupName");

        this.setState({assetTagGroupRows: assetTagGroups});
    }

    async getUsers() {
        let users = await this.global.dataManager.get("getUsers");
        ArrayUtil.sort(users, "name");
        if (this.state.selectedAccountId) {
            let accounts = await this.global.dataManager.get("getAccounts");
            let selectedUsers = [];
            for (let user of users) {
                for (let account of accounts) {
                    if (account.id == this.state.selectedAccountId && account.users && account.users.indexOf(user.id) > -1) {
                        selectedUsers.push(user);
                    }
                }
            }
            this.setState({userRows: selectedUsers});
        } else {
            this.setState({userRows: users});
        }
    }

    async getProducts() {
        let products = await this.global.dataManager.get("getProducts");
        ArrayUtil.sort(products, "productName");

        this.setState({productRows: products});
    }

    async getProductGroups() {
        let productGroups = await this.global.dataManager.get("getProductGroups");
        ArrayUtil.sort(productGroups, "productGroupName");

        this.setState({productGroupRows: productGroups});
    }

    async exportProducts() {
        let headerFields = ["Product Name"]
        let userData = [];

        for (let product of this.state.productRows) {
            //let item = [];
            //item.push(row[column]);

            userData.push([product.productName]);
        }

        var csv = PapaParse.unparse({
            fields: headerFields,
            data: userData
        });

        let filename = "product_export.csv";
        File.download(csv, filename);
    }

    async deleteAccountTapped(item:AccountModel) {
        console.log(item);
        await this.global.dataManager.delete("deleteAccount", item);
        this.getAccounts();
    }







    async deleteUserTapped(item:any) {
        let accounts = await this.global.dataManager.get("getAccounts");
        for (let account of accounts) {
            if (account.users && account.users.indexOf(item.id) > -1) {
                this.props.base.notify("Please remove this user from all accounts before deleting");
                return;
            }
        }

        let input:DeleteCognitoUserInput = {
            cognitoUsername: item.id,
            field: "deleteCognitoUser"
        }
        await this.global.dataManager.perform("deleteCognitoUser", input);
        await this.global.dataManager.delete("deleteUser", item);
        this.getUsers();
    }

    emailUsers() {
        LocalCache.removeSession("assetIds");
        let userIds = [];
        for (let user of this.state.userRows) {
            userIds.push(user.id);
        }
        LocalCache.saveSession("ids", userIds.join(","));
        Route.go(this, 'sendMessage/user');
        //this.props.base.notify("Not yet implemented");
    }

    userAccountChanged(value:any) {
        this.setState({selectedAccountId: value}, function(this:AdminPage) {
            this.getUsers();
        });
    }

    // Render accounts and users

    renderAccountRow(item:AccountModel) {
        /*const actions = <Box>
            <IconButtonComponent icon={BaseIcon.ICON_EDIT} tooltip="Edit" color="primary" onTouchTap={DialogUtil.openFunction(this.addAccountDialog.current, item)} />
        </Box>*/
        let accountAssets = ArrayUtil.getItems(this.state.assets, "accountId", item.id);
        let editFunction = DialogUtil.openFunction(this.addAccountDialog.current, item);

        return [
            item.accountName,
            accountAssets.length + " / " + item.assetLicense,
            item.live ? <span className="active">ACTIVE</span> : <span className="inactive">INACTIVE</span>,
            this.state.componentFactory.renderEditDeleteButtons(editFunction)
        ]
    }

    renderUserRows(item:UserModel) {
        console.log(item);
        let editFunction = DialogUtil.openFunction(this.addUserDialog.current, item);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this user? This action cannot be undone.", this.deleteUserTapped.bind(this), item);
        let actions = this.state.componentFactory.renderEditDeleteButtons(editFunction,deleteFunction);


        let accounts = [];
        if (item.admin) {
            accounts.push("Administrator (All)");
        }
        for (let account of this.state.accountRows) {
            if (account.users && account.users.indexOf(item.id) > -1) {
                accounts.push(account.accountName);
            }
        }

        return [
            item.name,
            item.email,
            accounts.join(', '),
            actions
        ]
    }

    async overflowMenuTapped(value:number, e:any) {
        if (value == 0) {
            // export asset template
            //this.props.base.notify("Not yet implemented");
            ImportExportUtil.exportAssetTemplate();
        }
    }

    renderAccounts() {
        const cf = this.state.componentFactory;
        var menuItems = ["Export Asset Template"];
        var headerRowContent = [
            <ButtonComponent primary={true} title="New Account" onTouchTap={DialogUtil.openFunction(this.addAccountDialog.current)} />,
            <MenuButtonComponent menuItemLabels={menuItems} onTouchTap={this.overflowMenuTapped.bind(this)}>
                <IconButtonComponent icon={BaseIcon.ICON_MORE_VERT} tooltip="More Actions" color="primary" onTouchTap={() => {}} />
            </MenuButtonComponent>
            ]
        return (
            cf.renderBox([
                VisualUtil.renderActionBar(null, headerRowContent),
                cf.renderTable(["Account Name", "Asset License", "Active", "Actions"], this.state.accountRows, this.renderAccountRow.bind(this))
            ])
        )
    }

    renderUsers() {
        let cf = this.state.componentFactory;
        var headers = ["User name", "User email", "Accounts", "Actions"];
        var headerRowContent = [
            <SelectComponent
                id="county"
                style={{width:'200px', backgroundColor:'#D7D7D7', marginRight:'20px'}}
                disableUnderline={true}
                value={this.state.selectedAccountId ? this.state.selectedAccountId : ""}
                options={this.global.accountOptions}
                onChange={this.userAccountChanged.bind(this)} />,
            cf.renderButton("Email Users", this.emailUsers.bind(this), {isFlat:true, startIcon:<AddCircleOutlineIcon/>})
        ];
        return (
            cf.renderBox([
                VisualUtil.renderActionBar(headerRowContent),
                cf.renderTable(headers, this.state.userRows, this.renderUserRows.bind(this))
            ])
        )
    }

    // Asset Tag

    async deleteAssetTagTapped(item:AssetTagModel) {
        await this.global.dataManager.delete("deleteAssetTag", item);
        this.getAssetTags();
    }

    renderAssetTagRow(item:AssetTagModel) {
        let editFunction = DialogUtil.openFunction(this.addAssetTagDialog.current, item);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this asset tag? This action cannot be undone.", this.deleteAssetTagTapped.bind(this), item);
        let actions = this.state.componentFactory.renderEditDeleteButtons(editFunction,deleteFunction);
        var assetTagGroup:AssetTagGroupModel = ArrayUtil.getItem(this.state.assetTagGroupRows, "id", item.assetTagGroupId);

        return [
            item.assetTagName,
            assetTagGroup?.assetTagGroupName,
            actions
        ]
    }

    renderAssetTags() {
        let cf = this.state.componentFactory;
        let newButton = cf.renderButton("New Asset Tag", DialogUtil.openFunction(this.addAssetTagDialog.current));
        return cf.renderBox([
            VisualUtil.renderActionBar(null, newButton),
            cf.renderTable(["Asset Tag Name", "Asset Tag Group", "Actions"], this.state.assetTagRows, this.renderAssetTagRow.bind(this))
        ])
    }

    // Asset Tag Groups

    async deleteAssetTagGroupTapped(item:AssetTagGroupModel) {
        await this.global.dataManager.delete("deleteAssetTagGroup", item);
        this.getAssetTagGroups();
    }

    renderAssetTagGroupRow(item:AssetTagGroupModel) {
        let editFunction = DialogUtil.openFunction(this.addAssetGroupDialog.current, item);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this asset tag group? This action cannot be undone.", this.deleteAssetTagGroupTapped.bind(this), item);
        let actions = this.state.componentFactory.renderEditDeleteButtons(editFunction,deleteFunction);

        return [
            item.assetTagGroupName,
            item.seasonStart && item.seasonEnd ? moment(item.seasonStart).format("MMMM DD") + " - " + moment(item.seasonEnd).format("MMMM DD") : "",
            actions
        ]
    }

    renderAssetTagGroups() {
        let cf = this.state.componentFactory;
        let newButton = cf.renderButton("New Asset Tag Group", DialogUtil.openFunction(this.addAssetGroupDialog.current))
        return cf.renderBox([
            VisualUtil.renderActionBar(null, newButton),
            cf.renderTable(["Asset Tag Group Name", "Season Dates", "Actions"], this.state.assetTagGroupRows, this.renderAssetTagGroupRow.bind(this))
        ])
    }

    // Render products

    selectedProductGroupChanged(value:any, key:string) {
        const selectedProductGroup = value != "" ? value : null;
        this.setState({selectedProductGroup:selectedProductGroup});
        //LocalCache.saveSession("selectedCountySponsor", value);
        //const selectedCounty = value != "" ? value : null;
        //setSelectedCounty(selectedCounty);
        //getSponsors();
        /*this.setState({selectedCounty:selectedCounty}, function(this:SponsorPage) {
            this.getSponsors();
        }.bind(this));*/
    }

    async deleteProductTapped(item:ProductModel) {
        await this.global.dataManager.delete("deleteProduct", item);
        this.getProducts();
    }

    renderProductRow(item:ProductModel) {
        let editFunction = DialogUtil.openFunction(this.addProductDialog.current, item);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this product? This action cannot be undone.", this.deleteProductTapped.bind(this), item);
        let actions = this.state.componentFactory.renderEditDeleteButtons(editFunction,deleteFunction);
        var productGroup:ProductGroupModel = ArrayUtil.getItem(this.state.productGroupRows, "id", item.productGroupId);

        return [
            item.productName,
            productGroup?.productGroupName,
            item.seasonStartMonth && item.seasonEndMonth ? item.seasonStartMonth + "/" + item.seasonStartDay + " - " + item.seasonEndMonth + "/" + item.seasonEndDay : "",
            actions
        ]
    }

    filterProducts(rows:Array<ProductModel>): Array<ProductModel> {
        if (this.state.selectedProductGroup && this.state.selectedProductGroup !== "") {
            return ArrayUtil.getItems(rows, "productGroupId", this.state.selectedProductGroup);
        }
        return rows;
    }

    renderProducts() {
        const cf = this.state.componentFactory;
        let productGroupOptions = SelectItem.fromArray(this.state.productGroupRows, "id", "productGroupName");
        productGroupOptions.splice(0, 0, SelectItem.create("", "All Product Groups"));
        const productGroupSelection = <SelectComponent
        id="productGroups"
        inputType="bootstrap"
        variant="outlined"
        disableUnderline={true}
        value={this.state.selectedProductGroup || ""}
        options={productGroupOptions}
        onChange={this.selectedProductGroupChanged.bind(this)}/>;
        const newButton = cf.renderButton("New Product", DialogUtil.openFunction(this.addProductDialog.current));
        return cf.renderBox([
            VisualUtil.renderActionBar(productGroupSelection, newButton),
            cf.renderTable(["Product Name", "Product Group", "Season Dates", "Actions"], this.filterProducts(this.state.productRows), this.renderProductRow.bind(this))
        ])
    }

    // Product Groups

    async deleteProductGroupTapped(item:ProductGroupModel) {
        let products = await this.global.dataManager.get("getProducts");
        for (let product of products) {
            if (product.productGroupId == item.id) {
                this.props.base.notify("Please remove this product group from all products before deleting");
                return;
            }
        }

        await this.global.dataManager.delete("deleteProductGroup", item);
        this.getProductGroups();
    }

    renderProductGroupRow(item:ProductGroupModel) {
        let editFunction = DialogUtil.openFunction(this.addProductGroupDialog.current, item);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this product group? This action cannot be undone.", this.deleteProductGroupTapped.bind(this), item);
        let actions = this.state.componentFactory.renderEditDeleteButtons(editFunction,deleteFunction);

        return [
            item.productGroupName,
            actions
        ]
    }

    renderProductGroups() {
        let cf = this.state.componentFactory;
        let newButton = cf.renderButton("New Product Group", DialogUtil.openFunction(this.addProductGroupDialog.current));
        return cf.renderBox([
            VisualUtil.renderActionBar(null, newButton),
            cf.renderTable(["Product Group Name", "Actions"], this.state.productGroupRows, this.renderProductGroupRow.bind(this))
        ])
    }



    public render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBoxProps({fullHeight: true, background: BaseColor.WHITE}, [
                this.state.dialogs,
                cf.renderBoxProps({marginLeft: '90px'}, [
                    !this.global.isDataLoaded ?
                        VisualUtil.showLoading() :
                        VisualUtil.getHeaderDisplay("Administrative Setup", null),
                    cf.renderLine(),
                    cf.renderTabs(["Accounts", "Users", "Products", "Product Groups", "Asset Tags", "Asset Tag Groups"], [
                        this.renderAccounts(),
                        this.renderUsers(),
                        this.renderProducts(),
                        this.renderProductGroups(),
                        this.renderAssetTags(),
                        this.renderAssetTagGroups()
                    ])
                ])
            ])
        )
    }
}

export default base(AdminPage, Sidebar)