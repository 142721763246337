import * as React from 'reactn';
import {
    ArrayUtil,
    base, BaseColor, ButtonComponent,
    ComponentFactory, ComponentUtil, DateUtil, DialogUtil, IBaseProps, Route, StringUtil, SwitchComponent
} from "oca-lib-web";
import Sidebar from "../component/Sidebar";
import {addCallback} from "reactn";
import Constants from "../util/Constants";
import { Alert } from '@mui/material';
//import ImageUtil from "../util/ImageUtil";
import AWSStorage from "../auth/aws/AWSStorage";
import ImageUtil from "../util/ImageUtil";
import {createRef} from "react";
import AddCommentDialog from "../component/AddCommentDialog";
import Imgix from "react-imgix";
import SelectItem from "oca-lib-web/dist/util/SelectItem";
import VisualUtil from "../util/VisualUtil";
import Styles from "../style/Styles";
import UserUtil from "../util/UserUtil";
import AssetSelectionDialog, {SelectionType} from "../component/AssetSelectionDialog";

interface IState {
    componentFactory:ComponentFactory,
    id?:string,
    type?:string,
    dialogs:Array<any>,
    assetImages:Array<any>,
    pictures:Array<any>,
    assetTagOptions:Array<SelectItem>,
    productOptions:Array<SelectItem>,
    errorText?:string
}

class AssetEventPage extends React.Component<IBaseProps, IState> {

    public components = ComponentUtil.createRefs(10);
    public assetSelectionDialog = createRef<any>();
    public addCommentDialog = createRef<any>();

    // Init

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        componentFactory.setHandleUpdateCallback((value:any, key:string) => {
            console.log(key, value);
            this.setState({errorText:null});
        })
        let dialogs = [
            <AssetSelectionDialog {...componentFactory.renderDialogProps("Select Event", "Save", this.copyAssetEventDialogCallback.bind(this), this.assetSelectionDialog)} />,
            <AddCommentDialog {...componentFactory.renderDialogProps("Add Comment", "Save", this.saveCommentCallback.bind(this), this.addCommentDialog)}  />
        ]

        this.state = {
            componentFactory: componentFactory,
            dialogs:dialogs,
            assetImages:[],
            pictures:[],
            assetTagOptions: [],
            productOptions: []
        };
    }

    async componentDidMount() {
        addCallback(global => {
            this.updateData();
            return null;
        });
        if (this.global.isDataLoaded) {
            this.updateData();
        }
    }

    async updateData() {
        let cf = this.state.componentFactory;
        let id = Route.param(this, "id");
        let type = Route.param(this, "type");
        let assetTags = await this.global.dataManager.get("getAssetTags");
        let products = await this.global.dataManager.get("getProducts");
        this.setState({id:id, type:type, assetTagOptions:cf.createOptions(assetTags, "id", "assetTagName"), productOptions:cf.createOptions(products, "productName", "productName")}, function(this:AssetEventPage) {
            this.getAssetEvent();
            if (type === "update") {
                this.getPictures();
            }
        }.bind(this));
    }

    async getAssetEvent(selectedAssetEvent?:AssetEventModel) {
        let assetEvent:AssetEventModel;
        //let id = Route.param(this, "id");
        if (this.state.type == "update") {
            assetEvent = await this.global.dataManager.get("getAssetEvent", this.state.id);
            //this.setState({assetEvent:assetEvent});
        } else if (selectedAssetEvent) {
            assetEvent = selectedAssetEvent;
        }
        console.log(assetEvent);

        let componentFactory = this.state.componentFactory;
        componentFactory.addDefaultBinding(this.global.dataManager, "createAssetEvent", assetEvent);
        this.setState({componentFactory:componentFactory}, function(this:AssetEventPage) {
            if (assetEvent) {
                let eventStartDateMoment = this.components[2];
                let eventEndDateMoment = this.components[3];
                let eventStartTimeMoment = this.components[4];
                let eventEndTimeMoment = this.components[5];
                if (eventStartDateMoment.current) {
                    eventStartDateMoment.current.handleDateChange(DateUtil.date(assetEvent.assetEventStartDate));
                }
                if (eventEndDateMoment.current) {
                    eventEndDateMoment.current.handleDateChange(DateUtil.date(assetEvent.assetEventEndDate));
                }
                if (eventStartTimeMoment.current) {
                    eventStartTimeMoment.current.handleDateChange(DateUtil.date(assetEvent.assetEventStartTime));
                }
                if (eventEndTimeMoment.current) {
                    eventEndTimeMoment.current.handleDateChange(DateUtil.date(assetEvent.assetEventEndTime));
                }
                this.forceUpdate();
            }
        }.bind(this))
    }

    async getPictures() {
        let pictures = await AWSStorage.list(this.state.id);
        let assetImages:Array<AssetImageModel> = [];
        for (let picture of pictures) {
            let assetImage = await this.global.dataManager.get("getAssetImage", picture.key);
            if (assetImage) {
                assetImages.push(assetImage);
            }
        }

        this.setState({pictures: pictures, assetImages:assetImages});
    }

    // Methods

    copyAssetEventDialogCallback(items:Array<AssetEventModel>) {
        console.log(items);
        if (items.length > 0) {
            items[0].id = null;
            this.components[0].current.props.onChange(items[0].assetEventTitle, "assetEventTitle");
            this.components[1].current.props.onChange(items[0].assetEventDescription, "assetEventDescription");
            this.components[6].current.props.onChange(items[0].assetEventRecurrence, "assetEventRecurrence");
            if (items[0].assetEventCustomRecurrence) {
                this.components[7].current.props.onChange(items[0].assetEventCustomRecurrence.split(","), "assetEventCustomRecurrence");
            }
            if (items[0].assetEventProducts) {
                this.components[8].current.props.onChange(items[0].assetEventProducts.split(","), "assetEventProducts");
            }
            if (items[0].assetEventTags) {
                this.components[9].current.props.onChange(items[0].assetEventTags.split(","), "assetEventTags");
            }
            this.getAssetEvent(items[0]);
        }
    }

    saveCommentCallback(item:any) {
        this.props.base.notify("Image saved");
        this.getPictures();
    }

    async uploadPictureTapped(e:any) {
        if (!e.nativeEvent.target || !e.nativeEvent.target.files) { return }

        console.log("bytes: " + e.nativeEvent.target.files[0].size);
        if(e.nativeEvent.target.files[0].size > 1048576) {
            this.props.base.notify("Image is too big to upload (max 1MB)", "error");
            return;
        }

        const file = e.nativeEvent.target.files[0];
        let fullFileName = this.state.id + "/" + StringUtil.uuid();
        //let fullFileName = String.uuid();
        await AWSStorage.put(fullFileName, file);
        console.log("uploaded file: " + fullFileName);

        this.getPictures();

        let componentBinding = this.state.componentFactory.getDefaultBinding();
        componentBinding.setProperty("photo", fullFileName);
        this.forceUpdate();
        componentBinding.saveBinding();
    }

    async handleSave(callback: any) {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) {
            this.setState({errorText:"Please fill out all fields"});
            return;
        }

        console.log(componentBinding.item);

        let eventStartDate = this.components[2].current.getValue();
        console.log(eventStartDate);
        let eventEndDate = this.components[3].current.getValue();
        let eventStartTime = this.components[4].current.getValue();
        let eventEndTime = this.components[5].current.getValue();
        if (eventStartDate == null) {
            this.setState({errorText:"Event start date is required"});
            return;
        } else if (eventEndDate == null) {
            this.setState({errorText:"Event end date is required"});
            return;
        } else if (eventStartTime == null) {
            this.setState({errorText:"Event start time is required"});
            return;
        } else if (eventEndTime == null) {
            this.setState({errorText:"Event end time is required"});
            return;
        }

        if (DateUtil.isAfter(eventStartDate, eventEndDate, 'day')) {
            this.setState({errorText:"Event end date must be after event start date"});
            return;
        } else if (DateUtil.isAfter(eventStartTime, eventEndTime, 'hour')) {
            this.setState({errorText:"Event end time must be after event start time"});
            return
        }

        let assetEventRecurrence = componentBinding.getProperty("assetEventRecurrence");
        let assetEventCustomRecurrence = componentBinding.getProperty("assetEventCustomRecurrence");
        if (assetEventRecurrence == "custom" && (!assetEventCustomRecurrence || assetEventCustomRecurrence == "")) {
            this.setState({errorText:"Custom event recurrence requires you to enter days of the week"});
            return
        } else if (assetEventRecurrence != "custom" && assetEventCustomRecurrence && assetEventCustomRecurrence != "") {
            this.setState({errorText:"Days of the week should only be entered for custom event recurrence"});
            return
        }

        if (this.state.type == "create") {
            componentBinding.setProperty("assetId", this.state.id);
        }

        console.log(componentBinding.item);
        await componentBinding.saveBinding();

        Route.goBack(this);
        //this.props.base.notify("Event saved");

        //callback(componentBinding.item);
    }

    async removePicture(picture:any) {
        console.log(picture);
        console.log("about to delete: " + JSON.stringify(picture));
        await AWSStorage.remove(picture.key);

        let assetImage = ArrayUtil.getItem(this.state.assetImages, "id", picture.key);
        if (assetImage) {
            await this.global.dataManager.delete("deleteAssetImage", assetImage);
        }

        this.getPictures();
        this.props.base.notify("Image deleted");
    }

    handleSwitchChanged(key) {
        console.log(key);
        let binding = this.state.componentFactory.getDefaultBinding();
        binding.setProperty("primaryImage", key);

        // TODO: more validation

        binding.saveBinding();
        this.props.base.notify("Primary image updated");
    }

    renderAssetEventImageRow(picture:any) {
        let cf = this.state.componentFactory;
        let editFunction = DialogUtil.openFunction(this.addCommentDialog.current, picture.key);
        let deleteFunction = () => this.props.base.alert("Confirm Delete", "Are you sure you want to delete this comment? This action cannot be undone.", this.removePicture.bind(this), picture);
        let actions = cf.renderEditDeleteButtons(editFunction,deleteFunction);

        let assetImage = ArrayUtil.getItem(this.state.assetImages, "id", picture.key);
        let binding = this.state.componentFactory.getDefaultBinding();
        let primaryImage = binding.getProperty("primaryImage");
        return [
            <Imgix height={100} width={100} src={ImageUtil.fetch(picture.key)} />,
            assetImage ? assetImage.comment : "",
            assetImage ? assetImage.isActive ? <span className="active">ACTIVE</span> : <span className="inactive">INACTIVE</span> : <span className="active">ACTIVE</span>,
            <SwitchComponent id="assetSwitch" primary checked={primaryImage == picture.key} onChange={(e:any) => this.handleSwitchChanged(picture.key)} />,
            actions
        ];
    }

    renderAssetImages() {
        let cf = this.state.componentFactory;
        let headers = ["Image", "Comment", "Active", "Primary", "Action"];
        let headerRowContent = [<ButtonComponent primary disabled={this.state.pictures.length >= Constants.MAX_NUMBER_OF_ASSET_IMAGES} title="Upload" forUpload={true} uploadAccept="images" onTouchTap={this.uploadPictureTapped.bind(this)} />]

        return (
            cf.renderPaper(cf.renderTableWithActions(headers, this.state.pictures, this.renderAssetEventImageRow.bind(this), headerRowContent))
        )
    }

    // Render

    render() {
        let cf = this.state.componentFactory;
        let tabs = ["Details"];
        if (this.state.type != "create") {
            tabs.push("Images");
        }

        return (
            cf.renderBoxProps({fullHeight:true, background:BaseColor.WHITE}, [
                this.state.dialogs,
                !this.global.isDataLoaded ?
                    VisualUtil.showLoading() : !UserUtil.hasAccount() ? VisualUtil.showNoAccount()
                    :
                        cf.renderBoxProps({marginLeft:'90px'}, [
                    this.state.errorText ? <Alert severity="error">{this.state.errorText}</Alert> : null,
                    VisualUtil.getHeaderDisplay("Asset Event", [
                        this.state.type !== "update" ? cf.renderButton("Copy Event", DialogUtil.openFunction(this.assetSelectionDialog.current, {
                                type: SelectionType.AssetEvent,
                                selected: null,
                                id: this.state.id
                            }), {isFlat:true}) : null,
                        cf.renderButton("Save", this.handleSave.bind(this))
                    ]),
                    cf.renderLine(),
                    cf.renderTabs(tabs, [
                        cf.renderPaper([
                            cf.renderBoxPadded(cf.renderTextField("assetEventTitle", "Event Title", this.components[0], null, {required:true})),
                            cf.renderBoxPadded(cf.renderTextField("assetEventDescription", "Event Description", this.components[1], null, {required:true, type:"textarea", multiline:true})),
                            cf.renderBoxPadded(cf.renderDateField("assetEventStartDate", "Event Start Date", "date", this.components[2], null, {required:true})),
                            cf.renderBoxPadded(cf.renderDateField("assetEventEndDate", "Event End Date", "date", this.components[3], null, {required:true})),
                            cf.renderBoxPadded(cf.renderDateField("assetEventStartTime", "Event Start Time", "time", this.components[4], null, {required:true})),
                            cf.renderBoxPadded(cf.renderDateField("assetEventEndTime", "Event End Time", "time", this.components[5], null, {required:true})),
                            cf.renderBoxPadded(cf.renderSelectField("assetEventRecurrence", "Event Recurrence", Constants.EVENT_RECURRENCE_SELECTION, this.components[6], null, {required:true})),
                            cf.renderBoxPadded(cf.renderSelectField("assetEventCustomRecurrence", "Custom Event Recurrence", Constants.EVENT_CUSTOM_RECURRENCE_SELECTION, this.components[7], null, {multiple:true})),
                            cf.renderBoxPadded(cf.renderSelectField("assetEventProducts", "Product Tags", this.state.productOptions, this.components[8], null, {multiple:true,required:false})),
                            cf.renderBoxPadded(cf.renderSelectField("assetEventTags", "Asset Tags", this.state.assetTagOptions, this.components[9], null, {multiple:true,required:false})),
                        ]),
                        this.renderAssetImages()
                    ])
                ])
            ])
        )
    }
}

export default base(AssetEventPage, Sidebar)