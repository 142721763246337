export default class AssetQuery {

    public static GetAssets = `query GetAssetsQuery($accountId: String) {
        getAssets(accountId:$accountId) {
            id
            assetName
            assetAddress
            assetOverrideLocation
            assetCounty
            assetLatitude
            assetLongitude
            assetPhoneNumber
            assetWebsite
            assetOnlineStore
            assetEmailAddress
            assetContactPhoneNumber
            assetContactPreference
            assetDescription
            assetPaymentMethods
            assetProducts
            assetUPickProducts
            assetHours
            assetFacebook
            assetInstagram
            assetTwitter
            assetCategories
            assetTags
            accountId
            assetEventStartDate
            assetEventEndDate
            assetEventStartTime
            assetEventEndTime
            assetEventRecurrence
            assetEventCustomRecurrence
            primaryImage
            lastUpdated
            isActive
        }
    }`;

    public static GetAssetsForAdmin = `query GetAssetsForAdminQuery($input:GetAssetsForAdminInput) {
        getAssetsForAdmin(input:$input) {
            id
            assetName
            assetAddress
            assetOverrideLocation
            assetCounty
            assetLatitude
            assetLongitude
            assetPhoneNumber
            assetWebsite
            assetOnlineStore
            assetEmailAddress
            assetContactPhoneNumber
            assetContactPreference
            assetDescription
            assetPaymentMethods
            assetProducts
            assetUPickProducts
            assetHours
            assetFacebook
            assetInstagram
            assetTwitter
            assetCategories
            assetTags
            accountId
            assetEventStartDate
            assetEventEndDate
            assetEventStartTime
            assetEventEndTime
            assetEventRecurrence
            assetEventCustomRecurrence
            primaryImage
            lastUpdated
            isActive
        }
    }`;

    public static GetAsset = `query GetAsset($id: ID) {
        getAsset(
            id:$id
        ) {
            id
            assetName
            assetAddress
            assetOverrideLocation
            assetCounty
            assetLatitude
            assetLongitude
            assetPhoneNumber
            assetWebsite
            assetOnlineStore
            assetOwnerName
            assetEmailAddress
            assetContactPhoneNumber
            assetContactPreference
            assetDescription
            assetPaymentMethods
            assetProducts
            assetUPickProducts
            assetHours
            assetFacebook
            assetInstagram
            assetTwitter
            assetCategories
            assetTags
            accountId
            assetEventStartDate
            assetEventEndDate
            assetEventStartTime
            assetEventEndTime
            assetEventRecurrence
            assetEventCustomRecurrence
            primaryImage
            lastUpdated
            isActive
        }
    }`;

    public static CreateAsset = `mutation CreateAssetMutation($input: AssetInput!) {
        createAsset(input: $input) {
            assetName
            assetAddress
            assetOverrideLocation
            assetCounty
            assetLatitude
            assetLongitude
            assetPhoneNumber
            assetWebsite
            assetOnlineStore
            assetEmailAddress
            assetContactPhoneNumber
            assetContactPreference
            assetDescription
            assetPaymentMethods
            assetProducts
            assetUPickProducts
            assetHours
            assetFacebook
            assetInstagram
            assetTwitter
            assetCategories
            assetTags
            accountId
            assetEventStartDate
            assetEventEndDate
            assetEventStartTime
            assetEventEndTime
            assetEventRecurrence
            assetEventCustomRecurrence
            primaryImage
            lastUpdated
            isActive
        }
    }`;

    public static CreateAssets = `mutation CreateAssetMutation($input: AssetBatchInput) {
        createAssets(input: $input) {
            success
        }
    }`;

    public static DeleteAsset = `mutation DeleteAssetMutation($id: ID!) {
        deleteAsset(id: $id) {
            id
        }
    }`;

    public static GetCountyFromCoordinates = `query GetCountyFromCoordinates($input: GetCountyFromCoordinatesInput!) {
        getCountyFromCoordinates(input: $input) {
            success
        }
    }`;
}