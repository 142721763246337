import {point, lineString, featureCollection, bbox, simplify} from '@turf/turf';
import Constants from "./Constants";
import {P, Http} from "oca-lib-web";
var polyline = require('@mapbox/polyline');

enum ViewMode {
    Light = "Light",
    Streets = "Streets",
    Satellite = "Satellite"
}

export default class MapUtil {

    public static getMapStyle(mode:string):string {
        let mapStyle: string;
        switch (mode) {
            case ViewMode.Light:
                mapStyle = "mapbox://styles/mapbox/light-v9";
                break;
            case ViewMode.Streets:
                mapStyle = "mapbox://styles/mapbox/streets-v10";
                break;
            case ViewMode.Satellite:
                mapStyle = "mapbox://styles/mapbox/satellite-v9";
                break;
        }
        return mapStyle;
    }

    public static getMapForPoint(latitude:number, longitude:number, height:number, width:number) {
        let accessToken = "pk.eyJ1IjoiZW1hamV3aWN6IiwiYSI6Ik5OX1JMREEifQ.E0AZQz9prc9zDkPVTE7IKQ";
        var url = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+2196f3({0},{1})/{5},{6},10,0/{2}x{3}?access_token={4}";
        url = url.replace("{0}", longitude.toString());
        url = url.replace("{1}", latitude.toString());
        url = url.replace("{2}", height.toString());
        url = url.replace("{3}", width.toString());
        url = url.replace("{4}", accessToken);
        url = url.replace("{5}", longitude.toString());
        url = url.replace("{6}", latitude.toString());
        return url;
    }

    public static url = "https://maps.googleapis.com/maps/api/directions/json?origin={0}&destination={1}&key=AIzaSyDohUqDp6zOdYeeFtguu4eOyFv9yYw9rZw";
    public static async getMapForPoints(points:Array<{latitude:number, longitude:number}>, width:number, height:number) {
        let featurePoints = [];
        let directionPoints = [];
        console.log(featurePoints);
        for (var i=0; i<points.length; i++) {
            if (i != 0) {
                let directionUrl = this.url.replace("{0}", points[i-1].latitude + "," + points[i-1].longitude);
                directionUrl = directionUrl.replace("{1}", points[i].latitude + "," + points[i].longitude);
                //console.log(directionUrl);
                let results = await Http.get(directionUrl);
                let route = results["routes"][0]["overview_polyline"]["points"];
                console.log(results);
                //console.log(route);
                //let directionLines = polyline.toGeoJSON(route);
                let directionLines = polyline.decode(route);
                let directionLineCoordinates = [];
                for (let directionLine of directionLines) {
                    directionLineCoordinates.push([directionLine[1], directionLine[0]]);
                }
                console.log(directionLines);
                //featurePoints.push(directionLines);
                let lineCoordinates = [];
                let steps = results["routes"][0]["legs"][0]["steps"];
                for (let step of steps) {
                    lineCoordinates.push([step["start_location"].lng, step["start_location"].lat]);
                    //lineCoordinates.push([step["end_location"].lng, step["end_location"].lat]);
                }

                if (directionLineCoordinates.length < 2) {
                    console.error("Unable to get direction line coordinates");
                    return P.resolve(null);
                }
                let newLineString = lineString(directionLineCoordinates);
                var options = {tolerance: .001, highQuality: false};
                let simplifiedLineString = simplify(newLineString, options);
                //console.log(simplifiedLineString);
                directionPoints.push(simplifiedLineString);

                //console.log(directionLines);
            }
            featurePoints.push(point([points[i].longitude, points[i].latitude], {"marker-size":"l", "marker-color":Constants.COLORS[i]}));
        }

        // test for length
        let tmpFeatureCollection = featureCollection(directionPoints);
        let featureCollectionObject;
        if (encodeURIComponent(JSON.stringify(tmpFeatureCollection)).length < 10000) {
            featureCollectionObject = featureCollection([...featurePoints, ...directionPoints]);
        } else {
            featureCollectionObject = featureCollection(featurePoints);
        }
        let bboxFeatures = bbox(featureCollectionObject);

        let accessToken = "pk.eyJ1IjoiZW1hamV3aWN6IiwiYSI6Ik5OX1JMREEifQ.E0AZQz9prc9zDkPVTE7IKQ";
        var url = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/geojson({0})/[{5},{6},{7},{8}]/{2}x{3}?access_token={4}";
        url = url.replace("{0}", encodeURIComponent(JSON.stringify(featureCollectionObject)));
        url = url.replace("{2}", height.toString());
        url = url.replace("{3}", width.toString());
        url = url.replace("{4}", accessToken);
        if (points && points.length > 0) {
            url = url.replace("{5}", bboxFeatures[0].toString());
            url = url.replace("{6}", bboxFeatures[1].toString());
            url = url.replace("{7}", bboxFeatures[2].toString());
            url = url.replace("{8}", bboxFeatures[3].toString());
        }
        console.log("Url length: " + url.length);
        return P.resolve(url);
    }

    // Util methods

    public static hexToRgb(hex: string): number[] {
        let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result
            ? [
                parseInt(result[1], 16),
                parseInt(result[2], 16),
                parseInt(result[3], 16)
            ]
            : null;
    }

    // getProp({ a: { b: { c: 'value' }}}, 'a.b.c') === 'value'
    public static getProp(obj: object, path: string): any {
        if (!obj || !path) return null;
        let paths = path.split(".");
        let curProp = obj;
        do {
            let curPath = paths.shift();
            curProp = curProp[curPath];
            if (!curProp) return null;
        } while (paths.length);
        return curProp;
    }

    public static sanitize(coords: any): any {
        if (!Array.isArray(coords)) return coords;

        let hasArrays = false;
        for (let item of coords) {
            if (Array.isArray(item)) {
                hasArrays = true;
                break;
            }
        }

        if (hasArrays) return coords.map(item => MapUtil.sanitize(item));
        return coords.length === 2 ? coords : coords.slice(0, 2);
    }


    /*public static getMapForPoints(points:Array<{latitude:number, longitude:number}>, width:number, height:number) {
    //pin-s-a+2196f3(\(startCoordinate)),pin-s-b+43a25c(\(endCoordinate))
    let accessToken = "pk.eyJ1IjoiZW1hamV3aWN6IiwiYSI6Ik5OX1JMREEifQ.E0AZQz9prc9zDkPVTE7IKQ";
    //var baseUrl = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/{0}/auto/{1}x{2}?access_token={3}"
    var baseUrl = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/{0}/{4},{5},13,0/{1}x{2}?access_token={3}"
    //var url = "https://api.mapbox.com/styles/v1/mapbox/streets-v11/static/pin-s+2196f3({0},{1})/{5},{6},13,0/{2}x{3}?access_token={4}";
    //url = url.replace("{0}", longitude.toString());
    //url = url.replace("{1}", latitude.toString());
    // TODO: Figure out how to convert list of latlng to first param
    let coordinateValues = "";
    for (let point of points) {
        if (coordinateValues !== "") {
            //coordinateValues = ",";
        }
        coordinateValues += "pin-s-a+2196f3(" + point.latitude + "," + point.longitude + ")";
    }
    baseUrl = baseUrl.replace("{0}", coordinateValues);
    baseUrl = baseUrl.replace("{1}", height.toString());
    baseUrl = baseUrl.replace("{2}", width.toString());
    baseUrl = baseUrl.replace("{3}", accessToken);
    baseUrl = baseUrl.replace("{4}", points.toString());
    baseUrl = baseUrl.replace("{5}", points.toString());
    return baseUrl;
}*/
}