import {createRef, RefObject} from "react";

export default class ComponentUtil {

    public static createRefs(numberOfRefs:number):Array<RefObject<any>> {
        let components:Array<RefObject<any>> = [];
        for (var i=0; i<numberOfRefs; i++) {
            components.push(createRef<any>());
        }
        return components;
    }
}