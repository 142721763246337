import * as React from 'reactn';
import {ComponentFactory, dialog, IBaseDialogProps} from "oca-lib-web";
import Imgix from "react-imgix";
import ImageUtil from "../util/ImageUtil";
import ReactGA from "react-ga";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    //assetImage?:AssetImageModel
}

class AddCommentDialog extends React.Component<IBaseDialogProps, IState> {

    constructor(props: any) {
        super();

        this.state = {
            componentFactory:new ComponentFactory(Styles.default),
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    async componentDidMount() {
        ReactGA.modalview('/item/commentDialog');

        console.log(this.props.param);
        let assetImage = await this.global.dataManager.get("getAssetImage", this.props.param);
        if (!assetImage) {
            assetImage = {
                id: this.props.param,
                isActive: true,
                numberOfLikes: 0,
                numberOfShares: 0
            }
        }
        let componentFactory = this.state.componentFactory;
        componentFactory.addDefaultBinding(this.global.dataManager, "createAssetImage", assetImage);
        this.setState({componentFactory:componentFactory})
        //this.state.componentFactory.addDefaultBinding(this.global.data.createAssetImage, assetImage);
        //this.forceUpdate();
        //let comment = assetImage ? assetImage.comment : "";

        //this.setState({assetImage:assetImage, comment:comment});
    }

    // Public methods

    async handleSave(callback:any) {
        let binding = this.state.componentFactory.getDefaultBinding();
        /*if (!binding.getProperty("id")) {
            binding.setProperty("id", this.props.param);
        }*/

        binding.saveBinding();
        callback(binding.item);

        /*let assetImage = this.state.assetImage;
        if (!assetImage) {
            assetImage = {
                id: this.props.param,
                comment: this.state.comment != "" ? this.state.comment : null,
                numberOfLikes: 0,
                numberOfShares: 0
            }
        } else {
            assetImage.comment = this.state.comment != "" ? this.state.comment : null;
        }

        console.log(assetImage);
        await this.global.data.createAssetImage(assetImage);

        callback(assetImage);*/
    }

    /*onTextChange(value:any) {
        this.setState({comment:value});
    }*/

    // Render

    public render() {
        let cf = this.state.componentFactory;
        return (
            cf.renderBox([
                cf.renderBoxPadded(<Imgix height={100} width={100} src={ImageUtil.fetch(this.props.param)} />),
                cf.renderBoxPadded(cf.renderTextField("comment", "Comment")),
                cf.renderBoxPadded(cf.renderSwitchField("isActive", "Active")),
            ])
        )
    }
}

export default dialog(AddCommentDialog)