export default class NotificationHistoryQuery {

    // TODO: add back notificationCenterOpened
    public static GetNotificationHistory = `query GetNotificationHistoryQuery($accountId: String) {
        getNotificationHistory (
            accountId:$accountId
        ) {
            id
            pushNotificationTitle
            pushNotificationBody
            pushNotificationDate
            pushNotificationTime
            appTargetType
            appTargetSelection
            appTargetCustom
            appTargetAssetTags
            appTargetProducts
            notificationIssues
            numberOfDevices
            numberOpened
            notificationCenterOpened
            completed
            inProcess
            date
            userId
            accountId
            photoPreference
            customPhoto
        }      
    }`;

    public static GetNotificationHistoryForAdmin = `query GetNotificationHistoryForAdminQuery {
        getNotificationHistoryForAdmin {
            id
            pushNotificationTitle
            pushNotificationBody
            pushNotificationDate
            pushNotificationTime
            appTargetType
            appTargetSelection
            appTargetCustom
            appTargetAssetTags
            appTargetProducts
            notificationIssues
            numberOfDevices
            numberOpened
            notificationCenterOpened
            completed
            inProcess
            date
            userId
            accountId
            photoPreference
            customPhoto
        }      
    }`;

    ccc
    public static CreateNotificationHistory = `mutation CreateNotificationHistoryQuery($input: NotificationHistoryInput!) {
        createNotificationHistory(input: $input) {
            id
            pushNotificationTitle
            pushNotificationBody
            pushNotificationDate
            pushNotificationTime
            appTargetType
            appTargetSelection
            appTargetCustom
            appTargetAssetTags
            appTargetProducts
            notificationIssues
            numberOfDevices
            numberOpened
            notificationCenterOpened
            completed
            inProcess
            date
            userId
            accountId
            photoPreference
            customPhoto
        }
    }`;

    public static DeleteNotificationHistory = `mutation DeleteNotificationHistoryQuery($id: ID!) {
        deleteNotificationHistory(id: $id) {
            id
        }
    }`;
}