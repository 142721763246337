export default class SponsorQuery {

    public static GetSponsors = `query GetSponsors($accountId: String) {
        getSponsors (
            accountId:$accountId
        ) {
            id
            sponsorName
            sponsorContent
            sponsorActionType
            sponsorActionValue
            sponsorPhoto
            sponsorWebsite
            sponsorType
            sponsorStartDate
            sponsorEndDate
            sponsorCounty
            primary
            accountId
        }
    }`;

    public static GetSponsorsForAdmin = `query GetSponsorsForAdmin {
        getSponsorsForAdmin {
            id
            sponsorName
            sponsorWebsite
            sponsorType
            sponsorStartDate
            sponsorEndDate
            sponsorCounty
            primary
            accountId
        }
    }`;

    public static CreateSponsor = `mutation CreateSponsorMutation($input: SponsorInput!) {
        createSponsor(input: $input) {
            id
            sponsorName
            sponsorContent
            sponsorActionType
            sponsorActionValue
            sponsorPhoto
            sponsorWebsite
            sponsorType
            sponsorStartDate
            sponsorEndDate
            sponsorCounty
            primary
            accountId
        }
    }`;

    public static DeleteSponsor = `mutation DeleteSponsor($id: ID!) {
        deleteSponsor(id: $id) {
            id
        }
    }`;
}