import {API, graphqlOperation} from 'aws-amplify';

export default class AWSGraphql {

    public static async do(query: string, parameter?: any): Promise<any> {
        return new Promise(async (resolve, reject) => {
            const result = await API.graphql(graphqlOperation(query, parameter));
            resolve(result);
        })
    }
}