import * as React from 'reactn';
import {
    ArrayUtil, ComponentFactory,
    DateComponent, DateUtil,
    dialog,
    IBaseDialogProps,
    P,
    SelectComponent, SelectItem, StringUtil,
    TextComponent
} from "oca-lib-web";
import Constants from "../util/Constants";
import {createRef} from "react";
import ReactGA from "react-ga";
import ImageUtil from "../util/ImageUtil";
import Imgix from "react-imgix";
import AWSStorage from "../auth/aws/AWSStorage";
import Styles from "../style/Styles";

interface IState {
    componentFactory:ComponentFactory,
    countyOptions:Array<SelectItem>,
    assetOptions:Array<SelectItem>
    //sponsor?:SponsorModel
}

class AddSponsorDialog extends React.Component<IBaseDialogProps, IState> {
    public sponsorNameComponent = createRef<TextComponent>();
    public sponsorContentComponent = createRef<TextComponent>();
    public sponsorActionTypeComponent = createRef<SelectComponent>();
    public sponsorActionValueComponent = createRef<any>();
    public sponsorWebsiteComponent = createRef<TextComponent>();
    public sponsorTypeComponent = createRef<SelectComponent>();
    public startDateComponent = createRef<DateComponent>();
    public endDateComponent = createRef<DateComponent>();

    constructor(props: any) {
        super();

        let componentFactory = new ComponentFactory(Styles.default);
        let param = props.param ? props.param : { primary:false }
        componentFactory.addDefaultBinding(this.global.dataManager, "createSponsor", param);
        componentFactory.setHandleUpdateCallback((value:any, key:any) => {
            if (key == "accountId") {
                this.setAssetOptions();
                this.setCountyOptions();
            }
            this.forceUpdate();
        })

        this.state = {
            //sponsor: props.param ? props.param : { primary:false },
            componentFactory: componentFactory,
            countyOptions: [],
            assetOptions: []
        };

        props.dialog.saveFunction(this.handleSave.bind(this));
    }

    // Methods
    async componentDidMount() {
        ReactGA.modalview('/sponsor/sponsorDialog');

        this.setAssetOptions();
        this.setCountyOptions();
    }

    async setAssetOptions() {
        let cf = this.state.componentFactory;
        let componentBinding = cf.getDefaultBinding();
        let assetOptions = [];
        let assets = [];
        if (componentBinding.getProperty("accountId")) {
            assets = await this.global.dataManager.get("getAssets", componentBinding.getProperty("accountId"));
        } else if (this.global.account) {
            assets = await this.global.dataManager.get("getAssets", this.global.account.id);
        }
        ArrayUtil.sort(assets, "assetName");
        assetOptions = cf.createOptions(assets, "id", "assetName");
        this.setState({assetOptions:assetOptions});
    }

    async setCountyOptions() {
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        let counties;
        if (this.global.account && this.global.account?.enhancedRegion) {
            counties = this.global.account.counties?.split(",");
        }
        else if (componentBinding.getProperty("accountId")) {
            let account = await this.global.dataManager.get("getAccount", componentBinding.getProperty("accountId"));
            if (account.enhancedRegion) {
                counties = account.counties.split(",");
            }
        }

        if (counties && counties.length > 1) {
            ArrayUtil.sort(counties);
            let countyOptions: Array<SelectItem> = this.state.componentFactory.createOptions(counties);
            countyOptions.splice(0, 0, SelectItem.create("", "All Counties"));
            this.setState({countyOptions:countyOptions});
        } else {
            this.setState({countyOptions:[]});
        }
    }

    async handleSave(callback:any) {
        //let user = await this.global.data.getCurrentUser();
        //let item = this.state.sponsor;
        let componentBinding = this.state.componentFactory.getDefaultBinding();
        if (!componentBinding.validate()) { return; }

        if (!this.global.user.admin) {
            //let account = await UserUtil.getAccountForUser(user);
            //item.accountId = account.id;
            componentBinding.setProperty("accountId", this.global.account.id)
        }
        //item.userId = user.id;
        console.log(componentBinding.item);

        let success = await this.validate();
        if (success) {
            //await componentBinding.saveBinding();
            await this.global.dataManager.save("createSponsor", componentBinding.item);
            callback(componentBinding.item);
        }
    }

    async uploadPictureTapped(e:any) {
        if (!e.nativeEvent.target || !e.nativeEvent.target.files) { return }

        const file = e.nativeEvent.target.files[0];
        const fileName = StringUtil.uuid();
        await AWSStorage.put(fileName, file);
        console.log("uploaded file: " + fileName);

        let componentBinding = this.state.componentFactory.getDefaultBinding();
        componentBinding.setProperty("sponsorPhoto", fileName);
        this.forceUpdate();
        //componentBinding.saveBinding();

    }

    async validate():Promise<boolean> {
        let success = true;

        // TODO: need validation to make primary sponsor or asset category
        let sponsor = this.state.componentFactory.getDefaultBinding().item;
        //let sponsors = await this.global.data.getSponsors(sponsor.accountId);
        let sponsors = await this.global.dataManager.get("getSponsors", sponsor.accountId);
        for (let sponsor of sponsors) {
            let currentSponsorStartDate = sponsor.sponsorStartDate;
            let currentSponsorEndDate = sponsor.sponsorEndDate;
            let sponsorStartDate = sponsor.sponsorStartDate;
            let sponsorEndDate = sponsor.sponsorEndDate;
            if (sponsor.id != sponsor.id && sponsor.sponsorType.indexOf(sponsor.sponsorType) > -1) {
                if (!DateUtil.isBefore(currentSponsorEndDate, sponsorStartDate) && !DateUtil.isAfter(currentSponsorStartDate, sponsorEndDate)) {
                    this.sponsorTypeComponent.current.setErrorText("There can only be one sponsor per asset category for a given date range");
                    success = false;
                    return P.resolve(success);
                }
            }
            if (sponsor.id != sponsor.id && sponsor.primary && sponsor.primary) {
                if (!currentSponsorEndDate.isBefore(sponsorStartDate) && !currentSponsorStartDate.isAfter(sponsorEndDate)) {
                    this.sponsorTypeComponent.current.setErrorText("You may only have one primary sponsor");
                    success = false;
                    return P.resolve(success);
                }
            }
        }

        return P.resolve(success);
    }

    /*shouldShowSponsorCounties() {
        return this.state.countyOptions.length > 0 && this.global.account?.enhancedRegion
    }*/

    getSponsorActionTypeLabel() {
        let cf = this.state.componentFactory;
        let action = cf.getDefaultBinding().getProperty("sponsorActionType");
        return action == "website" ? "Sponsor Website" : action == "email" ? "Sponsor Email" : action == "phone" ? "Sponsor Phone Number" : action == "actionLink" ? "Sponsor Asset" : "Sponsor Action Value";
    }

    render() {
        let cf = this.state.componentFactory;
        let assetGroups = Constants.ALL_GROUP_CATEGORIES;
        ArrayUtil.sort(assetGroups, "label");
        let photo = cf.getDefaultBinding().getProperty("sponsorPhoto");
        let sponsorActionType = cf.getDefaultBinding().getProperty("sponsorActionType");
        let sponsorContent = cf.getDefaultBinding().getProperty("sponsorContent");
        let sponsorActionTypeOptions = cf.createOptions([{id:"website", label:"Website"}, {id:"email", label:"Email"}, {id:"phone", label:"Phone Call"}, {id:"assetLink", label:"Asset Link"}], "id", "label")

        return cf.renderBox([
            this.global.user?.admin ? cf.renderBoxPadded(cf.renderSelectField("accountId", "Account", this.global.accountOptions)) : null,
            this.state.countyOptions.length > 0 ? cf.renderBoxPadded(cf.renderSelectField("sponsorCounty", "County", this.state.countyOptions)) : null,
            cf.renderBoxPadded(cf.renderTextField("sponsorName", "Sponsor Name", this.sponsorNameComponent, null, {maxLength:50, required:true})),
            cf.renderBoxPadded(cf.renderTextField("sponsorContent", "Sponsor Content (" + (sponsorContent ? sponsorContent.length : "0") + "/400)", this.sponsorContentComponent, null, {maxLength:400, type:"textarea", multiline:true})),
            cf.renderBoxPadded(cf.renderSelectField("sponsorActionType", "Sponsor Action Type", sponsorActionTypeOptions, this.sponsorActionTypeComponent, null, {required:true})),
            sponsorActionType == "assetLink" ?
                cf.renderBoxPadded(cf.renderSelectField("sponsorActionValue", "Sponsor Asset", this.state.assetOptions, this.sponsorActionValueComponent, null, {required:false})) :
                cf.renderBoxPadded(cf.renderTextField("sponsorActionValue", this.getSponsorActionTypeLabel(), this.sponsorActionValueComponent, null, {required:false})),
            cf.renderBoxPadded(cf.renderSelectField("sponsorType", "Sponsor Type", assetGroups, this.sponsorTypeComponent, null, {multiple:true, required:true})),
            cf.renderBoxPadded(cf.renderDateField("sponsorStartDate", "Start Date", "date", this.startDateComponent, null, {required:true})),
            cf.renderBoxPadded(cf.renderDateField("sponsorEndDate", "End Date", "date", this.endDateComponent, null, {required:true})),
            cf.renderBoxPadded(cf.renderSwitchField("primary", "Primary")),
            cf.renderBoxPadded([
                cf.renderBox(photo ? <Imgix height={100} width={100} src={ImageUtil.fetch(photo)} /> : null),
                cf.renderBox(cf.renderUploadButton("Upload Image", "images", this.uploadPictureTapped.bind(this), null))
            ])
        ])
    }
}

export default dialog(AddSponsorDialog)